import { Theme, css } from '@emotion/react';
import { INPUT_HEIGHT } from '../Input/styles';

export const switchStyles = (size: 'small' | 'normal') => (theme: Theme) => {
  const isSmall = size === 'small';
  const height = isSmall ? INPUT_HEIGHT / 1.2 : INPUT_HEIGHT;
  return css({
    display: 'flex',
    gap: '10px',
    cursor: 'pointer',
    width: 'fit-content',
    alignItems: 'center',
    '.switchContainer': {
      alignSelf: 'flex-start',
      position: 'relative',
      userSelect: 'none',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minHeight: `${height / 2 + 6}px`,
      paddingInline: '2px',
      minWidth: isSmall ? '48px' : '55px',
      backgroundColor: theme.colors.lightRed,
      border: '1px solid transparent',
      borderRadius: '50px',
      transition: 'background-color 0.2s ease-in-out',
      input: {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
        height: 0,
        width: 0,
      },
      '.checkMark': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: `${height / 2 + 2}px`,
        width: `${height / 2 + 2}px`,
        backgroundColor: theme.colors.persistentSemanticRed,
        borderRadius: '50%',
        border: `2px solid ${theme.colors.persistentSemanticRed}`,
        transition:
          'transform 0.2s linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%)',
        svg: {
          fill: theme.colors.textWhite,
          marginTop: '4px',
          ...(isSmall ? { height: '18px' } : {}),
        },
      },
      'input:checked ~ .checkMark': {
        transform: isSmall ? 'translateX(19px)' : 'translateX(22px)',
        backgroundColor: theme.colors.persistentSemanticBlue,
        border: `2px solid ${theme.colors.persistentSemanticBlue}`,
        svg: {
          fill: theme.colors.textWhite,
          marginTop: '3px',
        },
      },
      'input:disabled ~ .checkMark': {
        backgroundColor: theme.colors.lightestGrey,
        border: `2px solid ${theme.colors.lightGrey}`,
        svg: {
          fill: theme.colors.lightGrey,
        },
      },
    },
    '.switchContainer[data-ischecked="true"]': {
      backgroundColor: theme.colors.lighterBlue,
      border: `1px solid ${theme.colors.darkBlue}`,
    },

    '&.disabled': {
      '.switchLabel': {
        userSelect: 'none',
        color: theme.colors.lightGrey,
        fontWeight: theme.fontWeights.medium,
      },
      '.switchContainer': {
        backgroundColor: theme.colors.lightestGrey,
        border: `1px solid ${theme.colors.lightGrey}`,
      },
    },
  });
};
