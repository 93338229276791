import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import Table from '@app/components/common/Table';
import { diffPaymentsFactory } from '@app/helpers/factories/settings';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import {
  currentPaymentsSettingsSelector,
  currentTaxesSettingsSelector,
  selectCurrentSettingsData,
  selectRoles,
  settingsDifferencesSelector,
} from '@app/state/selectors/settingsSelectors';
import { useTheme } from '@emotion/react';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  formatMoney,
  IOpenItemTypeDiff,
  IPaymentsSettings,
  IPrinterRuleDiff,
  ITipRolesPool,
  ITipSharingRules,
  OrderType,
  orderTypesText,
  ServiceChargeAssignTo,
  ServiceChargeDiscount,
  ServiceChargeType,
  TChange,
} from '@westondev/tableturn-core';
import { Fragment, useCallback, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import RenderChange from '../common/RenderChange';

const COLOR_FIELDS = ['backgroundColor', 'textColor'];

const PaymentsDiffModal = ({ t }: WithTranslation) => {
  // Redux
  const differences = useSelector(settingsDifferencesSelector);
  const originalData = useSelector(
    currentPaymentsSettingsSelector,
  ) as IPaymentsSettings;
  const settingsTaxes = useSelector(currentTaxesSettingsSelector);

  const changeData = useSelector(
    selectCurrentSettingsData,
  ) as IPaymentsSettings;

  const roles = useSelector(selectRoles);

  const theme = useTheme();

  const defaultColor = {
    [COLOR_FIELDS[0]]: theme.colors.semanticBlue,
    [COLOR_FIELDS[1]]: theme.colors.textWhite,
  };

  const formattedDifferences = useMemo(() => {
    const diffObject = diffPaymentsFactory(differences);

    return diffObject;
  }, [differences]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (['timeZone', 'clockInMode'].includes(field)) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    if (!change.field) return null;
    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        colorFields={COLOR_FIELDS}
        defaultColor={defaultColor}
        isImage={change.field === 'logoUrl'}
      />
    );
  };

  const formatSharingRulesRecipients = useCallback(
    (recipients: ITipRolesPool) => {
      const recipientsText: string[] = [];

      Object.values(recipients).forEach(role => {
        recipientsText.push(`${roles?.[role.roleId]?.name}: ${role.amount}%`);
      });

      return recipientsText.length === 0 ? '- - -' : recipientsText.join(' , ');
    },
    [roles],
  );

  const joinOrderTypes = (orderTypes: OrderType[]) => {
    const displayedOrderTypes = orderTypes.map(
      orderType => orderTypesText[orderType as OrderType],
    );
    displayedOrderTypes.sort();
    return displayedOrderTypes.join(', ');
  };

  const renderSharingRulesTable = (roleSharingRows: IPrinterRuleDiff[]) => {
    const columnHelper = createColumnHelper<IPrinterRuleDiff>();

    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'contributorId',
        header: t('settingsModule.paymentsSettings.tipSettings.from'),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'contributorId');

          return (
            <RenderChange
              change={{
                field: 'contributorId',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? roles[
                        (originalData.sharingRules as ITipSharingRules)[
                          rowData.id
                        ]?.contributorId
                      ]?.name || '- - -'
                    : roles[
                        (changeData.sharingRules as ITipSharingRules)[
                          rowData.id
                        ].contributorId
                      ]?.name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'contribution',
        header: t('settingsModule.paymentsSettings.tipSettings.contribution'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'contribution');

          return (
            <RenderChange
              change={{
                field: 'contribution',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData?.sharingRules?.[rowData.id]?.contribution ||
                      originalData?.sharingRules?.[rowData.id]?.contribution ===
                        0
                      ? `${originalData?.sharingRules?.[rowData.id]
                          ?.contribution}%`
                      : '- - -'
                    : changeData?.sharingRules?.[rowData.id].contribution ||
                      changeData?.sharingRules?.[rowData.id].contribution === 0
                    ? `${changeData?.sharingRules?.[rowData.id].contribution}%`
                    : '- - -',
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'recipients',
        header: t('settingsModule.paymentsSettings.tipSettings.to'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'recipients');

          return (
            <RenderChange
              change={{
                field: 'recipients',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? formatSharingRulesRecipients(
                        originalData?.sharingRules?.[rowData.id]?.recipients ||
                          {},
                      )
                    : formatSharingRulesRecipients(
                        changeData?.sharingRules?.[rowData.id]?.recipients ||
                          {},
                      ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];

    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          title={t('settingsModule.paymentsSettings.tipSettings.sharingRules')}
          data={roleSharingRows}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            contributorId: 'center',
            contribution: 'center',
            recipients: 'center',
          }}
        />
      </Box>
    );
  };

  const renderRolePoleTable = (
    rolePoolRows: IPrinterRuleDiff[],
    key: 'rolePoolContributors' | 'rolePoolRecipients',
  ) => {
    const columnHelper = createColumnHelper<IPrinterRuleDiff>();

    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'roleId',
        header:
          key === 'rolePoolContributors'
            ? t('settingsModule.paymentsSettings.tipSettings.contributor')
            : t('settingsModule.paymentsSettings.tipSettings.recipient'),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'roleId');

          return (
            <RenderChange
              change={{
                field: 'roleId',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? roles[
                        (originalData[key] as ITipRolesPool)[rowData.id].roleId
                      ].name || '- - -'
                    : roles[
                        (changeData[key] as ITipRolesPool)[rowData.id].roleId
                      ].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'amount',
        header:
          key === 'rolePoolContributors'
            ? t(
                'settingsModule.paymentsSettings.tipSettings.percentageContributed',
              )
            : t(
                'settingsModule.paymentsSettings.tipSettings.percentageReceived',
              ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'amount');

          return (
            <RenderChange
              change={{
                field: 'amount',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData?.[key]?.[rowData.id]?.amount ||
                      originalData?.[key]?.[rowData.id]?.amount === 0
                      ? `${originalData?.[key]?.[rowData.id]?.amount}%`
                      : '- - -'
                    : changeData?.[key]?.[rowData.id].amount ||
                      changeData?.[key]?.[rowData.id].amount === 0
                    ? `${changeData?.[key]?.[rowData.id].amount}%`
                    : '- - -',
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];

    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          title={
            key === 'rolePoolContributors'
              ? t(
                  'settingsModule.paymentsSettings.tipSettings.rolesContributingToPool',
                )
              : t(
                  'settingsModule.paymentsSettings.tipSettings.rolesReceivingFromPool',
                )
          }
          data={rolePoolRows}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            roleId: 'center',
            amount: 'center',
          }}
        />
      </Box>
    );
  };

  const renderServiceChargeTable = (rolePoolRows: IPrinterRuleDiff[]) => {
    const columnHelper = createColumnHelper<IPrinterRuleDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'status',
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.status',
        ),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');

          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData?.serviceCharges?.[rowData.id]?.active
                    : changeData?.serviceCharges?.[rowData.id].active,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'name',
        header: t('settingsModule.paymentsSettings.serviceChargeSettings.name'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'name');

          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData?.serviceCharges?.[rowData.id]?.name
                    : changeData?.serviceCharges?.[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'posName',
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.posName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'posName');

          return (
            <RenderChange
              change={{
                field: 'posName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData?.serviceCharges?.[rowData.id]?.posName
                    : changeData?.serviceCharges?.[rowData.id].posName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'chargeType',
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.chargeType',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'chargeType');

          return (
            <RenderChange
              change={{
                field: 'chargeType',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData?.serviceCharges?.[rowData.id]?.chargeType
                    : changeData?.serviceCharges?.[rowData.id].chargeType,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'charge',
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.charge',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'charge');

          return (
            <RenderChange
              change={{
                field: 'charge',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData?.serviceCharges?.[rowData.id]?.chargeType ===
                      ServiceChargeType.PERCENT
                      ? `${originalData?.serviceCharges?.[rowData.id]?.charge}%`
                      : formatMoney(
                          null,
                          originalData?.serviceCharges?.[rowData.id]?.charge,
                        )
                    : changeData?.serviceCharges?.[rowData.id]?.chargeType ===
                      ServiceChargeType.PERCENT
                    ? `${changeData?.serviceCharges?.[rowData.id]?.charge}%`
                    : formatMoney(
                        null,
                        changeData?.serviceCharges?.[rowData.id]?.charge,
                      ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'assignTo',
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.assignTo',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'assignTo');

          return (
            <RenderChange
              change={{
                field: 'assignTo',
                label: '',
                value:
                  (rowData.action === 'remove'
                    ? originalData?.serviceCharges?.[rowData.id]?.assignTo
                    : changeData?.serviceCharges?.[rowData.id].assignTo) ===
                  ServiceChargeAssignTo.SERVER
                    ? t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.server',
                      )
                    : t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.restaurant',
                      ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'discount',
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.discount',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'discount');

          return (
            <RenderChange
              change={{
                field: 'discount',
                label: '',
                value:
                  (rowData.action === 'remove'
                    ? originalData?.serviceCharges?.[rowData.id]?.discount
                    : changeData?.serviceCharges?.[rowData.id].discount) ===
                  ServiceChargeDiscount.PRE_DISCOUNT
                    ? t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.pre',
                      )
                    : t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.post',
                      ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'orderTypes',
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.orderTypes',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'orderTypes');

          return (
            <RenderChange
              change={{
                field: 'orderTypes',
                label: '',
                value: joinOrderTypes(
                  rowData.action === 'remove'
                    ? originalData?.serviceCharges?.[rowData.id]?.orderTypes
                    : changeData?.serviceCharges?.[rowData.id]?.orderTypes,
                ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'minGuests',
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.minGuests',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'minGuests');

          return (
            <RenderChange
              change={{
                field: 'minGuests',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData?.serviceCharges?.[rowData.id]?.minGuests
                    : changeData?.serviceCharges?.[rowData.id].minGuests,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'taxIds',
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.taxes',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'taxIds');

          return (
            <RenderChange
              autoHeight
              change={{
                field: 'taxIds',
                label: '',
                value:
                  (
                    (rowData.action === 'remove'
                      ? originalData?.serviceCharges?.[rowData.id]?.taxIds
                      : changeData?.serviceCharges?.[rowData.id].taxIds) || []
                  )
                    .map(taxId => {
                      const tax = settingsTaxes[taxId];
                      return tax ? tax.name : '';
                    })
                    .join(', ') || '- - -',
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];

    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          title={t(
            'settingsModule.paymentsSettings.serviceChargeSettings.serviceCharges',
          )}
          data={rolePoolRows}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            taxIds: 'center',
            minGuests: 'center',
            orderTypes: 'center',
            discount: 'center',
            assignTo: 'center',
            chargeType: 'center',
            charge: 'center',
            posName: 'center',
            name: 'center',
            status: 'center',
          }}
        />
      </Box>
    );
  };

  return (
    <>
      {formattedDifferences && (
        <>
          {formattedDifferences.general.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.settings.general')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.general.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`generalSettings_general_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {formattedDifferences.tipSettings.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('settingsModule.paymentsSettings.tipSettings.title')}
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.tipSettings.map(change => {
                  if (
                    change.field === 'rolePoolContributors' ||
                    change.field === 'rolePoolRecipients' ||
                    change.field === 'sharingRules'
                  ) {
                    return null;
                  }

                  return (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`generalSettings_tipSettings_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  );
                })}

                {formattedDifferences.tipSettings.map(change => {
                  if (
                    (change.field === 'rolePoolContributors' ||
                      change.field === 'rolePoolRecipients' ||
                      change.field === 'sharingRules') &&
                    change.value &&
                    change?.value?.length > 0
                  ) {
                    return (
                      <Grid.Item
                        key={`paymentsSettings_tipSettings_${change.field}`}
                        mb={12}>
                        {change.field === 'sharingRules'
                          ? renderSharingRulesTable(change.value)
                          : renderRolePoleTable(change.value, change.field)}
                      </Grid.Item>
                    );
                  }

                  return null;
                })}
              </Grid>
            </>
          )}

          {formattedDifferences.serviceChargeSettings.length > 0 && (
            <>
              <HeaderDiffModal
                name={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.title',
                )}
              />

              {formattedDifferences.serviceChargeSettings.map(change => {
                if (
                  change.field === 'serviceCharges' &&
                  change.value &&
                  change?.value?.length > 0
                ) {
                  return (
                    <Fragment
                      key={`paymentsSettings_tipSettings_${change.field}`}>
                      {renderServiceChargeTable(change.value)}
                    </Fragment>
                  );
                }
                return null;
              })}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentsDiffModal;
