import { IBreadCrumbWeb, PullingDataTypes } from '@app/types';
import { AppThunk } from '../store';

import {
  ELoadingModalVariants,
  ILoadingModalExtended,
} from '@app/components/common/LoadingModal/types';
import { CallMethods } from '@app/constants';
import { apiCall } from '@app/helpers/apiCall';
import {
  isFreshSheetPath,
  isMenuPath,
  isOtherPath,
  isSettingsPath,
  isTableLayoutPath,
} from '@app/helpers/location';
import {
  getOrgDataFromPath,
  getPathWithOrgData,
} from '@app/helpers/navigation';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import router from '@app/router/router';
import endpoints from '@app/services/endpoints';
import { IInitializationParams } from '@app/services/types';
import {
  BreadCrumbAction,
  IMenuStore,
  ISettingsReducerStore,
  ISettingsStore,
  IUpdateMenuResponse,
  IUpdateSettingsResponse,
  IUserObject,
  MenuTypes,
  ROLE_INITIAL_STATE,
  SET_IS_LOADING_MODAL,
  SET_LOGGED_IN_USER,
  SettingsTypes,
  UPDATE_MENU,
  UPDATE_PRINTER_STATUS,
  UPDATE_SETTINGS,
  actionCreatorsApp,
  actionCreatorsLocationSettings,
  actionCreatorsLocations,
  actionCreatorsMasterSettings,
  actionCreatorsMenu,
  actionCreatorsRegister,
  actionCreatorsRemoteLocationSettings,
  actionCreatorsSelectedModeSettings,
  actionCreatorsSettings,
  actionCreatorsTableLayout,
  actionCreatorsVersionsSettings,
  apiEndpoints,
  websocketMapType,
} from '@westondev/tableturn-core';
import { jwtDecode } from 'jwt-decode';
import cloneDeep from 'lodash/cloneDeep';
import { batch } from 'react-redux';
import { Location } from 'react-router-dom';
import { actionCreatorsMenuWeb } from '..';
import {
  changeSelectedMenuModeWeb,
  checkForChangesAndNavigateWeb,
  checkForFreshSheetChangesAndNavigateWeb,
  initializeBreadCrumbForBucket,
  initializeOtherBreadcrumbs,
  loadMenuBucketChangeData,
  loadMenuTypesMenuChangeData,
} from '../menu/menuWebActions';
import {
  selectLocationId,
  selectLocationUserRoles,
  selectLoggedInUser,
  selectPortals,
} from '../selectors/appSelectors';
import { selectShouldRenderLocationDropdown } from '../selectors/locationsSelectors';
import {
  changeSelectedSettingsModeWeb,
  checkForSettingsChangesAndNavigateWeb,
  loadPrepStationsSettingsChangeData,
  loadPrintingRulesSettingsChangeData,
  loadSettingsBucketChangeData,
  loadUserSettingsChangeData,
} from '../settings/settingsWebActions';
import {
  checkForLayoutChangesWeb,
  initializeTableLayoutOnRefreshWeb,
} from '../settings/tableLayoutWebActions';
import {
  ActionTypes,
  IConfirmationModalExtended,
  IUserDetails,
  IUserOrganization,
  SET_HIDE_TOAST,
  SET_IS_LOADING,
  SET_LOCATION_ID,
  SET_ORGANIZATIONS,
  SET_ORGANIZATION_ID,
  SET_PORTALS,
  SET_PULLING_DATA,
  SET_SHOW_CONFORMATION_MODAL,
  SET_TOKEN,
  SET_USER_DETAILS,
  SET_VIRTUALIZED_NAVIGATION,
  TVirtualizedNavigation,
} from './types';
import { handlePrinterStatusWsWeb } from './webActions';

const { setLocationGroupsOnState } = actionCreatorsLocations;
const { updateSettingsOnSocket } = actionCreatorsSettings;
const { setLocationSettings } = actionCreatorsLocationSettings;
const { setMasterSettings } = actionCreatorsMasterSettings;
const { setRemoteLocationSettings } = actionCreatorsRemoteLocationSettings;
const { setSelectedSettingsMode } = actionCreatorsSelectedModeSettings;
const { setSettingsVersions } = actionCreatorsVersionsSettings;
const {
  setMasterMenu,
  setLocationMenu,
  setMenuVersions,
  setSelectedMenuMode,
  setFreshSheetDataFromMenu,
  setBreadCrumb,
} = actionCreatorsMenu;
const { setTableLayoutData } = actionCreatorsTableLayout;
const { setDrawers } = actionCreatorsRegister;
const { updateMenuOnSocketWeb } = actionCreatorsMenuWeb;

export const { setShowToast, showToast } = actionCreatorsApp;

export const setToken = (payload: string): ActionTypes => ({
  type: SET_TOKEN,
  payload,
});

export const hideToast = (): ActionTypes => ({
  type: SET_HIDE_TOAST,
});

export const setPullingData = (payload: PullingDataTypes): ActionTypes => ({
  type: SET_PULLING_DATA,
  payload,
});

export const setIsLoading = (payload: boolean): ActionTypes => ({
  type: SET_IS_LOADING,
  payload,
});

export const setIsLoadingModal = (
  payload: ILoadingModalExtended,
): ActionTypes => ({
  type: SET_IS_LOADING_MODAL,
  payload,
});

export const setPortals = (payload: string[]): ActionTypes => ({
  type: SET_PORTALS,
  payload,
});

export const setUserDetails = (payload: IUserDetails): ActionTypes => ({
  type: SET_USER_DETAILS,
  payload,
});

export const setLocationId = (payload: number): ActionTypes => ({
  type: SET_LOCATION_ID,
  payload,
});

export const setOrganizationId = (payload: number): ActionTypes => ({
  type: SET_ORGANIZATION_ID,
  payload,
});

export const setShowConfirmationModal = (
  payload: IConfirmationModalExtended,
): ActionTypes => ({
  type: SET_SHOW_CONFORMATION_MODAL,
  payload,
});

export const setLoggedInUser = (payload: IUserObject) => ({
  type: SET_LOGGED_IN_USER,
  payload,
});

export const setVirtualizedNavigation = (
  payload: Partial<TVirtualizedNavigation>,
) => ({
  type: SET_VIRTUALIZED_NAVIGATION,
  payload,
});

export const setOrganizations = (
  payload: IUserOrganization[],
): ActionTypes => ({
  type: SET_ORGANIZATIONS,
  payload,
});

export const updatePortals = (
  name: string,
  action: 'remove' | 'add' | 'initialize',
): AppThunk<void> => {
  return async (dispatch, getState) => {
    let portals = [...selectPortals(getState())];
    if (action === 'add') {
      portals.push(name);
    } else if (action === 'initialize') {
      portals = [name];
    } else {
      portals = portals.filter(portal => portal !== name);
    }
    dispatch(setPortals(portals));
  };
};

interface ILoginResult {
  token: string;
  email: string;
  phone: string;
  emailVerified: boolean;
  phoneVerified: boolean;
}

export const signIn = (
  email: string,
  password: string,
): AppThunk<Promise<boolean>> => {
  return async dispatch => {
    dispatch(setIsLoading(true));

    try {
      const result: ILoginResult = await apiCall(
        CallMethods.POST,
        endpoints.auth.signIn,
        false,
        {
          email,
          password,
        },
      );

      dispatch(setToken(result.token));
      localStorage.setItem('auth', result.token);
      setUserDetailsOnCache({
        email: result.email,
        phoneNumber: result.phone,
        emailVerified: !!result.emailVerified,
        phoneVerified: !!result.phoneVerified,
      } as IUserDetails);

      dispatch(setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setIsLoading(false));
      return false;
    }
  };
};

export const signUp =
  (result: ILoginResult): AppThunk =>
  dispatch => {
    dispatch(setToken(result.token));
    localStorage.setItem('auth', result.token);
    setUserDetailsOnCache({
      email: result.email,
      phoneNumber: result.phone,
      emailVerified: !!result.emailVerified,
      phoneVerified: !!result.phoneVerified,
    } as IUserDetails);
  };

export const sendVerificationCode = (
  email: string,
): AppThunk<Promise<boolean>> => {
  return async dispatch => {
    dispatch(setIsLoading(true));

    try {
      await apiCall(CallMethods.POST, endpoints.auth.resetPassword, false, {
        email,
      });
      dispatch(setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setIsLoading(false));
      return false;
    }
  };
};

export const resetPassword = (
  newPassword: string,
  email: string,
  code: string,
): AppThunk<Promise<boolean>> => {
  return async dispatch => {
    dispatch(setIsLoading(true));

    try {
      await apiCall(CallMethods.PUT, endpoints.auth.resetPassword, false, {
        email,
        newPassword,
        code,
      });
      dispatch(setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setIsLoading(false));
      return false;
    }
  };
};

export const verifyCode = (
  email: string,
  code: string,
): AppThunk<Promise<boolean>> => {
  return async dispatch => {
    dispatch(setIsLoading(true));

    try {
      await apiCall(CallMethods.POST, endpoints.auth.verifyCode, false, {
        email,
        code,
      });
      dispatch(setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setIsLoading(false));
      return false;
    }
  };
};

const setOrgDataOnCache = (data: IInitializationParams) => {
  localStorage.setItem(
    `orgData-${data.organizationId}-${data.locationId}`,
    JSON.stringify(data),
  );
};

const setUserDetailsOnCache = (data: IUserDetails) => {
  localStorage.setItem('userDetails', JSON.stringify(data));
};

export const setVirtualizedNavigationOnCache = (
  action: 'replace' | 'reset',
  nextLocation?: Location<any>,
): AppThunk<void> => {
  return (dispatch, getState) => {
    if (action === 'replace') {
      const breadCrumb = getState().menu.breadCrumb;
      const virtualizedNavigation = getState().app.virtualizedNavigation;

      // Set other paths that are not bucket paths.
      const setOtherPaths = (isReplacing = false) => {
        if (nextLocation)
          dispatch(
            setVirtualizedNavigation({
              breadcrumb: [
                ...(isReplacing
                  ? virtualizedNavigation.breadcrumb.slice(
                      0,
                      virtualizedNavigation.index,
                    )
                  : virtualizedNavigation.breadcrumb),
                {
                  action: BreadCrumbAction.NAV_OTHER,
                  onPress: () =>
                    router.navigate(getPathWithOrgData(nextLocation.pathname)),
                  pathURL: getPathWithOrgData(nextLocation.pathname),
                  text: 'Other page',
                  statusPath: '',
                  key: nextLocation.key,
                },
              ],
              index: isReplacing
                ? virtualizedNavigation.index + 1
                : virtualizedNavigation.breadcrumb.length + 1,
            }),
          );
      };
      // If we have a breadcrumb, we will replace the last element with the new location.
      if (virtualizedNavigation.breadcrumb.length > 0) {
        // if(breadCrumb.length ===0 && )
        const isReplacing =
          virtualizedNavigation.index < virtualizedNavigation.breadcrumb.length;

        if (
          (nextLocation &&
            (nextLocation.pathname.includes('fresh-sheet') ||
              isOtherPath(nextLocation.pathname))) ||
          (nextLocation && isSettingsPath(nextLocation.pathname))
        ) {
          return setOtherPaths(isReplacing);
        }

        const alreadyBeenAdded =
          virtualizedNavigation.breadcrumb[virtualizedNavigation.index - 1]
            ?.pathURL === nextLocation?.pathname;

        const nextVirtualizedBreadCrumb =
          // Replace the last element with the new location
          isReplacing && !alreadyBeenAdded
            ? [
                ...virtualizedNavigation.breadcrumb.slice(
                  0,
                  virtualizedNavigation.index,
                ),
                {
                  ...(breadCrumb[breadCrumb.length - 1] as IBreadCrumbWeb),
                  key: nextLocation?.key,
                },
              ]
            : // Add the new location to the breadcrumb
            breadCrumb.length > 0 && !alreadyBeenAdded && !isReplacing
            ? [
                ...virtualizedNavigation.breadcrumb,
                {
                  ...(breadCrumb[breadCrumb.length - 1] as IBreadCrumbWeb),
                  key: nextLocation?.key,
                },
              ]
            : // If the location is already in the breadcrumb, we update the key
            breadCrumb.length > 0 && alreadyBeenAdded
            ? virtualizedNavigation.breadcrumb.map((item, index) => ({
                ...item,
                key:
                  index === virtualizedNavigation.index - 1
                    ? nextLocation?.key
                    : item.key,
              }))
            : virtualizedNavigation.breadcrumb;

        const nextIndex = isReplacing
          ? virtualizedNavigation.index + 1
          : breadCrumb.length > 0 && !alreadyBeenAdded
          ? virtualizedNavigation.breadcrumb.length + 1
          : virtualizedNavigation.index;

        dispatch(
          setVirtualizedNavigation({
            breadcrumb: nextVirtualizedBreadCrumb,
            index: nextIndex,
          }),
        );
      } else if (breadCrumb.length > 0) {
        dispatch(
          setVirtualizedNavigation({
            breadcrumb: breadCrumb as IBreadCrumbWeb[],
            index: breadCrumb.length,
            initialIndex: breadCrumb.length,
          }),
        );
      } else {
        if (nextLocation && isSettingsPath(nextLocation.pathname)) {
          return setOtherPaths();
        }
      }
    } else {
      dispatch(
        setVirtualizedNavigation({
          breadcrumb: [],
          index: 0,
          initialIndex: 0,
        }),
      );
    }
  };
};

export const initializeBucketListBreadcrumb = (
  navMain: IBreadCrumbWeb,
  action: 'click' | 'reload' = 'reload',
): AppThunk<void> => {
  return (dispatch, getState) => {
    dispatch(setBreadCrumb([navMain]));

    if (action === 'click') {
      const virtualizedNavigation = getState().app.virtualizedNavigation;

      navMain = {
        ...navMain,
        key: router.state.location.key,
      };
      if (virtualizedNavigation.breadcrumb.length > 0) {
        return dispatch(
          setVirtualizedNavigation({
            breadcrumb: [...virtualizedNavigation.breadcrumb, navMain],
            index: virtualizedNavigation.index + 1,
          }),
        );
      }
      dispatch(
        setVirtualizedNavigation({
          breadcrumb: [navMain],
          index: 1,
          initialIndex: 1,
        }),
      );
    }
  };
};

const setUserDataOnState = (): AppThunk<void> => {
  return dispatch => {
    const params = getOrgDataFromPath();
    const localOrgData = localStorage.getItem(
      `orgData-${params.orgId}-${params.locationId}`,
    );
    const localUserData = localStorage.getItem('userDetails');
    const orgData = localOrgData ? JSON.parse(localOrgData) : null;
    const userDetails = localUserData ? JSON.parse(localUserData) : null;

    if (orgData && userDetails) {
      const { organizationId, locationId } = orgData;
      batch(() => {
        dispatch(setUserDetails(userDetails));
        dispatch(setLocationId(locationId));
        dispatch(setOrganizationId(organizationId));
      });
    }
  };
};

export const setLoggedInUserAndRole = (
  roleId: number | undefined,
): AppThunk<void> => {
  return (dispatch, getState) => {
    const users = getState().settings.master.users;
    const roles = getState().settings.selectedMode.currentSettings.roles;
    const userId = getState().app.userDetails.userId;

    const organizationId = getState().app.organizationId;
    const locationId = getState().app.locationId;

    const loggedInUser = Object.values(users).find(
      user => user.userId === userId,
    );
    const loggedInRole = Object.values(roles).find(
      role => role.roleId === roleId,
    );

    const hasRoleId = loggedInUser?.roleIds.includes(roleId as number);

    if (!loggedInUser) return;
    const newLoggedInUser = {
      ...loggedInUser,
      // TODO:  Call endpoints to get access locations
      settingsAccessLocations: loggedInUser.locationIds,
      menuAccessLocations: loggedInUser.locationIds,
    };
    if (loggedInRole && hasRoleId)
      newLoggedInUser.loggedInRole = cloneDeep(loggedInRole);
    else newLoggedInUser.loggedInRole = cloneDeep(ROLE_INITIAL_STATE);
    dispatch(setLoggedInUser(newLoggedInUser));

    setOrgDataOnCache({ organizationId, locationId, roleId });
    dispatch(updateTabTitle());
  };
};

const setTokenOnState = (): AppThunk<void> => {
  return dispatch => {
    const authToken = localStorage.getItem('auth');
    if (authToken) {
      dispatch(setToken(authToken));
    }
  };
};

export const getOrganizations = (): AppThunk<Promise<IUserOrganization[]>> => {
  return async dispatch => {
    dispatch(setIsLoading(true));

    const authToken = localStorage.getItem('auth') as string;
    const userId = Number(jwtDecode(authToken).sub);

    try {
      const result: {
        organizations: IUserOrganization[];
        user: IUserDetails;
      } = await apiCall(
        CallMethods.GET,
        `${endpoints.users.getOrganizations}/${userId}`,
        true,
      );
      dispatch(setIsLoading(false));
      dispatch(setUserDetails({ ...result.user, userId }));
      dispatch(setOrganizations(result.organizations));
      setUserDetailsOnCache({ ...result.user, userId });
      return result.organizations.sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      dispatch(setIsLoading(false));
      return [];
    }
  };
};

export const changeUnverifiedEmail =
  (newEmail: string): AppThunk<Promise<boolean>> =>
  async dispatch => {
    dispatch(setIsLoading(true));

    const authToken = localStorage.getItem('auth') as string;
    const userId = Number(jwtDecode(authToken).sub);

    const promise = apiCall(
      CallMethods.PUT,
      apiEndpoints.user.changeEmail(userId),
      true,
      { email: newEmail },
    );
    return await promise;
  };

export const changeUnverifiedPhone =
  (newPhone: string): AppThunk<Promise<boolean>> =>
  async dispatch => {
    dispatch(setIsLoading(true));

    const authToken = localStorage.getItem('auth') as string;
    const userId = Number(jwtDecode(authToken).sub);

    const promise = apiCall(
      CallMethods.PUT,
      apiEndpoints.user.changePhone(userId),
      true,
      { phoneNumber: newPhone },
    );

    dispatch(setIsLoading(false));
    return await promise;
  };

const SETTINGS_PATHS: Record<string, keyof ISettingsStore> = {
  '/settings/accounts/users': 'users',
  '/settings/register': 'register',
  '/settings/general': 'general',
  '/settings/accounts/roles': 'roles',
  '/settings/printing/ticket/kitchen-ticket': 'kitchenTicket',
  '/settings/printing/ticket/register-ticket': 'registerTicket',
  '/settings/printing/prep-stations': 'prepStations',
  '/settings/lock-screen': 'lockScreen',
  '/settings/printing/ticket/guest-check': 'guestCheck',
  '/settings/printing/printing-rules': 'printerRules',
  '/settings/taxes': 'taxes',
  '/settings/payments': 'payments',
};

const MENU_PATHS: Record<string, keyof IMenuStore> = {
  '/menu/menus': 'menuTypes',
  '/menu/categories': 'categories',
  '/menu/subcategories': 'subcategories',
  '/menu/modifiers/ingredients': 'ingredients',
  '/menu/modifiers/modifier-items': 'modifierItems',
  '/menu/discounts': 'discounts',
  '/menu/items': 'items',
  '/menu/specials': 'specials',
};
const FRESH_SHEET_MENU_PATH = '/menu/fresh-sheet';

const BUCKETS_WITH_ID = ['users', 'roles'];

export const refreshCredentialsOnReload = (): AppThunk<void> => {
  return async dispatch => {
    dispatch(setTokenOnState());
    dispatch(setUserDataOnState());
  };
};

export const refreshDataOnReload = (): AppThunk<void> => {
  return async dispatch => {
    localStorage.removeItem('path-data');

    const orgParams = getOrgDataFromPath();
    const localOrgData = localStorage.getItem(
      `orgData-${orgParams.orgId}-${orgParams.locationId}`,
    );

    const orgData = localOrgData ? JSON.parse(localOrgData) : null;
    const isOrgData = orgParams.orgId && orgParams.locationId ? true : false;

    const shouldCallGetOrgData = location.pathname !== '/my-organizations';

    if (isOrgData && shouldCallGetOrgData) {
      const pathSplitted = location.pathname.split('/');
      const isSettings = isSettingsPath(location.pathname);
      const isMenu = isMenuPath(location.pathname);

      dispatch(
        setIsLoadingModal({
          active: true,
          variant: ELoadingModalVariants.RefreshData,
          message: 'Checking Organization and Location',
        }),
      );

      const isOrgLocationValid = await dispatch(
        getOrganizationLocationData({
          organizationId: orgParams.orgId,
          locationId: orgParams.locationId,
          roleId: orgData ? orgData.roleId : undefined,
        }),
      );

      if (!isOrgLocationValid) {
        router.navigate('/my-organizations');
        requestAnimationFrame(() => {
          dispatch(
            setIsLoadingModal({
              active: true,
              variant: ELoadingModalVariants.RefreshError,
              message: 'Could not load Organization or Location',
            }),
          );
        });

        setTimeout(() => {
          dispatch(
            setIsLoadingModal({
              active: false,
              message: '',
            }),
          );
        }, 2000);
        return;
      } else {
        dispatch(
          setIsLoadingModal({
            active: true,
            variant: ELoadingModalVariants.RefreshData,
            message: 'Refreshing organization Data...',
          }),
        );
      }

      await dispatch(getOrganizations());

      if (isSettings) {
        const bucketEntry = Object.entries(SETTINGS_PATHS).find(([key]) =>
          location.pathname.includes(key),
        );
        const bucket = bucketEntry?.[1];
        const matchPath = bucketEntry
          ? getPathWithOrgData(bucketEntry[0])
          : undefined;

        if (bucket) {
          const id = BUCKETS_WITH_ID.includes(bucket)
            ? Number(!matchPath ? 0 : pathSplitted[matchPath.split('/').length])
            : 0;

          switch (bucket) {
            case 'users': {
              if (id) dispatch(loadUserSettingsChangeData({ id }));
              break;
            }
            case 'roles': {
              if (id)
                dispatch(
                  loadSettingsBucketChangeData({
                    setting: bucket as keyof ISettingsStore,
                    id,
                  }),
                );
              break;
            }
            case 'printerRules': {
              dispatch(loadPrintingRulesSettingsChangeData(0));
              break;
            }
            case 'prepStations': {
              dispatch(loadPrepStationsSettingsChangeData());
              break;
            }
            default:
              dispatch(
                loadSettingsBucketChangeData({
                  setting: bucket as keyof ISettingsStore,
                  id,
                }),
              );
              break;
          }
        } else if (isTableLayoutPath()) {
          dispatch(initializeTableLayoutOnRefreshWeb());
        }
        // initialize settings breadcrumb and virtualized breadcrumb
        dispatch(initializeOtherBreadcrumbs());
      } else if (isMenu) {
        const bucketEntry = Object.entries(MENU_PATHS).find(([key]) =>
          location.pathname.includes(key),
        );
        const bucket = bucketEntry?.[1];
        const matchPath = bucketEntry
          ? getPathWithOrgData(bucketEntry[0])
          : undefined;

        if (bucket) {
          const id = Number(
            !matchPath ? 0 : pathSplitted[matchPath.split('/').length],
          );
          const isAddPage = location.pathname.includes('add');
          switch (bucket) {
            case 'menuTypes': {
              if (id)
                dispatch(
                  loadMenuTypesMenuChangeData({
                    menuTypeId: id,
                    isRefreshingData: true,
                  }),
                );
              break;
            }
            case 'items': {
              break;
            }
            default:
              if (id)
                dispatch(
                  loadMenuBucketChangeData({
                    bucket,
                    id,
                  }),
                );
              break;
          }
          // initialize breadcrumb and virtualized breadcrumb for bucket without id
          if (!id) {
            dispatch(initializeBreadCrumbForBucket(bucket, 0, '', isAddPage));
          }
        } else if (location.pathname.includes(FRESH_SHEET_MENU_PATH)) {
          dispatch(replaceMasterWithLocation());
          dispatch(setFreshSheetDataFromMenu());
        }
      }

      const locationLength = location.pathname.split('/').length;
      const hasNoModule =
        locationLength < 4 ||
        location.pathname.split('/')[locationLength - 1] === '';

      if (hasNoModule) {
        router.navigate(`${location.pathname}/home`);
      }
      if (!isSettings && !isMenu) {
        dispatch(initializeOtherBreadcrumbs());
      }
      setTimeout(() => {
        dispatch(
          setIsLoadingModal({
            active: false,
            message: '',
          }),
        );
      }, 2000);
    }
  };
};

export const getOrganizationLocationData = ({
  organizationId,
  locationId,
  roleId,
}: IInitializationParams): AppThunk<Promise<boolean>> => {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    try {
      const response = await apiCall(
        CallMethods.GET,
        endpoints.initialization({
          organizationId,
          locationId,
        }),
        true,
      );

      const {
        locationGroups,
        settings,
        menu,
        tableLayout,
        register: { drawers },
      } = response;
      const streetLine1 = (settings as ISettingsReducerStore).location.general
        .streetLine1;

      const {
        master: masterSettings,
        location: locationSettings,
        remote: remoteLocationSettings,
        versions: versionsSettings,
      } = settings;
      //Locations
      dispatch(setLocationGroupsOnState(locationGroups));

      //Settings
      dispatch(setMasterSettings(masterSettings));
      dispatch(setLocationSettings(locationSettings));
      dispatch(setRemoteLocationSettings(remoteLocationSettings));
      dispatch(setSettingsVersions(versionsSettings));

      //Menus
      dispatch(setMasterMenu(menu.master));
      dispatch(setLocationMenu(menu.location));
      dispatch(setMenuVersions(menu.versions));

      const state = getState();

      const hasMultipleLocations = selectShouldRenderLocationDropdown(state);

      if (hasMultipleLocations) {
        dispatch(
          setSelectedSettingsMode({
            settingsType: SettingsTypes.LOCATION_SETTINGS,
            currentSettings: locationSettings,
            settingsVersion: versionsSettings.location || 0,
            id: locationId,
            name: streetLine1,
          }),
        );
        dispatch(
          setSelectedMenuMode({
            menuType: MenuTypes.LOCATION_MENU,
            currentMenu: menu.location as IMenuStore,
            menuVersion: menu?.versions?.location as number,
            id: locationId,
            name: streetLine1,
          }),
        );
      } else {
        dispatch(
          setSelectedSettingsMode({
            settingsType: SettingsTypes.MASTER_SETTINGS,
            currentSettings: masterSettings,
            settingsVersion: versionsSettings.master || 0,
            id: 0,
            name: 'Enterprise',
          }),
        );
        dispatch(
          setSelectedMenuMode({
            menuType: MenuTypes.MASTER_MENU,
            currentMenu: menu.master as IMenuStore,
            menuVersion: menu?.versions?.master as number,
            id: 0,
            name: 'Enterprise',
          }),
        );
      }

      //Register
      dispatch(setDrawers(drawers));

      setOrgDataOnCache({
        organizationId,
        locationId,
        roleId,
      });

      dispatch(setLocationId(locationId));
      dispatch(setOrganizationId(organizationId));

      dispatch(setLoggedInUserAndRole(roleId));

      //Table Layout
      dispatch(setTableLayoutData(tableLayout));

      dispatch(setIsLoading(false));

      return true;
    } catch (error) {
      dispatch(setIsLoading(false));
      return false;
    }
  };
};

export const changeRemoteLocation =
  (locationId: number): AppThunk<Promise<boolean>> =>
  // returns true if role selection by user is required
  async (dispatch, getState) => {
    let state = getState();
    const organizationId = state.app.organizationId;

    let locationName = '';
    for (const locationGroup of Object.values(state.locations)) {
      for (const location of locationGroup.locations) {
        if (location.id === locationId) {
          locationName = location.name;
          break;
        }
      }
    }

    dispatch(
      setIsLoadingModal({
        active: true,
        message: `Loading Location "${locationName}" Data...`,
      }),
    );

    const gotLocationDataSuccessfully = await dispatch(
      getOrganizationLocationData({
        organizationId,
        locationId,
      }),
    );

    dispatch(
      setIsLoadingModal({
        active: false,
        message: '',
      }),
    );

    if (!gotLocationDataSuccessfully) return false;

    state = getState();

    const loggedInUserRoles = selectLocationUserRoles(state);

    if (loggedInUserRoles.length === 1) {
      dispatch(setLoggedInUserAndRole(loggedInUserRoles[0].roleId));
      return false;
    } else if (loggedInUserRoles.length < 1) {
      console.error('User has no roles');
      return false;
    }

    return true;
  };

export const changeLocation =
  (locationId: number, locationName: string): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    // Change Menu change data
    dispatch(changeSelectedMenuModeWeb(locationId, locationName));
    // Change Settings change dat
    dispatch(changeSelectedSettingsModeWeb(locationId, locationName));

    const getMenuType = (id: number) => {
      const currentLocationId = getState().app.locationId;
      switch (id) {
        case 0:
          return 'master';
        case currentLocationId:
          return 'location';
        default:
          return 'otherLocation';
      }
    };
    const mode = getMenuType(locationId);

    let shouldShowRolesModal = false;

    if (mode === 'otherLocation') {
      shouldShowRolesModal = await dispatch(changeRemoteLocation(locationId));
    }

    dispatch(actionCreatorsMenu.setDefaultData() as unknown as AppThunk);
    dispatch(
      actionCreatorsSettings.setDefaultSettingsData() as unknown as AppThunk,
    );

    return shouldShowRolesModal;
  };

export const replaceMasterWithLocation = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (locationId === 0) return;

    let locationName = '';
    for (const locationGroup of Object.values(state.locations)) {
      for (const location of locationGroup.locations) {
        if (location.id === locationId) {
          locationName = location.name;
          break;
        }
      }
    }

    dispatch(changeLocation(locationId, locationName));
  };
};

export const checkForAllChangesAndNavigate = (
  onContinue: () => void,
): AppThunk => {
  return async dispatch => {
    const handleOnContinue = () => {
      onContinue();
    };

    if (isMenuPath(location.pathname)) {
      if (isFreshSheetPath()) {
        dispatch(checkForFreshSheetChangesAndNavigateWeb(handleOnContinue));
      } else {
        dispatch(
          checkForChangesAndNavigateWeb(handleOnContinue, useRefreshMenuWeb),
        );
      }
    } else if (isSettingsPath(location.pathname)) {
      if (isTableLayoutPath()) {
        dispatch(checkForLayoutChangesWeb(handleOnContinue));
      } else
        dispatch(
          checkForSettingsChangesAndNavigateWeb(
            handleOnContinue,
            useRefreshSettingsWeb,
            () => null,
          ),
        );
    } else {
      handleOnContinue();
    }
  };
};

export const checkForChangesOnRefreshWeb = (
  onHasChanges?: () => void,
  onContinue?: () => void,
): AppThunk => {
  return async (_, getState) => {
    const state = getState();
    const hasChanges =
      state.settings.changeData.hasChanges ||
      state.menu.changeData.hasChanges ||
      state.menu.changeFreshSheetData.hasChanges;

    if (hasChanges) {
      return onHasChanges && onHasChanges();
    } else {
      return onContinue && onContinue();
    }
  };
};

export const getHasChanges = (): AppThunk<boolean> => {
  return (_, getState) => {
    const state = getState();
    const hasChanges =
      state.settings.changeData.hasChanges ||
      state.menu.changeData.hasChanges ||
      state.menu.changeFreshSheetData.hasChanges;

    if (hasChanges) {
      return true;
    } else {
      return false;
    }
  };
};

export const webSocketRouter =
  (
    { type, payload }: websocketMapType,
    licenseSettingsId: number,
  ): AppThunk<void> =>
  dispatch => {
    switch (type) {
      case UPDATE_SETTINGS:
        dispatch(
          updateSettingsOnSocket(
            {
              ...payload,
              remote: null,
            } as IUpdateSettingsResponse,
            licenseSettingsId,
          ) as unknown as AppThunk<void>,
        );
        break;
      case UPDATE_MENU:
        dispatch(
          updateMenuOnSocketWeb({
            ...payload,
            remote: null,
          } as IUpdateMenuResponse) as unknown as AppThunk<void>,
        );
        break;
      case UPDATE_PRINTER_STATUS:
        dispatch(handlePrinterStatusWsWeb(payload));
        break;
    }
  };

export const updateTabTitle = (): AppThunk<void> => {
  return (_, getState) => {
    const state = getState();
    const locationName =
      state.settings.selectedMode.currentSettings.general.name;
    const loggedInRole = selectLoggedInUser(state).loggedInRole;
    const roleName = loggedInRole.name;
    document.title = locationName
      ? `${locationName} | ${roleName}`
      : `TABLETURN | ${roleName}`;
  };
};
export const getNewLocationPath = (locationId: number | string): string => {
  const path = location.pathname.split('/');
  path[2] = locationId.toString();
  return path.join('/');
};

export const updateLocationPath = (
  locationId: number | string,
): AppThunk<void> => {
  return () => {
    if (!locationId) return;

    router.navigate(getNewLocationPath(locationId), { replace: true });
  };
};
