import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import menuCategoryAssignedMenuTypesFactory from '@app/helpers/factories/menu/cardFactories/categories/menuCategoryAssignedMenuTypesFactory';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';

import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectChangeDataId,
  selectCombos,
  selectItems,
  selectMenuTypes,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction, SectionId } from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('categories', SECTION_ID);

const AssignedMenuTypesSection = ({ t }: WithTranslation) => {
  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  const [openAddExistingItem, setOpenAddExistingItem] = useState(false);

  const items = useSelector(selectItems);
  const menuTypes = useSelector(selectMenuTypes);
  const combos = useSelector(selectCombos);
  const categoryId = useSelector(selectChangeDataId);
  const { itemData } = useSelector(getMemoizedItemData);

  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigateWithOrg();

  const assignedMenuTypeIds: number[] = itemData?.menuTypeIds;

  const assignedItemIds: number[] = itemData?.itemIds;
  const assignedComboIds: number[] = itemData?.comboIds;

  const assignedMenuTypesCardList = useMemo(() => {
    return menuCategoryAssignedMenuTypesFactory(
      menuTypes,
      assignedMenuTypeIds,
      items,
      combos,
      assignedItemIds,
      assignedComboIds,
      categoryId,
    ).map(menuType => {
      const pathURL = `/menu/menus/${menuType.id}`;
      return {
        ...menuType,
        onClick: (openInNewTab?: boolean) => {
          if (openInNewTab) return openNewTabWithOrgData(pathURL);

          checkForChangesAndNavigateWeb(
            () =>
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text: menuType.title as string,
                onPress: () => navigate(pathURL),
                pathURL,
              }),
            onRefresh,
          );
        },
        isLink: getPathWithOrgData(pathURL),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    menuTypes,
    assignedItemIds,
    items,
    assignedMenuTypeIds,
    combos,
    assignedComboIds,
  ]);

  const closeModal = () => {
    setOpenAddExistingItem(false);
  };

  return (
    <AssignedElements
      noElementsMessage={t(
        'menuScreen.categoryDetails.associations.menuTypes.emptyCardsMessage',
      )}
      active={openAddExistingItem}
      onModalClose={closeModal}
      elements={assignedMenuTypesCardList}
      subCardProps={{
        title: t('loggedIn.menuModule.tabs.menuTypes'),
        subtitle: t(
          'menuScreen.categoryDetails.associations.menuTypes.description',
        ),
      }}
    />
  );
};

export default AssignedMenuTypesSection;
