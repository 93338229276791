import Card from '@app/components/common/Card';
import MainScreenContainer from '@app/components/common/MainScreenContainer';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { actionCreatorsApp, actionCreatorsMenuWeb } from '@app/state';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import {
  selectBreadCrumbs,
  selectCategories,
  selectMenuTypes,
} from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  dropdownMenuTypeOptionFactory,
  menuCategoryCardFactory,
} from '@westondev/tableturn-core';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CategoriesList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();
  const setShowToast = bindActionCreators(
    actionCreatorsApp.setShowToast,
    dispatch,
  );
  const categories = useSelector(selectCategories);
  const menuTypes = useSelector(selectMenuTypes);
  const breadcrumbs = useSelector(selectBreadCrumbs);

  // Local state
  const navigate = useNavigateWithOrg();

  const categoriesCardList = useMemo(
    () => menuCategoryCardFactory(categories),
    [categories],
  );

  const menuTypesOptions = useMemo(
    () => dropdownMenuTypeOptionFactory(menuTypes),
    [menuTypes],
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const dropdownOptions = useMemo(
    () => [
      [{ label: 'All', value: 0 }],
      [
        { label: t('commonTexts.active'), value: 1 },
        { label: t('commonTexts.inactive'), value: 2 },
      ],
    ],
    [t],
  );

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(2, null, navigate);
      if (!navMain) return;
      dispatch(initializeBucketListBreadcrumb(navMain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleAddNewCategory = () => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewCategoryTag'),
      onPress: () => navigate('/menu/categories/add'),
      pathURL: '/menu/categories/add',
    });
  };

  return (
    <MainScreenContainer
      dropdowns={[
        {
          id: 'activeFilter',
          label: t('commonTexts.status'),
          data: dropdownOptions,
          defaultValue: 1,
          dataExample: 1,
        },
        {
          id: 'menuTypeIds',
          label: t('menuScreen.categoryMain.menuTypesDropdown.label'),
          data: [[{ label: t('commonTexts.all'), value: 0 }], menuTypesOptions],
          dataExample: [],
        },
      ]}
      breakpoint="medium"
      sortAvailable
      data={categoriesCardList}
      optionList={[
        {
          text: t('menuScreen.menuLocation.actionButtons.addNewCategory'),
          handler: handleAddNewCategory,
        },
      ]}
      noItemsText={t('commonTexts.noneFound', {
        elementName: t('screenNames.CategoriesScreen'),
      })}
      onOptionClickDisabled={() => {
        setShowToast({
          type: 'info',
          title: t('menuScreen.categoryMain.disabledActionToast.title'),
          description: t(
            'menuScreen.categoryMain.disabledActionToast.description',
          ),
        });
      }}
      renderItem={category => {
        const pathURL = `/menu/categories/${category.id}`;

        return (
          <Card.Item
            title={category.title}
            isActive={category.status}
            showStatus
            tag={category.customTag}
            onClick={openInNewTab => {
              if (openInNewTab) return openNewTabWithOrgData(pathURL);

              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text: category.title,
                onPress: () => {
                  navigate(pathURL);
                },
                statusPath: `selectedMode.currentMenu.categories.${category.id}.active`,
                pathURL,
              });
            }}
            count={category.itemCount}
            isLink={getPathWithOrgData(pathURL)}
          />
        );
      }}
    />
  );
};

export default CategoriesList;
