import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { Header, Row } from '@tanstack/react-table';

export interface IRenderItem<T> {
  index: number;
  row: Row<T>;
  headers?: Header<T, unknown>[];
  dragListeners?: SyntheticListenerMap;
  isDragging?: boolean;
  isSortLocked?: boolean;
  selectionControls?: boolean;
  isSelected?: boolean;
}
export type TTableModes = 'sort-vertically' | 'basic' | 'selection';

export enum ETableModes {
  SORT_VERTICALLY = 'sort-vertically',
  BASIC = 'basic',
  SELECTION = 'selection',
}

export const DEFAULT_MAX_SIZE = -1;
export const DEFAULT_SIZE = -1;
export const DEFAULT_MIN_SIZE = -1;
export const BOX_ALIGN = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};
