import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import CardsContainer from '@app/components/common/CardsContainer';
import Dropdown from '@app/components/common/Dropdown';
import { IDropdownItem } from '@app/components/common/Dropdown/types';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import MoneyInput from '@app/components/common/MoneyInput';
import OptionsModal from '@app/components/common/OptionsModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import TaxesSelectionModal from '@app/components/common/SelectionModal/TaxesSelectionModal';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';

import {
  areSettingsMasterModeSelector,
  currentTaxesSettingsSelector,
  makeSelectSettingsData,
  selectOrderTypesAvailable,
  settingsErrorsSelector,
} from '@app/state/selectors/settingsSelectors';
import { selectServiceAreas } from '@app/state/selectors/tableLayoutSelectors';
import {
  assignToToValue,
  chargeDiscountToValue,
  chargeTypeToValue,
  generateOrderTypeOptions,
  minGuestsOptions,
  valueToAssignTo,
  valueToChargeDiscount,
  valueToChargeType,
  valueToOrderType,
} from '@app/state/settings/paymentsFactory';
import { store } from '@app/state/store';
import {
  INVISIBLE_CHAR,
  IPaymentsSettings,
  IServiceCharge,
  ITaxTypeData,
  OrderType,
  ServiceChargeType,
  SettingsSectionId,
  TaxType,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  formatTax,
  getPaymentsId,
  serviceAreasDropdownOptionsFactory,
} from '@westondev/tableturn-core';
import { without } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const gridItemSizingInputs = {
  mb: 20,
  sm: 10,
  lg: 4,
};

const SECTION_ID = SettingsSectionId.PAYMENTS;
const getMemoizedItemData =
  makeSelectSettingsData<IPaymentsSettings>(SECTION_ID);

interface IServiceChargeFormProps extends WithTranslation {
  serviceChargeId: number | string | null;
  navigateBack: () => void;
}

const ServiceChargeForm = ({
  t,
  serviceChargeId,
  navigateBack,
}: IServiceChargeFormProps) => {
  const { data } = useSelector(getMemoizedItemData);

  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [modalActiveType, setModalActiveType] = useState<
    'orderTypes' | 'serviceAreas' | null
  >(null);

  useEffect(() => {
    if (
      data &&
      !data?.serviceCharges?.[serviceChargeId || 0] &&
      serviceChargeId
    ) {
      navigateBack();
    }
  }, [data, serviceChargeId, navigateBack]);

  const orderTypesAvailable = useSelector(selectOrderTypesAvailable);
  const settingsTaxes = useSelector(currentTaxesSettingsSelector);
  const serviceAreas = useSelector(selectServiceAreas);
  const settingsErrors = useSelector(settingsErrorsSelector);
  const isMasterMode = useSelector(areSettingsMasterModeSelector);

  const isCreation = isNaN(Number(serviceChargeId));

  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    useDispatch(),
  );

  const serviceAreasDropdownOptions = useMemo(
    () => serviceAreasDropdownOptionsFactory(t, serviceAreas, false),
    [t, serviceAreas],
  );

  const orderTypeDropdownOptions = useMemo(
    () => generateOrderTypeOptions(t, orderTypesAvailable),
    [t, orderTypesAvailable],
  );

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const serviceChargeData = data?.serviceCharges?.[serviceChargeId || 0];

  const updateRow = useCallback(
    (
      newValue: number | string | OrderType[] | number[] | boolean,
      id: number | string,
      field: keyof IServiceCharge,
    ) => {
      const tableObject = store.getState().settings.changeData
        .data as IPaymentsSettings;

      const serviceChargesData = tableObject?.serviceCharges;

      const extraUpdatedFields: Partial<IServiceCharge> = {};

      if (
        field === 'chargeType' &&
        newValue !== serviceChargesData[id].chargeType
      ) {
        extraUpdatedFields.charge = 0;
      }

      setValue<IPaymentsSettings>(
        {
          ...tableObject,
          serviceCharges: {
            ...serviceChargesData,
            [id]: {
              ...serviceChargesData[id],
              ...extraUpdatedFields,
              [field]: newValue,
            },
          },
        },
        SECTION_ID,
      );
    },
    [setValue],
  );

  const generateCustomCards = useCallback(
    (taxes: number[]) => {
      const cards = taxes.map(id => ({
        id: id,
        title: settingsTaxes[id].name,
        onDelete: (taxId: number | string) => {
          const newItemMenuTypeTaxes = without(
            serviceChargeData?.taxIds,
            Number(taxId),
          );

          updateRow(newItemMenuTypeTaxes, serviceChargeData?.id, 'taxIds');
        },
        onPress: () => null,
      }));

      return cards;
    },
    [settingsTaxes, serviceChargeData, updateRow],
  );

  const itemMenuTypeTaxCards = useMemo(() => {
    const taxes = serviceChargeData?.taxIds || [];

    const filteredTaxes: number[] = [];

    for (const id of taxes) {
      if (
        settingsTaxes[id].name
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1
      ) {
        filteredTaxes.push(id);
      }
    }

    const cards = generateCustomCards(filteredTaxes);

    return cards;
  }, [serviceChargeData, generateCustomCards, searchText, settingsTaxes]);

  const updateOrderTypes = (values: IDropdownItem[]) => {
    const orderTypes = values.map(value => valueToOrderType[value.value]);

    updateRow(orderTypes, serviceChargeData?.id, 'orderTypes');
  };

  const generateDropdownSelectedServiceAreas = () => {
    const selectedServiceAreas = new Set(serviceChargeData?.serviceAreas || []);

    const selectedOptions = serviceAreasDropdownOptions[0].filter(
      dropdownItem => {
        if (selectedServiceAreas.has(dropdownItem.value)) {
          return true;
        }
      },
    );

    return selectedOptions;
  };

  const getDropdownSelectedOrderTypes = () => {
    const selectedOrderTypes = new Set(serviceChargeData?.orderTypes || []);

    const selectedOptions = orderTypeDropdownOptions.filter(dropdownItem => {
      if (selectedOrderTypes.has(valueToOrderType[dropdownItem.value])) {
        return true;
      }
    });

    return selectedOptions;
  };

  const generateDescription = (
    taxType: TaxType,
    typeData: ITaxTypeData | null,
  ) => {
    const description = formatTax(taxType, typeData);

    return description === 'N/A'
      ? t('settingsModule.taxesSettings.taxesTable.taxTypes.table')
      : description;
  };

  const handleOnAssociate = (newButtons: IItemSelection[]) => {
    const newButtonIds = newButtons.map(button => Number(button.id));

    const newTaxes = [...(serviceChargeData?.taxIds || []), ...newButtonIds];

    updateRow(newTaxes, serviceChargeData?.id, 'taxIds');
    setIsSelectionModalOpen(false);
  };

  const closeModal = () => {
    setModalActiveType(null);
  };

  const handleNameChange = (value: string) => {
    if (serviceChargeData?.posName === serviceChargeData?.name) {
      updateRow(value, serviceChargeData?.id, 'posName');
    }
    updateRow(value, serviceChargeData?.id, 'name');
  };

  useEffect(() => {
    if (!serviceChargeData && isCreation) {
      navigateBack();
    }
  }, [serviceChargeData, isCreation, navigateBack]);

  return (
    serviceChargeData && (
      <>
        <TaxesSelectionModal
          onAssociate={handleOnAssociate}
          active={isSelectionModalOpen}
          onModalClose={() => setIsSelectionModalOpen(false)}
          taxIds={serviceChargeData?.taxIds || []}
          scrollCardSectionNumberOfColumns={6}
        />
        <OptionsModal
          title={
            modalActiveType === 'serviceAreas'
              ? t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.selectServiceAreas',
                )
              : t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.selectOrderTypes',
                )
          }
          noElementMessage={
            modalActiveType === 'serviceAreas'
              ? t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.noServiceAreas',
                )
              : t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.noOrderTypes',
                )
          }
          options={
            modalActiveType === 'serviceAreas'
              ? serviceAreasDropdownOptions[0]
              : orderTypeDropdownOptions
          }
          onChangeDone={values => {
            if (modalActiveType === 'orderTypes') {
              updateOrderTypes(values);
            } else {
              const serviceAreaIds = values.map(value => value.value);
              updateRow(serviceAreaIds, serviceChargeData?.id, 'serviceAreas');
            }
            setModalActiveType(null);
          }}
          isActiveByDefault={!!modalActiveType}
          hideTriggerButton
          onCancel={closeModal}
          onOutsidePress={closeModal}
          currentOptionsSelected={
            modalActiveType === 'serviceAreas'
              ? generateDropdownSelectedServiceAreas()
              : getDropdownSelectedOrderTypes()
          }
          isSearchable
          allowMultipleSelection
        />
        <Card
          csx={{
            height: 'auto',
          }}>
          <Box
            csx={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
            }}>
            <Typography fontWeight="medium" variant="subtitle">
              {t(
                'settingsModule.paymentsSettings.serviceChargeSettings.title',
              ).toUpperCase()}
            </Typography>
            <Switch
              label={t(
                'settingsModule.paymentsSettings.serviceChargeSettings.status',
              )}
              checked={serviceChargeData.active}
              onChange={value =>
                updateRow(value, serviceChargeData?.id, 'active')
              }
            />
          </Box>
          <Grid
            columnGap={25}
            rowGap={35}
            columns={20}
            csx={{
              marginTop: '20px',
            }}>
            <Grid.Item {...gridItemSizingInputs}>
              <Input
                required
                label={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.name',
                )}
                placeholder={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.name',
                )}
                value={serviceChargeData?.name}
                onChange={e => handleNameChange(e.target.value)}
                onFocus={() => {
                  clearSettingsError(
                    getPaymentsId(serviceChargeData?.id, 'name'),
                  );
                }}
                error={Boolean(
                  settingsErrors[getPaymentsId(serviceChargeData?.id, 'name')],
                )}
                disabled={!isMasterMode && !isCreation}
              />
            </Grid.Item>
            <Grid.Item {...gridItemSizingInputs}>
              <Input
                required
                label={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.posName',
                )}
                placeholder={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.posName',
                )}
                value={serviceChargeData?.posName}
                onChange={e =>
                  updateRow(e.target.value, serviceChargeData?.id, 'posName')
                }
                onFocus={() => {
                  clearSettingsError(
                    getPaymentsId(serviceChargeData?.id, 'posName'),
                  );
                }}
                error={Boolean(
                  settingsErrors[
                    getPaymentsId(serviceChargeData?.id, 'posName')
                  ],
                )}
              />
            </Grid.Item>
            <Grid.Item {...gridItemSizingInputs}>
              <Dropdown
                label={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.chargeType',
                )}
                data={[
                  [
                    {
                      label: t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.fixed',
                      ),
                      value: 0,
                    },
                    {
                      label: t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.percent',
                      ),
                      value: 1,
                    },
                  ],
                ]}
                value={chargeTypeToValue[serviceChargeData.chargeType]}
                onChange={value => {
                  updateRow(
                    valueToChargeType[value],
                    serviceChargeData?.id,
                    'chargeType',
                  );
                  if (value === 0) {
                    updateRow(
                      valueToChargeDiscount['1'],
                      serviceChargeData?.id,
                      'discount',
                    );
                  }
                }}
                placeholder=""
              />
            </Grid.Item>
            <Grid.Item {...gridItemSizingInputs}>
              <MoneyInput
                required
                prefix={
                  !(serviceChargeData?.chargeType === ServiceChargeType.PERCENT)
                    ? '$'
                    : INVISIBLE_CHAR
                }
                suffix={
                  serviceChargeData?.chargeType === ServiceChargeType.PERCENT
                    ? '%'
                    : INVISIBLE_CHAR
                }
                precision={
                  serviceChargeData?.chargeType === ServiceChargeType.PERCENT
                    ? 0
                    : 2
                }
                maxValue={
                  serviceChargeData?.chargeType === ServiceChargeType.PERCENT
                    ? 100
                    : undefined
                }
                value={
                  serviceChargeData?.charge ? serviceChargeData?.charge : 0
                }
                onValueChange={value =>
                  updateRow(value, serviceChargeData?.id, 'charge')
                }
                label={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.amount',
                )}
                delimiter=""
                error={Boolean(
                  settingsErrors[
                    getPaymentsId(serviceChargeData?.id, 'charge')
                  ],
                )}
                onFocus={() =>
                  clearSettingsError(
                    getPaymentsId(serviceChargeData?.id, 'charge'),
                  )
                }
              />
            </Grid.Item>
            <Grid.Item {...gridItemSizingInputs}>
              <Dropdown
                label={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.prePostDiscount',
                )}
                data={[
                  [
                    {
                      label: t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.preDiscount',
                      ),
                      value: 0,
                    },
                    {
                      label: t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.postDiscount',
                      ),
                      value: 1,
                    },
                  ],
                ]}
                isDisabled={
                  serviceChargeData?.chargeType === ServiceChargeType.FIXED
                }
                value={chargeDiscountToValue[serviceChargeData.discount]}
                onChange={value =>
                  updateRow(
                    valueToChargeDiscount[value],
                    serviceChargeData?.id,
                    'discount',
                  )
                }
                placeholder=""
              />
            </Grid.Item>
            <Grid.Item {...gridItemSizingInputs}>
              <Dropdown
                label={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.assignTo',
                )}
                data={[
                  [
                    {
                      label: t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.checkOwner',
                      ),
                      value: 0,
                    },
                    {
                      label: t(
                        'settingsModule.paymentsSettings.serviceChargeSettings.restaurant',
                      ),
                      value: 1,
                    },
                  ],
                ]}
                value={assignToToValue[serviceChargeData.assignTo]}
                onChange={value =>
                  updateRow(
                    valueToAssignTo[value],
                    serviceChargeData?.id,
                    'assignTo',
                  )
                }
                placeholder=""
              />
            </Grid.Item>
            <Grid.Item {...gridItemSizingInputs}>
              <Dropdown.MultiSelect
                required
                label={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.orderTypes',
                )}
                placeholder={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.orderTypes',
                )}
                data={
                  orderTypeDropdownOptions.length ===
                  getDropdownSelectedOrderTypes().length
                    ? [[{ value: 0, label: t('commonTexts.all') }]]
                    : [orderTypeDropdownOptions]
                }
                value={
                  orderTypeDropdownOptions.length ===
                  getDropdownSelectedOrderTypes().length
                    ? [0]
                    : getDropdownSelectedOrderTypes().map(
                        option => option.value,
                      )
                }
                onChange={() => {}}
                isEditable={false}
                onActive={() => setModalActiveType('orderTypes')}
                error={Boolean(
                  settingsErrors[
                    getPaymentsId(serviceChargeData?.id, 'orderTypes')
                  ],
                )}
              />
            </Grid.Item>
            <Grid.Item {...gridItemSizingInputs}>
              <Dropdown
                label={t(
                  'settingsModule.paymentsSettings.serviceChargeSettings.minimumGuests',
                )}
                data={[minGuestsOptions()]}
                value={serviceChargeData.minGuests}
                onChange={value =>
                  updateRow(value, serviceChargeData?.id, 'minGuests')
                }
                placeholder=""
              />
            </Grid.Item>
          </Grid>
          <Card.SubCardOptions
            title={t(
              'settingsModule.paymentsSettings.serviceChargeSettings.associatedTaxes',
            )}
            searchBarProps={{
              value: searchText,
              onSort: undefined,
              isSortDisabled: true,
              setInputValue: setSearchText,
            }}
            actionOptions={[
              {
                text: t('menuScreen.itemDetails.taxesSection.addTax'),
                icon: false,
                handler: () => setIsSelectionModalOpen(true),
              },
            ]}
            isEditButtonDisabled={false}
            onEditMode={setEditMode}
            csx={{
              marginTop: '40px',
            }}>
            <CardsContainer
              data={itemMenuTypeTaxCards}
              noElementsMessage={t(
                'menuScreen.itemDetails.taxesSection.noTaxes',
              )}
              renderItem={element => (
                <Card.Item
                  {...element}
                  csx={theme => ({
                    borderColor: `${theme.colors.lightGrey} !important`,
                  })}
                  onRemoveClick={() => element.onDelete(element.id)}
                  subTitle={generateDescription(
                    settingsTaxes[element.id].taxType,
                    settingsTaxes[element.id].typeData,
                  )}
                  showRemoveIcon={editMode}
                />
              )}
            />
          </Card.SubCardOptions>
        </Card>
      </>
    )
  );
};

export default ServiceChargeForm;
