import { getCellSizes } from '@app/helpers/components/tableHelpers';
import { RadioSelectionStates } from '@app/types';
import { TCsx, useTheme } from '@emotion/react';
import { Cell, ColumnDef, flexRender } from '@tanstack/react-table';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { isValidElement, memo } from 'react';
import Box from '../../Box';
import Button from '../../Button';
import { CHECKBOX_SIZE } from '../../Checkbox/styles';
import Icon from '../../Icon';
import RadioCircle from '../../Icon/custom/RadioCircle';
import Typography from '../../Typography';
import { BOX_ALIGN, IRenderItem } from '../types';
import { dragControlStyles } from './styles';
import { FlexAlignType } from './types';

interface ITableRow<T> {
  item: IRenderItem<T>;
  noPadding?: boolean;
  itemHeight?: number;
  cellProps?: TCsx | undefined;
  bodyCellCsx?: TCsx;
  align?: {
    [key: string]: 'left' | 'center' | 'right';
  };
  renderEmptyValues?: boolean;
  isStriped?: boolean;
  columns: ColumnDef<T, any>[];
  showRemoved?: boolean;
  rowIdPrefix?: string;
  isTableInverted?: boolean;
}

// eslint-disable-next-line react-refresh/only-export-components
const TableRow = <T,>({
  noPadding,
  itemHeight,
  cellProps,
  align = {},
  isStriped,
  bodyCellCsx,
  showRemoved,
  rowIdPrefix = 'table-row-',
  isTableInverted = false,
  ...props
}: ITableRow<T>) => {
  const theme = useTheme();
  const {
    row,
    index,
    dragListeners,
    isDragging,
    isSortLocked,
    selectionControls,
    isSelected,
  } = props.item;

  const renderTextValue = (cell: Cell<T, unknown>) => {
    return !isNil(cell.getContext().getValue()) &&
      cell.getContext().getValue() !== '' ? (
      <Typography>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Typography>
    ) : (
      <Typography align="left" color="black">
        - - -
      </Typography>
    );
  };

  return (
    <Box
      id={`${rowIdPrefix}${row.id}`}
      key={`${rowIdPrefix}${row.id}`}
      csx={{
        display: 'flex',
        flexDirection: 'row',
        zIndex: 1,
      }}>
      <Box
        csx={[
          {
            display: 'flex',
            backgroundColor: showRemoved
              ? theme.colors.lighterRed
              : !isStriped
              ? undefined
              : index % 2 === 0
              ? theme.colors.lightestExtraGrey
              : theme.colors.white,
            borderBottom:
              !isStriped && !isTableInverted
                ? `1px solid ${theme.colors.lightGrey}`
                : 'none',
            paddingInline: noPadding ? '0px' : '25px',
            paddingLeft: noPadding || dragListeners ? '10px' : '25px',
            gap: '10px',
            width: '100%',
            flexDirection: isTableInverted ? 'column' : 'row',
            alignItems: isTableInverted ? 'flex-start' : 'center',
            height: isTableInverted ? 'auto' : itemHeight,
          },
          cellProps,
        ]}>
        {dragListeners && (
          <Box
            key="dragControl"
            id="dragControl"
            csx={[
              dragControlStyles,
              isSortLocked ? { cursor: 'default !important' } : {},
            ]}
            {...(!isSortLocked ? dragListeners : {})}>
            <Box csx={{ paddingTop: '3px' }}>
              <Icon
                name={isSortLocked ? 'MdLock' : 'MdDragIndicator'}
                color={isDragging ? 'persistentSemanticBlue' : 'semanticGrey'}
              />
            </Box>
          </Box>
        )}
        {selectionControls && (
          <Button
            variant="icon"
            onClick={() => row.toggleSelected()}
            icon={
              <RadioCircle
                size={CHECKBOX_SIZE - 5}
                state={
                  isSelected
                    ? RadioSelectionStates.FULL
                    : RadioSelectionStates.EMPTY
                }
                csx={{ marginLeft: '5px' }}
              />
            }
          />
        )}

        {row.getVisibleCells().map((cell: Cell<T, unknown>) => (
          <Box
            key={cell.id}
            id={cell.id}
            csx={[
              {
                display: 'flex',
                alignItems: 'center',
                ...getCellSizes(cell.column, isTableInverted),
                justifyContent: isTableInverted
                  ? 'flex-start'
                  : (BOX_ALIGN[align[cell.column.id]] as FlexAlignType),
              },
              bodyCellCsx,
            ]}>
            {typeof cell?.column?.columnDef?.cell === 'function'
              ? isValidElement(cell.column.columnDef.cell(cell.getContext()))
                ? flexRender(cell.column.columnDef.cell, cell.getContext())
                : renderTextValue(cell)
              : renderTextValue(cell)}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const memoizedTableRow = memo(TableRow, (prevProps, nextProps) => {
  return (
    prevProps.item.isDragging === nextProps.item.isDragging &&
    prevProps.item.isSortLocked === nextProps.item.isSortLocked &&
    prevProps.columns === nextProps.columns &&
    prevProps.isTableInverted === nextProps.isTableInverted &&
    prevProps.item.isSelected === nextProps.item.isSelected &&
    isEqual(prevProps.item.row.original, nextProps.item.row.original) &&
    isEqual(prevProps.cellProps, nextProps.cellProps) &&
    isEqual(prevProps.bodyCellCsx, nextProps.bodyCellCsx) &&
    isEqual(prevProps.align, nextProps.align)
  );
});
export default memoizedTableRow as typeof TableRow;
