import { RootState, store } from '@app/state/store';
import {
  CoreRootState,
  dateTimeMsToTodayDateCore,
  getDateFromUTCCore,
  getHourMsCore,
  getTimeFromUTCCore,
} from '@westondev/tableturn-core';
import startCase from 'lodash/startCase';
import { DateObjectUnits, DateTime, DateTimeFormatOptions } from 'luxon';

export const getTimeZoneWithoutContinent = (timeZone: string) => {
  const dividedTimeZone = timeZone.split('/');
  dividedTimeZone.splice(0, 1);
  return dividedTimeZone.join('/');
};

export const timeZoneToText = (timeZone: string) => {
  const timeZoneAbbreviation = DateTime.fromObject(
    {
      year: 2012,
      month: 1,
    },
    { zone: timeZone },
  ).toFormat('ZZZZ');
  const dividedTimeZone = getTimeZoneWithoutContinent(timeZone)
    .split('/')
    .map(timeZonePart => startCase(timeZonePart));
  const timeZoneString = dividedTimeZone.join(' - ');

  return `${timeZoneString} (${timeZoneAbbreviation})`;
};

export const dateTimeMsToTodayDate = (dateTime: number, useTimeZone = false) =>
  dateTimeMsToTodayDateCore(
    dateTime,
    useTimeZone,
    store.getState() as CoreRootState & RootState,
  );

export const getHourMs = (date: string, useTimeZone = true) =>
  getHourMsCore(
    date,
    store.getState() as CoreRootState & RootState,
    useTimeZone,
  );

export const getHourAndMinutesAndSeconds = (
  date: string,
  useTimeZone = false,
): DateObjectUnits => {
  const timeZone =
    store.getState().settings.selectedMode.currentSettings.general.timeZone;
  let lDate = DateTime.fromISO(date, useTimeZone ? { zone: timeZone } : {});
  if (!useTimeZone) {
    lDate = lDate.toUTC();
  }
  return {
    hour: lDate.get('hour'),
    minute: lDate.get('minute'),
    second: lDate.get('second'),
  };
};

export const getDateFromUTC = (date: string) =>
  getDateFromUTCCore(date, store.getState() as unknown as CoreRootState);

export const getTimeFromUTC = (
  date: string,
  supportLanguage = true,
  applyTimeZone = true,
  timeFormatter: DateTimeFormatOptions = DateTime.TIME_24_SIMPLE,
) =>
  getTimeFromUTCCore(
    date,
    store.getState() as unknown as CoreRootState,
    supportLanguage,
    applyTimeZone,
    timeFormatter,
  );
