import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import { selectChangeDataId } from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import { TChange } from '@app/types';
import {
  CoreRootState,
  IModifierItem,
  TModifierItemModifierGroupDiff,
  TPricingVersionDiff,
  diffModifierItemsFactory,
  formatMoney,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import useRootSelector from '@app/hooks/useRootSelector';
import MenuTypeVersionsButtons from '../../common/MenuTypeVersionsButtons';
import Typography from '@app/components/common/Typography';
import useItemChangeColor from '@app/hooks/useItemChangeColor';
import { kitchenTicketDiffModalStyles } from '../../SettingsDiffConfirmationModal/KitchenTicketDiffModal/styles';
import Divider from '@app/components/common/Divider';

const ModifierItemsDiffModal = ({ t }: WithTranslation) => {
  const { differences, data: modifierItemData } = useRootSelector(
    state => state.menu.changeData,
  );
  const modifierItemId = useSelector(selectChangeDataId);
  const {
    borderContainerColor,
    boxColor,
    boxTextAndBorderColor,
    boxRemovedColor,
    boxRemovedTextColor,
    subtitleColor,
  } = useItemChangeColor();

  const formattedDifferences = useMemo(() => {
    return diffModifierItemsFactory(
      modifierItemId,
      differences,
      modifierItemData as IModifierItem,
      store.getState() as CoreRootState,
    );
  }, [modifierItemId, differences, modifierItemData]);

  const [selectedPricingVersion, setSelectedMenuTypeVersion] = useState(0);

  const formatValue = (value: any, field: string, textValue?: string) => {
    if (typeof value === 'boolean') {
      // if value is boolean return yes, no
      if (field === 'active') {
        return value ? t('commonTexts.active') : t('commonTexts.inactive');
      } else return value ? t('commonTexts.yes') : t('commonTexts.no');
    } else if (typeof value === 'number') {
      if (
        [
          'includedMasterPrice',
          'additionalMasterPrice',
          'includedLightPrice',
          'includedRegularPrice',
          'includedExtraPrice',
          'additionalLightPrice',
          'additionalRegularPrice',
          'additionalExtraPrice',
          'defaultSelectionPrice',
          'onSidePrice',
        ].includes(field)
      ) {
        return formatMoney(null, value);
      }
      // if value is number, format the number depending on the field
      else if (
        field === 'quarterPricePercent' ||
        field === 'halfPricePercent'
      ) {
        return `${Number(value)}%`;
      } else if (field === 'defaultModifierGroupPricingVersionId') {
        return textValue;
      } else return value; // return just the number
    } else return value;
  };

  const renderChange = (change: TChange) => {
    if (!change.field) return null;
    return <RenderChange change={change} formatValue={formatValue} />;
  };

  const renderPricingOptionsButtons = (
    pricingVersions: TPricingVersionDiff[],
  ) => {
    return (
      <MenuTypeVersionsButtons
        menuTypeVersions={pricingVersions}
        selectedMenuTypeVersion={selectedPricingVersion}
        onPress={setSelectedMenuTypeVersion}
      />
    );
  };

  const renderModifierGroups = (
    modifierGroups: TModifierItemModifierGroupDiff[],
  ) => {
    return modifierGroups.map((modifierGroup, modifierGroupIndex) => {
      const sortPriority = modifierGroup.changes.filter(
        change => change.field === 'sortPriority',
      );
      const changes = modifierGroup.changes.filter(
        change => change.field !== 'sortPriority',
      );

      const wasRemoved = modifierGroup.action === 'remove';
      const wasAdded = modifierGroup.action === 'add';
      return (
        <Box
          key={`modifierGroup_${modifierGroup.label}`}
          csx={{
            backgroundColor: 'white',
            borderRadius: '10px',
            marginBottom:
              modifierGroups.length > 0 &&
              modifierGroupIndex !== modifierGroups.length - 1
                ? '15px'
                : '0',
            width: '100%',
            border: `1px solid ${borderContainerColor} !important`,
            overflow: 'hidden',
          }}>
          {/* Header */}
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px 20px',
            }}>
            <Box
              csx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Box>
                <Typography
                  variant="subtitle"
                  color={subtitleColor}
                  fontWeight="medium">
                  {modifierGroup.label}
                </Typography>
                <Typography variant="caption" color="darkestGrey">
                  {t(
                    'menuScreen.itemDetails.modifierGroupsSection.modifierName',
                    { name: modifierGroup.label },
                  )}
                </Typography>
              </Box>
              {wasAdded && (
                <Box
                  csx={{
                    display: 'flex',
                    minWidth: '80px',
                    height: '50px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 10px',
                    backgroundColor: boxColor,
                    borderRadius: '10px',
                    border: `1px solid ${boxTextAndBorderColor}`,
                    marginLeft: '15px',
                  }}>
                  <Typography
                    csx={{
                      display: 'flex',
                      color: boxTextAndBorderColor,

                      textAlign: 'center',
                    }}>
                    {t('app.modals.itemsDiff.menuType.newModifierGroup')}
                  </Typography>
                </Box>
              )}
            </Box>
            {wasRemoved ? (
              <Box
                csx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '80px',
                  height: '50px',
                  justifyContent: 'center',
                  backgroundColor: boxRemovedColor,
                  borderRadius: '10px',
                  borderColor: boxRemovedTextColor,
                  borderWidth: '1px',
                  marginLeft: '15px',
                  paddingHorizontal: '10px',
                }}>
                <Typography
                  csx={{
                    color: boxRemovedTextColor,

                    textAlign: 'center',
                  }}>
                  {t('app.modals.diffModal.removed')}
                </Typography>
              </Box>
            ) : (
              sortPriority.length === 1 && (
                <Box
                  csx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Typography>{sortPriority[0].label}:</Typography>
                  <Box
                    csx={{
                      display: 'flex',
                      minWidth: '80px',
                      height: '50px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: boxColor,
                      borderRadius: '10px',
                      border: `1px solid ${boxTextAndBorderColor}`,
                      marginLeft: '15px',
                    }}>
                    <Typography
                      csx={{
                        display: 'flex',
                        color: boxTextAndBorderColor,

                        textAlign: 'center',
                      }}>
                      {sortPriority[0].value as string}
                    </Typography>
                  </Box>
                </Box>
              )
            )}
          </Box>
          {/* Options */}
          {!wasRemoved && changes.length > 0 && (
            <>
              <Divider csx={{ margin: '10px 0 0 0' }} />
              <Grid rowGap={10} columnGap={20} csx={{ padding: '16px 20px' }}>
                {changes.map((change, idx) => (
                  <Grid.Item sm={3} key={`mod_item_change_${idx}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
        </Box>
      );
    });
  };

  return (
    <Box>
      {formattedDifferences && (
        <>
          {formattedDifferences.masterChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.commonChanges')}
                color="black"
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.masterChanges.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`master_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {formattedDifferences.inventory.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.inventory')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.inventory.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`inventory_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {formattedDifferences.pricingVersions.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.pricingVersions')}
              />
              <Box>
                {renderPricingOptionsButtons(
                  formattedDifferences.pricingVersions,
                )}
              </Box>
              <Box
                csx={[
                  kitchenTicketDiffModalStyles,
                  theme => ({
                    backgroundColor: theme.colors.white,
                    border: `2px solid ${borderContainerColor}`,
                  }),
                ]}>
                {formattedDifferences.pricingVersions[selectedPricingVersion]
                  .action === 'remove' && (
                  <Typography
                    csx={{
                      textAlign: 'center',
                    }}>
                    {t(
                      'app.modals.modifierItemsDiffModal.pricing.pricingVersionRemoved',
                    )}
                  </Typography>
                )}

                {formattedDifferences.pricingVersions[selectedPricingVersion]
                  ?.changes.length > 0 && (
                  <>
                    <HeaderDiffModal
                      name={t(
                        'app.modals.diffModal.sectionTitles.settings.ticketDisplay',
                      )}
                      color="black"
                    />
                    <Grid columnGap={20} rowGap={10}>
                      {formattedDifferences.pricingVersions[
                        selectedPricingVersion
                      ]?.changes
                        .filter(
                          change =>
                            change.field.includes('Master') ||
                            change.field.includes('name'),
                        )
                        .map(change => (
                          <Grid.Item
                            mb={12}
                            sm={6}
                            md={4}
                            lg={4}
                            key={`change_${change.field}`}>
                            {renderChange(change)}
                          </Grid.Item>
                        ))}
                    </Grid>

                    {formattedDifferences.pricingVersions[
                      selectedPricingVersion
                    ].changes.filter(
                      change =>
                        change.field.includes('Master') ||
                        change.field.includes('name'),
                    ).length > 0 &&
                      formattedDifferences.pricingVersions[
                        selectedPricingVersion
                      ].changes.filter(
                        change =>
                          !change.field.includes('Master') &&
                          !change.field.includes('name'),
                      ).length > 0 && (
                        <Divider
                          lineCsx={{
                            borderColor: borderContainerColor,
                          }}
                          csx={{ margin: '10px 0' }}
                        />
                      )}

                    <Grid columnGap={20} rowGap={10}>
                      <Grid.Item sm={4}>
                        {formattedDifferences.pricingVersions[
                          selectedPricingVersion
                        ].changes
                          .filter(
                            change =>
                              !change.field.includes('Master') &&
                              !change.field.includes('additional') &&
                              !change.field.includes('Percent') &&
                              !change.field.includes('name'),
                          )
                          .map(renderChange)}
                      </Grid.Item>
                      <Grid.Item sm={4}>
                        {formattedDifferences.pricingVersions[
                          selectedPricingVersion
                        ].changes
                          .filter(
                            change =>
                              !change.field.includes('Master') &&
                              change.field.includes('additional') &&
                              !change.field.includes('Percent') &&
                              !change.field.includes('name'),
                          )
                          .map(renderChange)}
                      </Grid.Item>
                      <Grid.Item sm={4}>
                        {formattedDifferences.pricingVersions[
                          selectedPricingVersion
                        ].changes
                          .filter(
                            change =>
                              !change.field.includes('Master') &&
                              !change.field.includes('additional') &&
                              change.field.includes('Percent') &&
                              !change.field.includes('name'),
                          )
                          .map(renderChange)}
                      </Grid.Item>
                    </Grid>
                  </>
                )}
              </Box>
            </>
          )}

          {formattedDifferences &&
            formattedDifferences.modifiers.length > 0 && (
              <>
                <HeaderDiffModal
                  name={t('app.modals.itemsDiff.sectionTitles.modifierGroups')}
                />
                {renderModifierGroups(formattedDifferences.modifiers)}
              </>
            )}

          {formattedDifferences &&
            formattedDifferences.modifierGroups.length > 0 && (
              <>
                <HeaderDiffModal
                  name={t('app.modals.diffModal.sectionTitles.associations')}
                />
                <RenderChange
                  change={{
                    field: 'parentModifierGroupIds',
                    label: t('menuScreen.common.bucket.modifierGroups.plural'),
                    value: formattedDifferences.modifierGroups,
                  }}
                  formatValue={formatValue}
                />
              </>
            )}
        </>
      )}
    </Box>
  );
};

export default ModifierItemsDiffModal;
