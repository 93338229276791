import { css } from '@emotion/react';
export const loadingModalStyles = (mode: 'state' | 'local') =>
  css({
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    backgroundColor:
      mode === 'state' ? 'rgba(75,75,75,.6)' : 'rgba(255,255,255,.8)',
    userSelect: 'none',
    ' .spinnerContainer': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    ' .icon': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
  });
