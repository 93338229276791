import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IItemDetailsSection } from '../types';
import ItemPrepStationSection from './ItemPrepStationSection';
import { ItemPrepStationSectionKind } from '@app/hocs/types';
import { FormMode } from '@westondev/tableturn-core';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';

const PrepStationsSection = ({
  t,
  menuTypeVersionId,
  sectionId,
  itemData,
  mode,
  isDisabled,
  ...accordionSectionProps
}: IItemDetailsSection) => {
  return (
    <AccordionSection
      disabledLayer={isDisabled && <NoMenuTypeLayer />}
      title={t('menuScreen.itemDetails.prepStationsSection.title')}
      info={t('menuScreen.itemDetails.prepStationsSection.prepStations.toolTip')}
      {...accordionSectionProps}>
      <ItemPrepStationSection
        menuTypeVersionId={menuTypeVersionId}
        sectionId={sectionId}
        itemData={itemData}
        mode={mode}
        kind={ItemPrepStationSectionKind.ADD}
      />
      {mode === FormMode.MASS_UPDATE && (
        <ItemPrepStationSection
          menuTypeVersionId={menuTypeVersionId}
          sectionId={sectionId}
          itemData={itemData}
          mode={mode}
          kind={ItemPrepStationSectionKind.REMOVE}
        />
      )}
    </AccordionSection>
  );
};

export default PrepStationsSection;
