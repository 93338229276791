import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Checkbox from '@app/components/common/Checkbox';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import Typography from '@app/components/common/Typography';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  ICombo,
  IComboMenuTypeVersion,
  IMassUpdate,
  IMenuTypeVersion,
  MassUpdateValues,
  SectionId,
  actionCreatorsMenu,
  formatMoney,
  menuSelectors,
  useFormError,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import PricingModal from './PricingModal';
import Card from '@app/components/common/Card';
import { store } from '@app/state/store';

const SUB_PRICES = ['onlinePrice', 'deliveryPrice', 'toGoPrice'];

const sectionId = SectionId.BASIC;

const gridItemSizing = {
  mb: 15,
  sm: 5,
  md: 5,
  xl: 3,
};

interface IPriceSubSection extends WithTranslation {
  menuTypeVersionId: number;
  itemData: IMenuTypeVersion | IComboMenuTypeVersion | IMassUpdate;
  itemId: number;
  isCombo: boolean | undefined;
}

const getSubsections = (
  t: WithTranslation['t'],
  isCombo: boolean | undefined,
) => {
  if (isCombo) {
    return [
      {
        name: t('menuScreen.comboDetails.pricingSection.comboBasePrice'),
        info: 'Info',
        key: 'combo' as const,
      },
      {
        name: t('menuScreen.itemDetails.pricingSection.onlinePrice'),
        info: 'Info',
        key: 'online' as const,
      },
      {
        name: t('menuScreen.itemDetails.pricingSection.deliveryPrice'),
        info: 'Info',
        key: 'delivery' as const,
      },
      {
        name: t('menuScreen.itemDetails.pricingSection.toGoPrice'),
        info: 'Info',
        key: 'toGo' as const,
      },
    ];
  }

  return [
    {
      name: t('menuScreen.itemDetails.pricingSection.itemPrice'),
      info: t('menuScreen.itemDetails.pricingSection.itemPricetoolTip'),
      key: 'item' as const,
    },
    {
      name: t('menuScreen.itemDetails.pricingSection.onlinePrice'),
      info: t('menuScreen.itemDetails.pricingSection.onlinePricetoolTip'),
      key: 'online' as const,
    },
    {
      name: t('menuScreen.itemDetails.pricingSection.deliveryPrice'),
      info: t('menuScreen.itemDetails.pricingSection.deliveryPricetoolTip'),
      key: 'delivery' as const,
    },
    {
      name: t('menuScreen.itemDetails.pricingSection.toGoPrice'),
      info: t('menuScreen.itemDetails.pricingSection.toGoPricetoolTip'),
      key: 'toGo' as const,
    },
  ];
};

const PricingSubsection = ({
  t,
  menuTypeVersionId,
  itemId,
  itemData,
  isCombo,
}: IPriceSubSection) => {
  // Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );
  const locationIds = useRootSelector(menuSelectors.selectAllLocationIds);

  const [modalActive, setModalActive] = useState(false);
  const [itemName, setItemName] = useState('');

  const SUB_SECTIONS = useMemo(
    () => getSubsections(t, isCombo),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCombo],
  );

  return (
    <>
      <PricingModal
        itemName={itemName ? itemName : ''}
        isActive={modalActive}
        onCloseModal={() => setModalActive(false)}
        itemId={itemId || 0}
        menuTypeId={menuTypeVersionId}
        locationIds={locationIds ?? []}
        isCombo={false}
      />
      <Card.SubCard title={t('menuScreen.itemDetails.pricingSection.title')}>
        <Grid rowGap={20} columnGap={30}>
          <Grid.Item>
            <Grid
              rowGap={20}
              columnGap={20}
              columns={15}
              csx={{ alignItems: 'flex-start' }}>
              {SUB_SECTIONS.map(({ name, info, key }) => (
                <PriceSubSection
                  key={key}
                  isCombo={isCombo}
                  name={name}
                  info={info}
                  keyPrefix={key}
                  menuTypeVersionId={menuTypeVersionId}
                  itemData={itemData as IMenuTypeVersion}
                />
              ))}

              <Grid.Item {...gridItemSizing}>
                <Box csx={{ paddingTop: '26px' }}>
                  <Button
                    variant="primary"
                    csx={{
                      width: '100%',
                    }}
                    onClick={() => {
                      const currentName =
                        (store.getState().menu.changeData?.data as ICombo)
                          ?.name ?? '';
                      setItemName(currentName);
                      setModalActive(true);
                    }}>
                    {t('menuScreen.itemDetails.pricingSection.allLocationsBtn')}
                  </Button>
                </Box>
              </Grid.Item>
            </Grid>
          </Grid.Item>

          {/* Checkboxes Row */}
          <Grid.Item>
            <Grid columnGap={20} rowGap={20} columns={15}>
              <Grid.Item {...gridItemSizing} csx={{ justifyContent: 'center' }}>
                <Checkbox
                  label={
                    isCombo
                      ? t(
                          'menuScreen.comboDetails.pricingSection.isTaxInclusive',
                        )
                      : t(
                          'menuScreen.itemDetails.pricingSection.isTaxInclusive',
                        )
                  }
                  info={t('menuScreen.itemDetails.pricingSection.isTaxInclusivetoolTip')}
                  checked={
                    itemData
                      ? itemData?.taxInclusive === MassUpdateValues.NO_CHANGES
                        ? false
                        : itemData.taxInclusive
                      : false
                  }
                  onChange={taxInclusive =>
                    setValue({ taxInclusive }, sectionId, menuTypeVersionId)
                  }
                />
              </Grid.Item>
              <Grid.Item {...gridItemSizing} csx={{ justifyContent: 'center' }}>
                <Checkbox
                  label={t(
                    'menuScreen.itemDetails.pricingSection.excludeFromSuggestedTip',
                  )}
                  info={t(
                    'menuScreen.itemDetails.pricingSection.excludeFromSuggestedTiptoolTip',
                  )}
                  checked={
                    itemData
                      ? itemData?.excludeFromSuggestedTip ===
                        MassUpdateValues.NO_CHANGES
                        ? false
                        : itemData.excludeFromSuggestedTip
                      : false
                  }
                  onChange={excludeFromSuggestedTip =>
                    setValue(
                      { excludeFromSuggestedTip },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                />
              </Grid.Item>
              <Grid.Item {...gridItemSizing} csx={{ justifyContent: 'center' }}>
                <Checkbox
                  label={t(
                    'menuScreen.itemDetails.pricingSection.excludeFromDiscounts',
                  )}
                  info={t(
                    'menuScreen.itemDetails.pricingSection.excludeFromDiscountstoolTip',
                  )}
                  checked={
                    itemData
                      ? itemData?.excludeFromDiscounts ===
                        MassUpdateValues.NO_CHANGES
                        ? false
                        : itemData.excludeFromDiscounts
                      : false
                  }
                  onChange={excludeFromDiscounts =>
                    setValue(
                      { excludeFromDiscounts },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </Grid>
      </Card.SubCard>
    </>
  );
};

const PriceSubSection = ({
  name,
  info,
  keyPrefix: key,
  menuTypeVersionId,
  itemData,
  isCombo,
}: {
  name: string;
  info: string;
  keyPrefix: 'item' | 'combo' | 'online' | 'delivery' | 'toGo';
  menuTypeVersionId: number;
  itemData: IMenuTypeVersion | IComboMenuTypeVersion;
  isCombo: boolean | undefined;
}) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { t } = useTranslation();

  const priceError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.${key}Price`,
  );

  const handlePriceChange = (itemPrice: number) => {
    const changes: Record<string, number> = {};
    let previousPrice = 0; // (itemData as IMenuTypeVersion).itemPrice;

    if (isCombo) {
      previousPrice = (itemData as IComboMenuTypeVersion)?.comboBasePrice;
      changes.comboBasePrice = itemPrice;
    } else {
      previousPrice = (itemData as IMenuTypeVersion)?.itemPrice;
      changes.itemPrice = itemPrice;
    }

    SUB_PRICES.forEach(subPrice => {
      const value = (itemData as IMenuTypeVersion)[
        subPrice as keyof IMenuTypeVersion
      ];
      if (value === 0 || value === previousPrice) {
        changes[subPrice] = itemPrice;
      }
    });

    setValue(changes, sectionId, menuTypeVersionId);
    setValue({}, SectionId.REGISTER, menuTypeVersionId);
  };

  const price = useMemo(() => {
    if (key === 'combo') {
      return (
        (itemData as unknown as IComboMenuTypeVersion)?.comboBasePrice ?? 0
      );
    }
    if (key === 'item') {
      return (itemData as IMenuTypeVersion)?.itemPrice ?? 0;
    }

    return (
      (itemData as IMenuTypeVersion | IComboMenuTypeVersion)?.[`${key}Price`] ||
      0
    );
  }, [itemData, key]);

  return (
    <Grid.Item {...gridItemSizing} key={key}>
      <Box
        csx={{
          marginBottom: '10px',
        }}>
        <MoneyInput
          label={name}
          info={info}
          value={price}
          onValueChange={value => {
            if (key === 'item' || key === 'combo') {
              handlePriceChange(value);
            } else
              setValue(
                { [`${key}Price`]: value },
                sectionId,
                menuTypeVersionId,
              );
          }}
          error={!!priceError?.error}
          caption={priceError?.error || undefined}
          onFocus={() => {
            priceError && priceError?.clear();
          }}
        />
      </Box>
      {!isCombo && key !== 'combo' && (
        <>
          <Typography variant="caption" csx={{ marginBottom: '10px' }}>
            {name} + {t('app.modals.itemsDiff.pricing.modPrice')}
          </Typography>
          <Typography color="persistentSemanticBlue" fontWeight="medium">
            {itemData
              ? formatMoney(
                  '',
                  (itemData as IMenuTypeVersion)?.[`${key}Price`] +
                    (itemData as IMenuTypeVersion)?.defaultModifiersPrice,
                )
              : formatMoney('', 0)}
          </Typography>
        </>
      )}
    </Grid.Item>
  );
};

export default PricingSubsection;
