import Card from '@app/components/common/Card';
import MainScreenContainer from '@app/components/common/MainScreenContainer';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { actionCreatorsMenuWeb } from '@app/state';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import {
  selectBreadCrumbs,
  selectCardSpecialList,
} from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SpecialsList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const specialsList = useSelector(selectCardSpecialList);

  const breadcrumbs = useSelector(selectBreadCrumbs);

  const navigate = useNavigateWithOrg();

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(7, null, navigate);
      if (!navMain) return;
      dispatch(initializeBucketListBreadcrumb(navMain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleAddNewSpecial = () => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewSpecialTag'),
      onPress: () => navigate('/menu/specials/add'),
      pathURL: '/menu/specials/add',
    });
  };

  return (
    <>
      <MainScreenContainer
        noItemsText={t('commonTexts.noneFound', {
          elementName: t('screenNames.SpecialsScreen'),
        })}
        sortAvailable
        data={specialsList}
        optionList={[
          {
            text: t('menuScreen.menuLocation.actionButtons.addNewSpecial'),
            handler: handleAddNewSpecial,
          },
        ]}
        renderItem={special => {
          const pathURL = `/menu/specials/${special.id}`;
          return (
            <Card.Item
              {...special}
              isActive={true}
              showStatus={false}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: special.title,
                  onPress: () => {
                    navigate(pathURL);
                  },
                  statusPath: `selectedMode.currentMenu.specials${special.id}.active`,
                  pathURL,
                });
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          );
        }}
      />
    </>
  );
};

export default SpecialsList;
