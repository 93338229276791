import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Modal from '@app/components/common/Modal/Modal';

import useRootSelector from '@app/hooks/useRootSelector';
import * as actionCreatorsApp from '@app/state/app/actions';
import {
  selectIsMassUpdate,
  selectLocationGroupsObject,
} from '@app/state/menu/menuSelectors';
import { makeSelectLocationModalMode } from '@app/state/selectors/webSettingsSelectors';
import { AppThunk, useAppDispatch } from '@app/state/store';
import {
  CONFIRMATION_MODAL_INITIAL_STATE,
  IConfirmationModal,
  ILocationGroupResume,
  ILocationsObject,
  ISelectLocationGroupRow,
  actionCreatorsMenu,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  filter,
  menuSelectors,
  useSort,
} from '@westondev/tableturn-core';
import React, { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '../../Grid';
import SearchInput from '../../SearchInput';
import SelectLocationGroupRow from './SelectLocationGroupRow';
import selectLocationReducer from './SelectLocationReducer';
const { getSettingsLocationsToApply } = actionCreatorsSettings;
const { setSelectedSettingsLocations } = actionCreatorsSettingsChangeData;

const { makeBucketAllowsMasterSaveSelector } = menuSelectors;

const {
  getLocationsToApply,
  setMassUpdateSelectedLocations,
  setSelectedLocations,
} = actionCreatorsMenu;

type ISelectLocationsModal = IConfirmationModal &
  WithTranslation & {
    filteredLocationIds: number[];
    variant: 'settings' | 'menu';
  };

const SelectLocationsModal = ({
  t,
  active: isActive,
  onSuccess,
  filteredLocationIds,
  variant,
}: ISelectLocationsModal) => {
  // REDUX
  const dispatch = useDispatch();

  const selectLocationModalMode = useMemo(makeSelectLocationModalMode, []);
  const selectBuckedAllowsMasterSave = useMemo(
    () => makeBucketAllowsMasterSaveSelector(variant),
    [variant],
  );

  const {
    isMasterMode,
    id: currentLocation,
    userLocation,
  } = useRootSelector(state => selectLocationModalMode(state, variant));
  const locationGroupsObject = useSelector(selectLocationGroupsObject);
  const isMassUpdate = useSelector(selectIsMassUpdate);
  const bucketAllowsMasterSave = useSelector(selectBuckedAllowsMasterSave);

  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const onSort = useSort<ISelectLocationGroupRow>('name');
  const [searchValue, setSearchValue] = React.useState('');

  const onCloseModal = () =>
    setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);

  const [baseState, update] = React.useReducer(selectLocationReducer, [
    ...(isMasterMode || !bucketAllowsMasterSave
      ? []
      : [
          {
            locationGroupId: -1,
            locations: [
              {
                description: 'Enterprise',
                enabled: true,
                locationId: 0,
                name: 'Enterprise',
                selected: false,
              },
            ],
            name: 'Enterprise',
            hideExpand: true,
          },
        ]),

    ...(generateLocationObject(
      locationGroupsObject,
      currentLocation,
      filteredLocationIds,
      isMasterMode,
      userLocation,
    ) as ISelectLocationGroupRow[]),
  ]);

  const getSelectedLocationsArray = () => {
    const selectedLocations: number[] = [];
    baseState.forEach(locationGroup =>
      locationGroup.locations.forEach(
        location =>
          location.selected && selectedLocations.push(location.locationId),
      ),
    );

    return isMasterMode
      ? selectedLocations
      : [currentLocation, ...selectedLocations];
  };

  useEffect(() => {
    dispatch(
      variant === 'menu'
        ? isMassUpdate
          ? setMassUpdateSelectedLocations(getSelectedLocationsArray())
          : setSelectedLocations(getSelectedLocationsArray())
        : setSelectedSettingsLocations(getSelectedLocationsArray()),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseState]);

  const [slice, setSlice] =
    React.useState<ISelectLocationGroupRow[]>(baseState);

  React.useEffect(() => {
    setSlice(
      filter<ISelectLocationGroupRow>(
        baseState,
        { name: searchValue },
        null,
        onSort,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseState, searchValue]);

  return (
    <Modal
      title={t('app.modals.selectLocation.title')}
      isActive={isActive}
      onModalClose={onCloseModal}
      noPadding
      footer={
        <>
          <Button onClick={onCloseModal} csx={{ minWidth: '100px' }}>
            {t('commonButtons.cancel')}
          </Button>
          <Button
            variant="primary"
            csx={{ minWidth: '100px' }}
            onClick={onSuccess}>
            {t('commonButtons.save')}
          </Button>
        </>
      }>
      <Box csx={{ padding: '20px' }}>
        <Grid columnGap={15} rowGap={15}>
          <Grid.Item mb={8} sm={9} md={10}>
            <Box>
              <SearchInput onChange={setSearchValue} value={searchValue} />
            </Box>
          </Grid.Item>
          <Grid.Item mb={4} sm={3} md={2}>
            <Button onClick={() => update({ type: 'selectAll' })}>
              {t('app.modals.selectLocation.selectAll')}
            </Button>
          </Grid.Item>
          <Grid.Item>
            {slice.map((item, index) => (
              <SelectLocationGroupRow
                key={item.locationGroupId}
                handleLocationGroupToggle={(locationGroupId, state) =>
                  update({
                    type: 'toggleLocationGroup',
                    locationGroup: locationGroupId,
                    state,
                  })
                }
                handleLocationToggle={(
                  locationGroupId: number,
                  locationId: number,
                ) => {
                  update({
                    type: 'toggleLocation',
                    location: locationId,
                    locationGroup: locationGroupId,
                  });
                }}
                locations={item.locations}
                locationGroupId={item.locationGroupId}
                name={item.name}
                isFirst={index === 0}
                isLast={index === slice.length - 1}
                hideExpand={item.hideExpand}
                hideCounter={item.hideExpand}
              />
            ))}
          </Grid.Item>
        </Grid>
      </Box>
    </Modal>
  );
};

const generateLocationObject = (
  locationGroupsObject: ILocationsObject,
  currentLocation: number,
  filteredLocationIds: number[],
  isMasterMode: boolean,
  userLocation: number,
): ISelectLocationGroupRow[] =>
  Object.entries(locationGroupsObject)
    .map(([key, locGrp]: [string, ILocationGroupResume]) => ({
      locationGroupId: Number(key),
      name: locGrp.name,
      locations: Object.values(locGrp.locations)
        .filter(location => filteredLocationIds.includes(location.locationId))
        .map(location => ({
          selected:
            location.locationId === userLocation &&
            userLocation === currentLocation,
          enabled: !isMasterMode
            ? location.locationId !== currentLocation
            : true,
          locationId: location.locationId,
          name: location.name,
          description: location.description,
        })),
    }))
    .filter(locationGroup => locationGroup.locations.length > 0);

const SelectLocationsModalWrapper = ({
  ...props
}: IConfirmationModal & WithTranslation & { variant: 'settings' | 'menu' }) => {
  const dispatch = useAppDispatch();
  const locationIds = dispatch(
    (props.variant === 'menu'
      ? getLocationsToApply()
      : getSettingsLocationsToApply()) as unknown as AppThunk<number[]>,
  );

  return <SelectLocationsModal {...props} filteredLocationIds={locationIds} />;
};
export default SelectLocationsModalWrapper;
