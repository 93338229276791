import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import FilterGenericSelectionModal from '@app/components/common/SelectionModal/FilterGenericSelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import menuCategoryAssignedItemsFactory from '@app/helpers/factories/menu/cardFactories/categories/menuCategoryAssignedItemsFactory';
import { getSelectionModalMenuTypeFilterValue } from '@app/helpers/menu/modals/itemsSelectionModal';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';

import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectMenu,
  selectMenuTypes,
} from '@app/state/menu/menuSelectors';
import { TCsx, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  ISubcategory,
  SectionId,
  actionCreatorsMenu,
  getMenuCategoryMenuTypeElements,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import without from 'lodash/without';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const EMPTY_OBJECT = {};
interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
  hideEdit?: boolean;
  hideMoreOptions?: boolean;
  bucket: 'items' | 'combos';
  categoryId?: number;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('subcategories', SECTION_ID);

const AssignedItemsCombosSection = ({
  csx,
  hideEdit,
  hideMoreOptions,
  t,
  bucket,
  categoryId,
}: IAssignedItemsCombosSection) => {
  // Redux
  const elements = useRootSelector(state => selectMenu(state)[bucket]);
  const menuTypes = useSelector(selectMenuTypes);
  const { itemData } = useSelector(getMemoizedItemData);
  const { id: subcategoryId } = useParams();

  const { updateSubcategory: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  // Local state
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [menuTypeFilter, setMenuTypeFilter] = useState([0]);
  const [filteredList, setFilteredList] = useState<
    typeof assignedElementsCardList
  >([]);

  const isItem = bucket === 'items';
  const elementIdsField = isItem ? 'itemIds' : 'comboIds';
  const menuTypeElementIdsField = isItem
    ? 'menuTypeItemIds'
    : 'menuTypeComboIds';
  const bucketUrl = bucket === 'combos' ? 'combos/combos' : bucket;

  const navigate = useNavigateWithOrg();

  const onRefresh = useRefreshMenuWeb();

  const theme = useTheme();

  const assignedItemIds: ISubcategory['menuTypeItemIds'] =
    itemData?.menuTypeItemIds || EMPTY_OBJECT;
  const assignedComboIds: ISubcategory['menuTypeComboIds'] =
    itemData?.menuTypeComboIds || EMPTY_OBJECT;

  const assignedMenuTypeElementIds =
    itemData?.[menuTypeElementIdsField] || EMPTY_OBJECT;

  const assignedElementsCardList = useMemo(
    () => {
      const handleDeleteItemFromSubcategory = (
        deletedElementId: number,
        deletedMenuTypeId: number,
      ) => {
        const clonedAssignedMenuTypeElementIds = cloneDeep(
          assignedMenuTypeElementIds,
        );

        let elementIds: number[] =
          get(clonedAssignedMenuTypeElementIds, `${deletedMenuTypeId}`) || [];

        elementIds = without(elementIds, deletedElementId);

        if (elementIds.length === 0) {
          unset(clonedAssignedMenuTypeElementIds, `${deletedMenuTypeId}`);
        } else {
          set(
            clonedAssignedMenuTypeElementIds,
            `${deletedMenuTypeId}`,
            elementIds,
          );
        }

        const newElementIds = getMenuCategoryMenuTypeElements(
          clonedAssignedMenuTypeElementIds,
        );
        setValue({
          [elementIdsField]: newElementIds,
          [menuTypeElementIdsField]: clonedAssignedMenuTypeElementIds,
        });
      };

      return menuCategoryAssignedItemsFactory(
        elements,
        menuTypes,
        { 0: assignedMenuTypeElementIds },
        (id, text, openInNewTab) => {
          const pathURL = `/menu/${isItem ? 'items' : 'combos'}/${id}`;
          if (openInNewTab) return openNewTabWithOrgData(pathURL);

          const _navigate = () => navigate(pathURL);
          checkForChangesAndNavigateWeb(
            () =>
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text,
                onPress: _navigate,
                pathURL,
              }),
            onRefresh,
          );
        },
        handleDeleteItemFromSubcategory,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements, menuTypes, assignedMenuTypeElementIds],
  );

  const closeModal = () => setOpenSelectionModal(false);

  const handleAssociate = (buttons: IItemSelection[]) => {
    const currentMenuTypeElementIds: ISubcategory['menuTypeItemIds'] =
      cloneDeep(itemData?.[menuTypeElementIdsField]);

    buttons.forEach(button => {
      const menuTypeId = button.menuTypeId;
      const elementIds = get(currentMenuTypeElementIds, `${menuTypeId}`) || [];

      if (elementIds.includes(button.id)) {
        return;
      }

      elementIds.push(button.id);

      set(currentMenuTypeElementIds, `${menuTypeId}`, elementIds);
    });

    const newElementIds = getMenuCategoryMenuTypeElements(
      currentMenuTypeElementIds,
    );

    setValue({
      [menuTypeElementIdsField]: currentMenuTypeElementIds,
      [elementIdsField]: newElementIds,
    });
    closeModal();
  };

  useEffect(() => {
    if (!assignedElementsCardList) {
      return;
    }
    const list = menuTypeFilter.includes(0)
      ? assignedElementsCardList
      : [...assignedElementsCardList].filter(element =>
          menuTypeFilter.includes(element.menuTypeId),
        );
    setFilteredList(list);
  }, [assignedElementsCardList, menuTypeFilter]);

  const filterFunction = useCallback(
    (newElements: IItemSelection[]) =>
      newElements.filter(element => {
        const menuType =
          get(assignedMenuTypeElementIds, `${element.menuTypeId}`) || [];
        if (menuType.includes(element.id)) {
          return false;
        }

        return true;
      }),
    [assignedMenuTypeElementIds],
  );

  const onMenuTypeFilterClear = () => setMenuTypeFilter([0]);

  const onFilter = (option: number) => {
    setMenuTypeFilter(
      getSelectionModalMenuTypeFilterValue(option, menuTypeFilter),
    );
  };

  const menuTypeOptions = useMemo(() => {
    const menuTypeSet = new Set<number>();
    assignedElementsCardList.forEach(button =>
      menuTypeSet.add(button.menuTypeId),
    );

    return [
      { label: t('commonTexts.all'), value: 0 },
      ...Array.from(menuTypeSet).map(menuTypeId => ({
        label: menuTypes[menuTypeId]?.name || '',
        value: menuTypeId,
      })),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedElementsCardList, t]);

  const menuTypeFilterNode = useMemo(
    () => (
      <FilterGenericSelectionModal<number>
        options={[
          {
            id: 1,
            label: t('menuScreen.common.bucket.menuTypes.plural'),
            options: menuTypeOptions,
          },
        ]}
        currentValue={[{ id: 1, values: menuTypeFilter }]}
        onFilter={onFilter as (options: unknown, id: number) => void}
        onClear={onMenuTypeFilterClear}
        triggerButtonProps={{
          csx: { minWidth: '50px', width: '50px' },
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuTypeOptions, menuTypeFilter],
  );

  return (
    <>
      <AssignedElements
        type={bucket}
        detailsScreenProps={{
          currentRelationsIds: Object.values(
            isItem ? assignedItemIds : assignedComboIds,
          )
            .flat()
            .map(id => `${id}`),
          wantedEntity: bucket,
        }}
        noElementsMessage={t(
          `menuScreen.categoryDetails.associations.${bucket}.emptyCardsMessage`,
        )}
        filterFunction={filterFunction}
        active={openSelectionModal}
        onAssociate={handleAssociate}
        onModalClose={closeModal}
        elements={filteredList}
        subCardProps={{
          title:
            bucket === 'combos'
              ? t('loggedIn.menuModule.tabs.combos.combos')
              : t('loggedIn.menuModule.tabs.items'),
          onEditMode: hideEdit ? undefined : setEditMode,
          extraOptions: menuTypeFilterNode,
          actionOptions: hideMoreOptions
            ? undefined
            : [
                {
                  text: t(
                    `components.actionButtons.${
                      isItem ? 'addNewItem' : 'addNewCombo'
                    }`,
                  ),
                  icon: true,
                  handler: () =>
                    checkForChangesAndNavigateWeb(
                      () =>
                        addBreadcrumbLocationWeb({
                          action: BreadCrumbAction.ADD,
                          text: t(
                            `components.actionButtons.${
                              isItem ? 'addNewItemTag' : 'addNewComboTag'
                            }`,
                          ),
                          onPress: () => {
                            navigate(`/menu/${bucketUrl}/add`, {
                              state: {
                                categoryId,
                                subcategoryId,
                              },
                            });
                          },
                          pathURL: `/menu/${bucketUrl}/add`,
                        }),
                      onRefresh,
                      true,
                      false,
                      'items',
                    ),
                },
                {
                  text: t(
                    `components.actionButtons.${
                      isItem ? 'addExistingItem' : 'addExistingCombo'
                    }`,
                  ),
                  icon: false,
                  handler: () => setOpenSelectionModal(true),
                },
              ],
          csx,
        }}
        groupByMenuType
        renderItem={item => {
          const _bucket = isItem ? 'items' : 'combos/combos';
          const pathURL = `/menu/${_bucket}/${item.id}`;
          return (
            <Box
              csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Card.Item
                csx={{ borderColor: `${theme.colors.lightGrey} !important` }}
                {...item}
                showRemoveIcon={isEditMode}
                onClick={openInNewTab => {
                  if (openInNewTab) return openNewTabWithOrgData(pathURL);

                  if (isEditMode) {
                    item?.onClick?.(
                      item.id as unknown as any, //click event
                    );
                    return;
                  }
                  checkForChangesAndNavigateWeb(
                    () =>
                      addBreadcrumbLocationWeb({
                        action: BreadCrumbAction.NAV,
                        text: item.title as string,
                        onPress: () => navigate(pathURL),
                        pathURL,
                      }),
                    onRefresh,
                  );
                }}
                isLink={getPathWithOrgData(pathURL)}
              />
            </Box>
          );
        }}
      />
    </>
  );
};

export default AssignedItemsCombosSection;
