import { MODULE_PATH_INDEX } from '@app/constants';
import { getPathWithOrgData } from './navigation';

export const getModulePath = (path?: string) => {
  const pathSplitted = (path || location.pathname).split('/');
  return pathSplitted[MODULE_PATH_INDEX];
};

export const isMenuPath = (path: string) => getModulePath(path) === 'menu';
export const isSettingsPath = (path: string) =>
  getModulePath(path) === 'settings';
export const isFreshSheetPath = () =>
  location.pathname.includes(getPathWithOrgData('/menu/fresh-sheet'));

export const isTableLayoutPath = () =>
  location.pathname.includes(getPathWithOrgData('/settings/table-layout'));

export const isOtherPath = (path: string) =>
  !isMenuPath(path) && !isSettingsPath(path);

export const generatePathKey = () => {
  const _tabKey = Math.random().toString(36).substr(2, 9);
  return _tabKey;
};
