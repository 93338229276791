import { CallMethods } from '@app/constants';
import { baseUrl } from '@westondev/tableturn-core';
import axios, { AxiosError } from 'axios';

export const baseURL = baseUrl;

export interface IGenericRequestError {
  error: {
    type: string;
    requestId: string;
    code: number;
    message: string;
    systemMessage: string;
  };
}

export const apiCall = async <T>(
  method: CallMethods,
  endpoint: string,
  withAuth = false,
  data: T = {} as T,
) => {
  //TODO: use the core api call

  const hasToken = localStorage.getItem('auth');
  const authToken = hasToken ? (hasToken as string) : '';

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      ...(withAuth
        ? {
            Authorization: `Bearer ${authToken}`,
          }
        : {}),
    },
  });

  return axiosInstance
    .request({
      url: withAuth ? `/s${endpoint}` : endpoint,
      method,
      data,
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const axiosError = error as AxiosError<IGenericRequestError>;

      console.error({
        type: 'error',
        title: `Error: ${
          axiosError.response?.data?.error?.requestId ||
          typeof axiosError.response?.data === 'string'
            ? axiosError.response?.data
            : ''
        }`,
        description:
          typeof axiosError.response?.data === 'string'
            ? axiosError.response?.data
            : axiosError.response?.data?.error?.message || '',
      });

      throw error;
    });
};

export const webErrorCodesDispatcher = (
  error: AxiosError<IGenericRequestError>,
) => {
  // TODO: do an action depending on the error
  return error;
};
