import Card from '@app/components/common/Card';
import MainScreenContainer from '@app/components/common/MainScreenContainer';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsApp, actionCreatorsMenuWeb } from '@app/state';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import {
  selectBreadCrumbs,
  selectIngredients,
} from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  menuIngredientCardFactory,
} from '@westondev/tableturn-core';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const IngredientsList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();
  const setShowToast = bindActionCreators(
    actionCreatorsApp.setShowToast,
    dispatch,
  );
  const ingredients = useSelector(selectIngredients);
  const breadcrumbs = useSelector(selectBreadCrumbs);

  // Local state
  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();

  const ingredientCardList = useMemo(
    () => menuIngredientCardFactory(ingredients),
    [ingredients],
  );

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const dropdownOptions = useMemo(
    () => [
      [{ label: 'All', value: 0 }],
      [
        { label: t('commonTexts.active'), value: 1 },
        { label: t('commonTexts.inactive'), value: 2 },
      ],
    ],
    [t],
  );

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(5, 53, navigate);
      if (!navMain) return;
      dispatch(initializeBucketListBreadcrumb(navMain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleAddNewIngredient = () => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewIngredient'),
      onPress: () => navigate('/menu/modifiers/ingredients/add'),
      pathURL: '/menu/modifiers/ingredients/add',
    });
  };

  return (
    <MainScreenContainer
      dropdowns={[
        {
          id: 'activeFilter',
          label: t('commonTexts.status'),
          data: dropdownOptions,
          defaultValue: 1,
          dataExample: 1,
        },
      ]}
      noItemsText={t('commonTexts.noneFound', {
        elementName: t('screenNames.IngredientsScreen'),
      })}
      breakpoint="medium"
      sortAvailable
      data={ingredientCardList}
      optionList={[
        {
          text: t('menuScreen.menuLocation.actionButtons.addNewIngredient'),
          handler: handleAddNewIngredient,
        },
      ]}
      onOptionClickDisabled={() => {
        setShowToast({
          type: 'info',
          title: t('menuScreen.ingredientsMain.disabledActionToast.title'),
          description: t(
            'menuScreen.ingredientsMain.disabledActionToast.description',
          ),
        });
      }}
      renderItem={ingredient => {
        const pathURL = `/menu/modifiers/ingredients/${ingredient.id}`;
        return (
          <Card.Item
            title={ingredient.title}
            isActive={ingredient.isActive}
            showStatus
            tag={t('components.menuCard.tag.itemCount', {
              count: ingredient.itemCount,
            })}
            onClick={openInNewTab => {
              if (openInNewTab) return openNewTabWithOrgData(pathURL);

              checkForChangesAndNavigateWeb(() => {
                const _navigate = () => navigate(pathURL);
                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: ingredient.title,
                  statusPath: `selectedMode.currentMenu.ingredients.${ingredient.id}.active`,
                  onPress: _navigate,
                  pathURL,
                });
                _navigate();
              }, onRefresh);
            }}
            count={ingredient.itemCount}
            isLink={getPathWithOrgData(pathURL)}
          />
        );
      }}
    />
  );
};

export default IngredientsList;
