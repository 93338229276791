import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import CustomizingReceipts from '@app/components/LoggedIn/Settings/Printing/Ticket/KitchenTicket/CustomizingReceipts';
import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Grid from '@app/components/common/Grid';
import useIsSet from '@app/hooks/useIsSet';
import { actionCreatorsSettingsWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModifierDisplay from '../KitchenTicket/ModifierDisplay';
import PrintTestTicket from '../KitchenTicket/PrintTestTicket';
import CheckDisplay from './CheckDisplay';
import ItemSortPriority from './ItemSortPriority';
import TaxIndicators from './TaxIndicators';
import CustomOrderTypeNames from './CustomOrderTypeNames';

const GuestCheck = ({ t }: WithTranslation) => {
  const loadRegisterSettingsChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadSettingsBucketChangeData,
    useDispatch(),
  );

  const isSet = useIsSet('guestCheck');

  useEffect(() => {
    if (!isSet) loadRegisterSettingsChangeData({ setting: 'guestCheck' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet]);

  return isSet ? (
    <>
      <HeaderTitle
        title={t('settingsModule.guestCheckSettings.title')}
        options={<PrintTestTicket />}
      />

      <Accordion>
        <AccordionSection
          title={t('loggedIn.commonTitles.basic')}
          isExpanded
          titleTextContainerCsx={{ minWidth: '150px' }}>
          <Grid columnGap={15} rowGap={15}>
            <Grid.Item sm={6} md={6} lg={4}>
              <CheckDisplay />
            </Grid.Item>
            <Grid.Item sm={6} md={6} lg={8}>
              <ModifierDisplay
                columns={3}
                hiddenElements={['consolidateByItem', 'groupBySeat']}
              />
            </Grid.Item>
          </Grid>
        </AccordionSection>
        <AccordionSection
          title={t(
            'loggedIn.settingsModule.guestCheckSettings.customizingReceipts.title',
          )}
          isTitleUpperCase={false}
          titleTextContainerCsx={{ minWidth: '150px' }}>
          <Grid columnGap={15} rowGap={15}>
            <Grid.Item sm={12}>
              <CustomizingReceipts
                hiddenElements={['ticketBody']}
                titles={{
                  ticketHeader: t(
                    'loggedIn.settingsModule.guestCheckSettings.customizingReceipts.checkHeader',
                  ),
                  ticketFooter: t(
                    'loggedIn.settingsModule.guestCheckSettings.customizingReceipts.checkFooter',
                  ),
                }}
              />
            </Grid.Item>
          </Grid>
        </AccordionSection>
        <AccordionSection
          title={t('loggedIn.commonTitles.advanced')}
          titleTextContainerCsx={{ minWidth: '150px' }}>
          <Grid columnGap={15} rowGap={15}>
            <Grid.Item mb={12}>
              <CustomOrderTypeNames bucket="guestCheck" />
            </Grid.Item>
            <Grid.Item mb={12}>
              <TaxIndicators />
            </Grid.Item>
            <Grid.Item mb={12}>
              <ItemSortPriority />
            </Grid.Item>
          </Grid>
        </AccordionSection>
      </Accordion>
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default GuestCheck;
