import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IGuestCheckSettings,
  IKitchenTicketSettings,
  IPrepStationVersion,
  IWithPrepStationVersionId,
  MODIFIER_DISPLAY_TYPE_OPTIONS,
  SettingsSectionId,
  actionCreatorsSettings,
  displayModeDropdownFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SettingsSectionId.TICKET_DISPLAY;
const getMemoizedItemData =
  makeSelectSettingsData<IKitchenTicketSettings>(SECTION_ID);

interface IModifierDisplay extends WithTranslation {
  hiddenElements?: Array<
    | 'displayDefaultModifiers'
    | 'consolidateByItem'
    | 'hideModGroupTitles'
    | 'groupSameModItems'
    | 'addSubForMultiSelectOff'
    | 'noForDeselectedDefaultModifiers'
    | 'groupBySeat'
    | 'modifierItemDisplayMode'
    | 'printPriceForFreeModItems'
    | 'onlyPrintModItemsWithPrice'
  >;
  columns?: 2 | 3;
  prepStationVersionId?: IWithPrepStationVersionId['prepStationVersionId'];
}

const ModifierDisplay = ({
  t,
  hiddenElements = [],
  columns = 2,
  prepStationVersionId,
}: IModifierDisplay) => {
  const { updateKitchenTicketSettings, updateGenericSettings } =
    bindActionCreators(actionCreatorsSettings, useDispatch());

  const { data: kitchenTicketSettings } = useSelector(getMemoizedItemData);

  const data = prepStationVersionId
    ? kitchenTicketSettings?.prepStationVersions[prepStationVersionId]
    : (kitchenTicketSettings as unknown as IPrepStationVersion);

  const displayModeDropdownOptions = useMemo(
    () => displayModeDropdownFactory(t),
    [t],
  );

  const setValue = (
    values: Partial<IPrepStationVersion> | Partial<IGuestCheckSettings>,
  ) => {
    if (prepStationVersionId) {
      return updateKitchenTicketSettings(
        values as Partial<IPrepStationVersion>,
        SECTION_ID,
        prepStationVersionId,
      );
    }

    updateGenericSettings<IGuestCheckSettings>(
      values as Partial<IGuestCheckSettings>,
      SECTION_ID,
    );
  };

  return (
    <Card.SubCard
      title={t(
        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.title',
      )}
      csx={{ height: '100%' }}>
      <Grid rowGap={15} columnGap={15}>
        <Grid.Item md={12} xl={columns === 2 ? 12 : 8}>
          <Grid columnGap={15} rowGap={15}>
            {!hiddenElements.includes('displayDefaultModifiers') && (
              <Grid.Item md={12} lg={6}>
                <Checkbox
                  label={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.displayDefaultModifiers',
                  )}
                  info={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.displayDefaultModifierstoolTip',
                  )}
                  checked={data ? data.displayDefaultModifiers : false}
                  onChange={displayDefaultModifiers =>
                    setValue({ displayDefaultModifiers })
                  }
                />
              </Grid.Item>
            )}
            {!hiddenElements.includes('addSubForMultiSelectOff') && (
              <Grid.Item md={12} lg={6}>
                <Checkbox
                  label={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.addSubForMultiSelectOff',
                  )}
                  info={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.addSubForMultiSelectOfftoolTip',
                  )}
                  checked={data ? data.addSubForMultiSelectOff : false}
                  onChange={addSubForMultiSelectOff =>
                    setValue({ addSubForMultiSelectOff })
                  }
                />
              </Grid.Item>
            )}
            {!hiddenElements.includes('consolidateByItem') && (
              <Grid.Item md={12} lg={6}>
                <Checkbox
                  label={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.consolidateByItem',
                  )}
                  info={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.consolidateByItemtoolTip',
                  )}
                  checked={data ? data.consolidateByItem : false}
                  onChange={consolidateByItem =>
                    setValue({ consolidateByItem })
                  }
                />
              </Grid.Item>
            )}

            {!hiddenElements.includes('groupSameModItems') && (
              <Grid.Item md={12} lg={6}>
                <Checkbox
                  label={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.groupSameModItems',
                  )}
                  checked={data ? data.groupSameModItems : false}
                  onChange={groupSameModItems =>
                    setValue({ groupSameModItems })
                  }
                />
              </Grid.Item>
            )}
            {!hiddenElements.includes('printPriceForFreeModItems') && (
              <Grid.Item md={12} lg={6}>
                <Checkbox
                  label={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.printPriceForFreeModItems',
                  )}
                  checked={
                    data
                      ? (data as unknown as IGuestCheckSettings)
                          .printPriceForFreeModItems
                      : false
                  }
                  onChange={printPriceForFreeModItems =>
                    setValue({ printPriceForFreeModItems })
                  }
                />
              </Grid.Item>
            )}

            {!hiddenElements.includes('noForDeselectedDefaultModifiers') && (
              <Grid.Item md={12} lg={6}>
                <Checkbox
                  label={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.noForDeselectedDefaultModifiers',
                  )}
                  checked={data ? data.noForDeselectedDefaultModifiers : false}
                  onChange={noForDeselectedDefaultModifiers =>
                    setValue({ noForDeselectedDefaultModifiers })
                  }
                />
              </Grid.Item>
            )}
            {!hiddenElements.includes('onlyPrintModItemsWithPrice') && (
              <Grid.Item md={12} lg={6}>
                <Checkbox
                  label={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.onlyPrintModItemsWithPrice',
                  )}
                  checked={
                    data
                      ? (data as unknown as IGuestCheckSettings)
                          .onlyPrintModItemsWithPrice
                      : false
                  }
                  onChange={onlyPrintModItemsWithPrice =>
                    setValue({ onlyPrintModItemsWithPrice })
                  }
                />
              </Grid.Item>
            )}
            {!hiddenElements.includes('hideModGroupTitles') && (
              <Grid.Item md={12} lg={6}>
                <Checkbox
                  label={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.hideModGroupTitles',
                  )}
                  info={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.hideModGroupTitlestooltip',
                  )}
                  checked={data ? data.hideModGroupTitles : false}
                  onChange={hideModGroupTitles =>
                    setValue({ hideModGroupTitles })
                  }
                />
              </Grid.Item>
            )}
            {!hiddenElements.includes('groupBySeat') && (
              <Grid.Item md={12} lg={6} lgOffset={6}>
                <Checkbox
                  label={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.groupBySeat',
                  )}
                  info={t(
                    'settingsModule.kitchenTicketSettings.modifierDisplay.groupBySeattoolTip',
                  )}
                  checked={data ? data.groupBySeat : false}
                  onChange={groupBySeat => {
                    setValue({ groupBySeat });
                    updateGenericSettings(
                      {},
                      SettingsSectionId.CUSTOMIZING_RECEIPTS_BODY,
                    );
                  }}
                />
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
        <Grid.Item md={12} xl={columns === 2 ? 6 : 4}>
          {!hiddenElements.includes('modifierItemDisplayMode') && (
            <Dropdown
              label={t(
                'settingsModule.kitchenTicketSettings.modifierDisplay.modifierItemDisplayMode.label',
              )}
              placeholder={t(
                'settingsModule.kitchenTicketSettings.modifierDisplay.modifierItemDisplayMode.label',
              )}
              info={t(
                'settingsModule.kitchenTicketSettings.modifierDisplay.modifierItemDisplayMode.toolTip',
              )}
              data={[displayModeDropdownOptions]}
              value={
                MODIFIER_DISPLAY_TYPE_OPTIONS.indexOf(
                  data?.modifierItemDisplayMode,
                ) + 1
              }
              onChange={newModifierItemDisplayMode =>
                setValue({
                  modifierItemDisplayMode:
                    MODIFIER_DISPLAY_TYPE_OPTIONS[
                      newModifierItemDisplayMode - 1
                    ],
                })
              }
            />
          )}
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default ModifierDisplay;
