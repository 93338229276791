import FilterModal from '@app/components/common/FilterModal';
import FilterModalOption from './FilterModalOption';
import { TFilterGenericSelectionModal } from './types';

const FilterGenericSelectionModal = <T,>({
  options,
  onFilter,
  currentValue = [],
  onClear,
  isClearDisabled,
  onClearAll,
  isClearAllDisabled,
  isActive,
  triggerButtonProps,
}: TFilterGenericSelectionModal<T>) => {
  return (
    <FilterModal
      triggerButtonProps={triggerButtonProps}
      onClearAll={onClearAll}
      isClearAllDisabled={isClearAllDisabled}
      isActive={isActive}>
      {options.map((option, index) => (
        <FilterModalOption
          key={index}
          index={index}
          option={option}
          onFilter={onFilter}
          currentValue={currentValue}
          onClear={onClear}
          isClearDisabled={isClearDisabled}
        />
      ))}
    </FilterModal>
  );
};

export default FilterGenericSelectionModal;
