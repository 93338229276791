import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Grid from '@app/components/common/Grid';
import {
  SectionId,
  MassUpdateValues,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  leftTwoCheckboxesSpacing,
  registerDisplayNavigationCheckbosStyles,
  rightTwoCheckboxesStyles,
} from './styles';

const sectionId = SectionId.REGISTER;

interface IRegisterDisplayGeneralSubsection extends WithTranslation {
  enterItemDetails?: boolean | undefined | MassUpdateValues;
  exitItemDetailsOnRequiredModCompletion?:
    | boolean
    | undefined
    | MassUpdateValues;
  doneIsForTable: boolean | undefined | MassUpdateValues;
  defaultNoSeat: boolean | undefined | MassUpdateValues;
  menuTypeVersionId: number;
  isCombo?: boolean;
}

const RegisterDisplayNavigationSubsection = ({
  t,
  menuTypeVersionId,
  enterItemDetails,
  exitItemDetailsOnRequiredModCompletion,
  doneIsForTable,
  defaultNoSeat,
  isCombo,
}: IRegisterDisplayGeneralSubsection) => {
  // Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  if (isCombo) {
    return (
      <Card.SubCard
        title={t(
          'menuScreen.commonSections.registerDisplay.registerDisplayOptions.registerNavigation',
        )}>
        <Grid
          columns={{
            mb: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 15,
          }}>
          <Grid.Item xl={6} csx={leftTwoCheckboxesSpacing}>
            <Grid rowGap={15} columnGap={15}>
              <Grid.Item md={4} xl={6}>
                <Checkbox
                  info={t(
                    'menuScreen.itemDetails.registerDisplay.doneIsForTable',
                  )}
                  label={t(
                    'menuScreen.itemDetails.registerDisplay.doneIsForTable',
                  )}
                  checked={Boolean(doneIsForTable)}
                  onChange={_doneIsForTable =>
                    setValue(
                      { doneIsForTable: _doneIsForTable },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                />
              </Grid.Item>
              <Grid.Item md={4} xl={6}>
                <Checkbox
                  info={t('menuScreen.itemDetails.basic.defaultNoSeatInfo')}
                  label={t('menuScreen.itemDetails.basic.defaultNoSeat')}
                  checked={Boolean(defaultNoSeat)}
                  onChange={_defaultNoSeat =>
                    setValue(
                      { defaultNoSeat: _defaultNoSeat },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </Grid>
      </Card.SubCard>
    );
  }

  return (
    <Card.SubCard
      title={t(
        'menuScreen.commonSections.registerDisplay.registerDisplayOptions.registerNavigation',
      )}>
      <Grid
        columns={{
          mb: 12,
          sm: 12,
          md: 12,
          lg: 12,
          xl: 15,
        }}>
        <Grid.Item xl={6} csx={leftTwoCheckboxesSpacing}>
          <Grid rowGap={15} columnGap={15}>
            <Grid.Item md={4} xl={6}>
              <Checkbox
                label={t(
                  'menuScreen.itemDetails.registerDisplay.enterItemDetails',
                )}
                info={t(
                  'menuScreen.itemDetails.registerDisplay.enterItemDetailstoolTip',
                )}
                checked={Boolean(enterItemDetails)}
                onChange={_enterItemDetails =>
                  setValue(
                    { enterItemDetails: _enterItemDetails },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
              />
            </Grid.Item>
            <Grid.Item md={4} xl={6}>
              <Checkbox
                label={t(
                  'menuScreen.itemDetails.registerDisplay.exitItemDetailsOnRequiredModsCompletion',
                )}
                info={t(
                  'menuScreen.itemDetails.registerDisplay.exitItemDetailsOnRequiredModsCompletiontoolTip',
                )}
                checked={Boolean(exitItemDetailsOnRequiredModCompletion)}
                onChange={_exitItemDetailsOnRequiredModCompletion =>
                  setValue(
                    {
                      exitItemDetailsOnRequiredModCompletion:
                        _exitItemDetailsOnRequiredModCompletion,
                    },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>
        <Grid.Item xl={9} csx={rightTwoCheckboxesStyles}>
          <Grid rowGap={15} columnGap={15}>
            <Grid.Item md={4} csx={[registerDisplayNavigationCheckbosStyles]}>
              <Checkbox
                info={t(
                  'menuScreen.itemDetails.registerDisplay.doneIsForTable',
                )}
                label={t(
                  'menuScreen.itemDetails.registerDisplay.doneIsForTable',
                )}
                checked={Boolean(doneIsForTable)}
                onChange={_doneIsForTable =>
                  setValue(
                    { doneIsForTable: _doneIsForTable },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
              />
            </Grid.Item>
            <Grid.Item md={4} csx={[registerDisplayNavigationCheckbosStyles]}>
              <Checkbox
                info={t('menuScreen.itemDetails.basic.defaultNoSeatInfo')}
                label={t('menuScreen.itemDetails.basic.defaultNoSeat')}
                checked={Boolean(defaultNoSeat)}
                onChange={_defaultNoSeat =>
                  setValue(
                    { defaultNoSeat: _defaultNoSeat },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default RegisterDisplayNavigationSubsection;
