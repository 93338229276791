import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import TextAreaInput from '@app/components/common/TextAreaInput';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  MassUpdateValues,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { useDispatch } from 'react-redux';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import { IItemDetailsSection } from '../types';
import Checkbox from '@app/components/common/Checkbox';
import MoneyInput from '@app/components/common/MoneyInput';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { MQ_MIN_MEDIUM } from '@app/theme/types';
import isNil from 'lodash/isNil';

const MoreInformationSection = ({
  t,
  menuTypeVersionId,
  itemData,
  sectionId,
  isDisabled,
  isCombo,
  ...props
}: IItemDetailsSection) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  return (
    <AccordionSection
      disabledLayer={isDisabled && <NoMenuTypeLayer />}
      title={t('menuScreen.itemDetails.moreInformation.title')}
      {...props}>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}>
        {!isCombo && (
          <Grid
            columnGap={20}
            csx={theme => ({
              borderBottom: `1px solid ${theme.colors.lightGrey}`,
            })}>
            <Grid.Item
              md={6}
              csx={[
                displayFlexEndColumnStyles,
                theme => ({
                  paddingBlock: '20px',
                  borderBottom: `1px solid ${theme.colors.lightGrey}`,
                  [MQ_MIN_MEDIUM]: {
                    borderRight: `1px solid ${theme.colors.lightGrey}`,
                    paddingRight: '20px',
                    borderBottom: '0',
                  },
                }),
              ]}>
              <DetailsSubtitle>
                {t('menuScreen.itemDetails.basic.advancedOptions')}
              </DetailsSubtitle>
              <Grid columnGap={15} rowGap={15}>
                <Grid.Item sm={12} xl={6}>
                  <MoneyInput
                    label={t('menuScreen.itemDetails.basic.calories')}
                    placeholder={t('menuScreen.itemDetails.basic.calories')}
                    value={
                      itemData?.calories === MassUpdateValues.NO_CHANGES
                        ? null
                        : isNil(itemData?.calories)
                        ? null
                        : Number(itemData?.calories)
                    }
                    onValueChange={calories =>
                      setValue({ calories }, sectionId, menuTypeVersionId)
                    }
                    prefix=""
                    precision={0}
                  />
                </Grid.Item>
                <Grid.Item sm={12} xl={6}>
                  <MoneyInput
                    label={t('menuScreen.itemDetails.basic.prepTime')}
                    placeholder={t('menuScreen.itemDetails.basic.prepTime')}
                    value={
                      itemData?.prepTime === MassUpdateValues.NO_CHANGES
                        ? null
                        : isNil(itemData?.prepTime)
                        ? null
                        : Number(itemData?.prepTime)
                    }
                    onValueChange={prepTime =>
                      setValue({ prepTime }, sectionId, menuTypeVersionId)
                    }
                    prefix=""
                    precision={0}
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item
              md={6}
              csx={[
                {
                  paddingBlock: '20px',
                  justifyContent: 'space-between',
                },
              ]}>
              <DetailsSubtitle>
                {t('menuScreen.itemDetails.basic.restrictions')}
              </DetailsSubtitle>
              <Grid
                rowGap={10}
                csx={{
                  alignItems: 'flex-end',
                }}>
                <Grid.Item md={12} xl={6} csx={{ marginBottom: '10px' }}>
                  <Checkbox
                    label={t('menuScreen.itemDetails.basic.restrictionsLabel')}
                    checked={
                      itemData ? Boolean(itemData?.ageRestricted) : false
                    }
                    onChange={ageRestricted =>
                      setValue({ ageRestricted }, sectionId, menuTypeVersionId)
                    }
                  />
                </Grid.Item>
                <Grid.Item md={12} xl={6}>
                  <MoneyInput
                    label={t('menuScreen.itemDetails.basic.ageThreshold')}
                    value={Number(itemData?.ageThreshold)}
                    onValueChange={ageThreshold => {
                      setValue({ ageThreshold }, sectionId, menuTypeVersionId);
                    }}
                    placeholder="Age Threshold"
                    editable={Boolean(itemData?.ageRestricted)}
                    disabled={!itemData?.ageRestricted}
                    prefix=""
                    suffix=" y/o"
                    precision={0}
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        )}

        <Grid rowGap={15} columnGap={15}>
          <Grid.Item mb={9} xl={11}>
            <TextAreaInput
              label={t(
                'menuScreen.itemDetails.moreInformation.inputs.description.label',
              )}
              info={t(
                'menuScreen.itemDetails.moreInformation.inputs.description.toolTip',
              )}
              maxLength={1000}
              value={itemData?.description || ''}
              onChange={e => {
                setValue(
                  {
                    description: e.currentTarget.value,
                  },
                  sectionId,
                  menuTypeVersionId,
                );
              }}
              placeholder={
                itemData && itemData.description === MassUpdateValues.NO_CHANGES
                  ? t('commonTexts.noChanges')
                  : t(
                      'menuScreen.itemDetails.moreInformation.inputs.description.placeholder',
                    )
              }
            />
          </Grid.Item>
          <Grid.Item
            mb={3}
            xl={1}
            csx={{
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}>
            <Button
              variant="primary"
              onClick={() => {
                setValue(
                  {
                    description: '',
                  },
                  sectionId,
                  menuTypeVersionId,
                );
              }}
              disabled={itemData?.description === ''}
              csx={{
                height: '90px',
              }}>
              {t('commonButtons.clear')}
            </Button>
          </Grid.Item>
        </Grid>

        <Grid rowGap={15} columnGap={15}>
          <Grid.Item mb={9} xl={11}>
            <TextAreaInput
              label={t(
                'menuScreen.itemDetails.moreInformation.inputs.onlineDescription.label',
              )}
              info={t(
                'menuScreen.itemDetails.moreInformation.inputs.onlineDescription.toolTip',
              )}
              maxLength={1000}
              value={itemData?.onlineDescription || ''}
              onChange={e => {
                setValue(
                  {
                    onlineDescription: e.currentTarget.value,
                  },
                  sectionId,
                  menuTypeVersionId,
                );
              }}
              placeholder={
                itemData &&
                itemData.onlineDescription === MassUpdateValues.NO_CHANGES
                  ? t('commonTexts.noChanges')
                  : t(
                      'menuScreen.itemDetails.moreInformation.inputs.onlineDescription.placeholder',
                    )
              }
            />
          </Grid.Item>
          <Grid.Item
            mb={3}
            xl={1}
            csx={{
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}>
            <Button
              variant="primary"
              onClick={() => {
                setValue(
                  {
                    onlineDescription: '',
                  },
                  sectionId,
                  menuTypeVersionId,
                );
              }}
              disabled={itemData?.onlineDescription === ''}
              csx={{
                height: '90px',
              }}>
              {t('commonButtons.clear')}
            </Button>
          </Grid.Item>
        </Grid>

        {!isCombo && (
          <Grid rowGap={15} columnGap={15}>
            <Grid.Item mb={9} xl={11}>
              <TextAreaInput
                label={t(
                  'menuScreen.itemDetails.moreInformation.inputs.recipe.label',
                )}
                info={t(
                  'menuScreen.itemDetails.moreInformation.inputs.recipe.toolTip',
                )}
                maxLength={1000}
                value={itemData?.recipe || ''}
                onChange={e => {
                  setValue(
                    {
                      recipe: e.currentTarget.value,
                    },
                    sectionId,
                    menuTypeVersionId,
                  );
                }}
                placeholder={
                  itemData && itemData.recipe === MassUpdateValues.NO_CHANGES
                    ? t('commonTexts.noChanges')
                    : t(
                        'menuScreen.itemDetails.moreInformation.inputs.recipe.placeholder',
                      )
                }
              />
            </Grid.Item>
            <Grid.Item
              mb={3}
              xl={1}
              csx={{
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}>
              <Button
                variant="primary"
                onClick={() => {
                  setValue(
                    {
                      recipe: '',
                    },
                    sectionId,
                    menuTypeVersionId,
                  );
                }}
                disabled={itemData?.recipe === ''}
                csx={{
                  height: '90px',
                }}>
                {t('commonButtons.clear')}
              </Button>
            </Grid.Item>
          </Grid>
        )}
      </Box>
    </AccordionSection>
  );
};

export default MoreInformationSection;
