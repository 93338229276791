import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Divider from '@app/components/common/Divider';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import PrepStationsSelectionModal from '@app/components/common/SelectionModal/PrepStationsSelectionModal';
import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import SectionLayer from '@app/components/LoggedIn/Menu/common/SectionLayer';
import { ItemPrepStationSectionKind } from '@app/hocs/types';
import { actionCreatorsApp } from '@app/state';
import {
  selectChangeMassUpdateData,
  selectItems,
} from '@app/state/menu/menuSelectors';
import { currentPrepStationsSelector } from '@app/state/selectors/settingsSelectors';
import {
  FormMode,
  IMassUpdate,
  actionCreatorsMenu,
  menuPrepStationsFactory,
} from '@westondev/tableturn-core';
import without from 'lodash/without';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import SwitchMassUpdate from '../../SwitchMassUpdate';
import { IItemDetailsSection } from '../../types';

interface IItemPrepStationSection extends IItemDetailsSection {
  kind: ItemPrepStationSectionKind;
}

const ItemPrepStationSection = ({
  t,
  menuTypeVersionId,
  sectionId,
  itemData,
  mode,
  kind,
}: IItemPrepStationSection) => {
  // Redux
  const dispatch = useDispatch();
  const { setShowToast } = bindActionCreators(actionCreatorsApp, dispatch);

  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );
  const prepStations = useSelector(currentPrepStationsSelector);
  const changeMassUpdateData = useSelector(selectChangeMassUpdateData);
  const items = useSelector(selectItems);

  // Local state
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const title =
    kind === ItemPrepStationSectionKind.ADD
      ? mode === FormMode.MASS_UPDATE
        ? t('menuScreen.itemDetails.prepStationsSection.prepStations.addTitle')
        : t('menuScreen.itemDetails.prepStationsSection.prepStations.title')
      : t(
          'menuScreen.itemDetails.prepStationsSection.prepStations.removeTitle',
        );

  const prepStationIds = itemData
    ? kind === ItemPrepStationSectionKind.ADD
      ? itemData.prepStationIds
      : (itemData as IMassUpdate).removePrepStationIds
    : [];

  const prepStationsList = useMemo(
    () =>
      menuPrepStationsFactory(prepStationIds, prepStations).map(
        prepStationButton => {
          return {
            ...prepStationButton,
            isActive: prepStationButton.status,
            showStatus: true,
            onRemoveClick: (prepStationId: number | string) => {
              const newPrepStationIds = without(
                prepStationIds,
                Number(prepStationId),
              );

              setValue(
                {
                  [kind === ItemPrepStationSectionKind.ADD
                    ? 'prepStationIds'
                    : 'removePrepStationIds']: newPrepStationIds,
                },
                sectionId,
                menuTypeVersionId,
              );
            },
          };
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepStations, prepStationIds, menuTypeVersionId],
  );

  const handleOnAssociate = (newButtons: IItemSelection[]) => {
    const newButtonIds = newButtons.map(button => Number(button.id));
    setValue(
      {
        [kind === ItemPrepStationSectionKind.ADD
          ? 'prepStationIds'
          : 'removePrepStationIds']: [...prepStationIds, ...newButtonIds],
        showPrepStationSelectionModal: false,
      },
      sectionId,
      menuTypeVersionId,
    );
    setIsSelectionModalOpen(false);
  };

  const notAvailablePrepStationIds = useMemo(() => {
    if (kind !== ItemPrepStationSectionKind.REMOVE) return [];
    const notRemoveIds = new Set<string>();
    changeMassUpdateData.bucketIds.forEach(id => {
      for (const menuType of changeMassUpdateData.selectionCriteria
        ?.menuTypeIds as number[]) {
        if (!(menuType in items[id].menuTypeVersions)) continue;
        const menuTypeVersion = items[id].menuTypeVersions[menuType];
        menuTypeVersion.prepStationIds.forEach(prepStationId => {
          notRemoveIds.add(String(prepStationId));
        });
      }
    });

    return Object.keys(prepStations || {}).filter(id => !notRemoveIds.has(id));
  }, [
    items,
    changeMassUpdateData.bucketIds,
    prepStations,
    kind,
    changeMassUpdateData.selectionCriteria,
  ]);

  const notSelectablePrepStationIds = useMemo(() => {
    if (kind !== ItemPrepStationSectionKind.REMOVE) return [];

    const removedSelected = [...notAvailablePrepStationIds];
    for (const id of (itemData as IMassUpdate).prepStationIds) {
      if (removedSelected.indexOf(String(id)) === -1)
        removedSelected.push(String(id));
    }
    for (const id of (itemData as IMassUpdate).removePrepStationIds) {
      if (removedSelected.indexOf(String(id)) === -1)
        removedSelected.push(String(id));
    }
    return removedSelected.map(id => Number(id));
  }, [notAvailablePrepStationIds, itemData, kind]);

  return (
    <>
      <PrepStationsSelectionModal
        onAssociate={handleOnAssociate}
        active={isSelectionModalOpen}
        onModalClose={() => setIsSelectionModalOpen(false)}
        prepStationIds={
          kind === ItemPrepStationSectionKind.REMOVE
            ? notSelectablePrepStationIds
            : prepStationIds
        }
        btnSuccessText={
          kind === ItemPrepStationSectionKind.REMOVE
            ? t('menuScreen.itemDetails.itemLocation.removeLocation')
            : undefined
        }
      />

      <AssignedElements
        elements={prepStationsList}
        noElementsComponent={
          prepStationsList.length ||
          kind === ItemPrepStationSectionKind.REMOVE ? undefined : kind ===
            ItemPrepStationSectionKind.ADD ? (
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                height: '100px',
              }}>
              <SectionLayer
                title={t(
                  'menuScreen.itemDetails.prepStationsSection.prepStations.emptyCardsMessage',
                )}
              />
              <Divider />
              <SwitchMassUpdate
                checked={itemData?.showPrepStationSelectionModal ?? false}
                onChange={showPrepStationSelectionModal => {
                  setValue(
                    { showPrepStationSelectionModal },
                    sectionId,
                    menuTypeVersionId,
                  );
                }}
                label={t(
                  'menuScreen.itemDetails.prepStationsSection.prepStations.showPrepStationSelectionModal',
                )}
              />
            </Box>
          ) : undefined
        }
        subCardProps={{
          title,
          actionOptions: [
            {
              text: t(
                'menuScreen.itemDetails.prepStationsSection.prepStations.actionButtons.addExistingStation',
              ),
              icon: false,
              handler: () => setIsSelectionModalOpen(true),
            },
          ],
          onEditMode: prepStationsList.length ? setEditMode : undefined,
          onEditPressDisabled: () =>
            setShowToast({
              type: 'info',
              title: t(
                'menuScreen.itemDetails.prepStationsSection.prepStations.disabledActionToast.title',
              ),
              description: t(
                'menuScreen.common.editDisabled.prepStationsItems',
              ),
            }),
        }}
        renderItem={card => (
          <Card.Item
            key={`item-id-${card.id}`}
            showRemoveIcon={isEditMode}
            {...card}
          />
        )}
      />
    </>
  );
};

export default ItemPrepStationSection;
