import { getCellSizes } from '@app/helpers/components/tableHelpers';
import { RadioSelectionStates } from '@app/types';
import { CSSObject, SerializedStyles, TCsx } from '@emotion/react';
import {
  ColumnDef,
  RowSelectionState,
  SortDirection,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import isNil from 'lodash/isNil';
import { isValidElement, useCallback, useEffect, useState } from 'react';
import i18n from '../../../i18n.config';
import Box from '../Box';
import Button from '../Button';
import { CHECKBOX_SIZE } from '../Checkbox/styles';
import Grid from '../Grid';
import Icon from '../Icon';
import RadioCircle from '../Icon/custom/RadioCircle';
import Typography from '../Typography';
import CardsTable from './CardsTable';
import TableCard from './CardsTable/TableCard';
import DefaultTable from './DefaultTable';
import SelectionTable from './SelectionTable';
import SortableTable from './SortableTable';
import TableRow from './TableRow';
import { boxAlign } from './TableRow/types';
import { headerRowStyles, headerStyles } from './styles';
import { ETableModes, IRenderItem, TTableModes } from './types';

export interface ITable<TData> {
  data: TData[];
  columns: ColumnDef<TData, any>[];
  scrollEnabled?: boolean;
  columnVisibility?: VisibilityState;
  noPadding?: boolean;
  isStriped?: boolean;
  align?: {
    [key: string]: 'left' | 'center' | 'right';
  };
  alignHeaders?: {
    [key: string]: 'left' | 'center' | 'right';
  };
  isInverted?: boolean;
  headerCsx?: TCsx;
  bodyCsx?: TCsx;
  cellCsx?: TCsx;
  headerCellCsx?: TCsx;
  bodyCellCsx?: TCsx;
  listEmptyText?: React.ReactElement;
  newRowComponent?: React.ReactElement;
  loadingComponent?: React.ReactElement | undefined;
  renderEmptyValues?: boolean;
  enableAlphabeticalSorting?: boolean;
  noDataMessage?: string;
  columnSorting?: SortingState;
  onSortPress?: (value: {
    sortDirection: SortDirection | false;
    columnId: string;
  }) => void;
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  persistentScrollbar?: boolean;
  nestedScrollEnabled?: boolean;
  noDataPlaceholder?: React.ReactNode;
  showLinesOneEmptyCell?: boolean;
  manualSorting?: boolean;
  alwaysShowSortIcon?: boolean;
  noDataComponent?: React.ReactNode;
  styleForSmallerScreens?: 'card' | 'scroll';
  mode?: TTableModes;
  onSortEnd?: (newData: TData[]) => void;
  showShadow?: boolean;
  rowIdPrefix?: string;
  title?: string;
  onAddClick?: () => void;
  addRowText?: string;
  requiredColumns?: string[];
  enableMultiRowSelection?: boolean;
  containerCsx?: TCsx;
  onRowSelectionChange?: (selectedRows: RowSelectionState) => void;
}

const Table = <T,>(props: ITable<T>) => {
  let { cellCsx: cellProps } = props;
  const {
    data,
    columns,
    columnVisibility,
    noPadding = false,
    isStriped = false,
    align = {},
    alignHeaders = {},
    isInverted = false,
    headerCsx,
    headerCellCsx,
    bodyCellCsx,
    enableAlphabeticalSorting = false,
    onSortPress,
    renderEmptyValues,
    columnSorting,
    manualSorting = true,
    alwaysShowSortIcon = false,
    styleForSmallerScreens = 'scroll',
    mode = ETableModes.BASIC,
    rowIdPrefix,
    title,
    onAddClick,
    addRowText,
    requiredColumns = [],
    enableMultiRowSelection = true,
    onRowSelectionChange,
    newRowComponent,
  } = props;

  const [sorting, setSorting] = useState<SortingState>(columnSorting || []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      size: -1,
      minSize: -1,
      maxSize: -1,
    },
    state: {
      sorting,
      rowSelection,
    },
    initialState: {
      columnVisibility: columnVisibility ? columnVisibility : {},
      sorting: columnSorting,
    },
    enableSorting: enableAlphabeticalSorting,
    manualSorting: manualSorting,
    onSortingChange: setSorting,
    enableRowSelection: mode === ETableModes.SELECTION,
    enableMultiRowSelection,
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    onRowSelectionChange && onRowSelectionChange(rowSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  cellProps = cellProps as CSSObject;
  const ItemHeight =
    cellProps && cellProps.height ? parseInt(cellProps.height as string) : 50;

  const renderRow = useCallback(
    (item: IRenderItem<T>) => {
      let bodyCellPropsCopy = bodyCellCsx;

      if (data.length === item.index + 1) {
        bodyCellPropsCopy = {
          ...(bodyCellCsx as SerializedStyles),
          borderBottomWidth: 0,
        };
      }
      const currentRow = item.row.getAllCells()[0].getContext();
      const hasMeta = item.row.getAllCells()[0].getContext().cell.column
        .columnDef.meta;
      const showRemoved = hasMeta
        ? hasMeta.getCellContext(currentRow).removed
        : false;

      if (styleForSmallerScreens === 'card')
        return (
          <TableCard
            key={item.row.id}
            cellCsx={cellProps}
            item={item}
            noPadding={noPadding}
            isStriped={isStriped}
            itemHeight={ItemHeight}
            renderEmptyValues={renderEmptyValues}
            align={align}
            columns={columns}
            bodyCellCsx={bodyCellPropsCopy}
            showRemoved={showRemoved}
          />
        );
      return (
        <TableRow
          key={item.row.id}
          cellProps={cellProps}
          item={item}
          noPadding={noPadding}
          isStriped={isStriped}
          itemHeight={ItemHeight}
          renderEmptyValues={renderEmptyValues}
          align={align}
          columns={columns}
          bodyCellCsx={bodyCellPropsCopy}
          showRemoved={showRemoved}
          rowIdPrefix={rowIdPrefix}
          isTableInverted={isInverted}
        />
      );
    },
    [
      bodyCellCsx,
      data.length,
      styleForSmallerScreens,
      cellProps,
      noPadding,
      isStriped,
      ItemHeight,
      renderEmptyValues,
      align,
      columns,
      rowIdPrefix,
      isInverted,
    ],
  );

  const renderHeaderGroups = useCallback(() => {
    return table.getHeaderGroups().map(headerGroup => (
      <Box
        key={headerGroup.id}
        csx={headerStyles(title ? true : false, isInverted)}>
        {mode === ETableModes.SORT_VERTICALLY && (
          <Box
            csx={{
              minWidth: '60px',
            }}
          />
        )}
        <Box
          csx={[
            headerRowStyles(
              mode === ETableModes.SORT_VERTICALLY
                ? 'scroll'
                : styleForSmallerScreens,
              isInverted,
            ),
            {
              paddingLeft: noPadding
                ? '0px'
                : mode === ETableModes.SORT_VERTICALLY
                ? '10px'
                : '25px',
            },
            headerCsx,
          ]}>
          {mode === 'selection' && (
            <Button
              variant="icon"
              onClick={() => table.toggleAllRowsSelected()}
              icon={
                <RadioCircle
                  size={CHECKBOX_SIZE - 5}
                  state={
                    table.getIsAllRowsSelected()
                      ? RadioSelectionStates.FULL
                      : table.getIsSomeRowsSelected()
                      ? RadioSelectionStates.SOME
                      : RadioSelectionStates.EMPTY
                  }
                  csx={{ marginLeft: '5px' }}
                />
              }
            />
          )}

          {headerGroup.headers.map(header => (
            <Box
              key={header.id}
              csx={[
                {
                  display: 'flex',
                  alignItems: 'center',
                  ...getCellSizes(header.column, isInverted),
                  cursor: enableAlphabeticalSorting ? 'pointer' : 'default',
                  userSelect: enableAlphabeticalSorting ? 'none' : 'auto',
                },
                headerCellCsx,
              ]}
              onClick={() => {
                if (header.column.getCanSort()) {
                  onSortPress &&
                    onSortPress({
                      sortDirection: header.column.getNextSortingOrder(),
                      columnId: header.column.id,
                    });
                  header.column.toggleSorting();
                }
              }}>
              <Box
                csx={{
                  display: 'flex',
                  justifyContent: boxAlign[alignHeaders[header.column.id]],
                  width: '100%',
                }}>
                {header.isPlaceholder ? null : !isNil(
                    header.getContext().header,
                  ) ? (
                  !isValidElement(header.column.columnDef.header) ? (
                    <Typography
                      fontWeight="medium"
                      csx={{
                        textAlign: alignHeaders[header.column.id],
                      }}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </Typography>
                  ) : (
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )
                  )
                ) : (
                  <Typography align="center">
                    {'No Header'.toUpperCase()}
                  </Typography>
                )}
                {requiredColumns.includes(header.column.id) && (
                  <Typography
                    color="persistentSemanticRed"
                    csx={{ marginLeft: '4px' }}>
                    *
                  </Typography>
                )}
                {header.column.getIsSorted() ? (
                  <Icon
                    name={`FaSortAlpha${
                      header.column.getIsSorted() === 'asc' ? 'Down' : 'DownAlt'
                    }`}
                    color="semanticBlue"
                    size="19px"
                    csx={{ marginLeft: '10px' }}
                  />
                ) : (
                  enableAlphabeticalSorting &&
                  alwaysShowSortIcon &&
                  header.column.getCanSort() && (
                    <Icon
                      name="FaSortAlphaDown"
                      color="lightGrey"
                      size="19px"
                      csx={{ marginLeft: '10px' }}
                    />
                  )
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    ));
  }, [
    alignHeaders,
    alwaysShowSortIcon,
    enableAlphabeticalSorting,
    headerCellCsx,
    headerCsx,
    isInverted,
    mode,
    noPadding,
    onSortPress,
    requiredColumns,
    styleForSmallerScreens,
    table,
    title,
  ]);

  return (
    <Grid rowGap={10} csx={{ justifyContent: 'center' }}>
      <Grid.Item mb={12}>
        {styleForSmallerScreens === 'card' ? (
          <CardsTable
            {...props}
            table={table}
            headerGroups={renderHeaderGroups()}
            renderRow={renderRow}
            ItemHeight={ItemHeight}
          />
        ) : mode === ETableModes.SORT_VERTICALLY ? (
          <SortableTable
            {...props}
            table={table}
            headerGroups={renderHeaderGroups()}
            renderRow={renderRow}
            ItemHeight={ItemHeight}
          />
        ) : mode === ETableModes.SELECTION ? (
          <SelectionTable
            {...props}
            table={table}
            headerGroups={renderHeaderGroups()}
            renderRow={renderRow}
            ItemHeight={ItemHeight}
          />
        ) : (
          <DefaultTable
            {...props}
            table={table}
            headerGroups={renderHeaderGroups()}
            renderRow={renderRow}
            ItemHeight={ItemHeight}
          />
        )}
      </Grid.Item>
      {newRowComponent && <Grid.Item mb={12}>{newRowComponent}</Grid.Item>}
      {onAddClick && (
        <Grid.Item mb={12}>
          <Box csx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="primary"
              onClick={() => onAddClick()}
              icon={<Icon name="MdOutlineAdd" />}>
              {addRowText || i18n.t('components.table.addNewRow')}
            </Button>
          </Box>
        </Grid.Item>
      )}
    </Grid>
  );
};

export default Table;
