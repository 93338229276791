import { webErrorCodesDispatcher } from '@app/helpers/apiCall';
import {
  EPrinterStatus,
  IPrinterUpdateWSR,
  actionCreatorsApp,
  actionCreatorsLocationSettings,
  actionCreatorsPrinterCenter,
  websocketMapType,
} from '@westondev/tableturn-core';
import i18n from '../../i18n.config';
import { getRequiredMenuActionsData } from '../menu/menuWebActions';
import { printersSelector } from '../selectors/settingsSelectors';
import { AppThunk } from '../store';
import { setShowToast, webSocketRouter } from './actions';

export const webSocketRouterWeb =
  (data: websocketMapType): AppThunk<void> =>
  dispatch => {
    const currentDeviceLicenseSettingsId = 0;

    return dispatch(webSocketRouter(data, currentDeviceLicenseSettingsId));
  };

export const handlePrinterStatusWsWeb = (
  payload: IPrinterUpdateWSR,
): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();

    const printers = printersSelector(state);

    if (payload.status !== EPrinterStatus.ACTIVE) {
      dispatch(
        setShowToast({
          type: 'error',
          title: i18n.t(
            'navBar.common.printerCenter.printer.printerErrorTitle',
          ),
          description: `${printers[payload.printerId]?.name} - ${i18n.t(
            `printerStatus.${payload.status}`,
          )}}`,
        }),
      );
    }

    if (payload.status === EPrinterStatus.ACTIVE) {
      try {
        const printerJobsIds = payload.printJobs.map(printJob => printJob.id);

        dispatch(
          actionCreatorsPrinterCenter.clearPrinterErrorsForPrintJobs(
            printerJobsIds,
          ) as unknown as AppThunk<void>,
        );
      } catch (err) {
        console.error(err);
      }
    }

    dispatch(actionCreatorsLocationSettings.setPrinterStatusWs(payload));
  };
};

export const setMasterLocationWeb =
  (onContinue?: () => void): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId } = getRequiredMenuActionsData(state);
    return dispatch(
      actionCreatorsApp.setMasterLocation(
        organizationId,
        0,
        webErrorCodesDispatcher,
        undefined,
        onContinue,
      ),
    );
  };
