import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import {
  selectChangeDataDifferences,
  selectChangeDataId,
  selectCurrentItem,
} from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import { TChange } from '@app/types';
import {
  CoreRootState,
  IDiscount,
  TDiscountType,
  diffDiscountFactory,
  formatMoney,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import EmptyField from '../../common/EmptyField';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import TextAreaChange from '../../common/TextAreaChange';

const DiscountsDiffModal = ({ t }: WithTranslation) => {
  const differences = useSelector(selectChangeDataDifferences);

  const changeData = useSelector(selectCurrentItem) as IDiscount;
  const discountId = useSelector(selectChangeDataId);

  const formattedDifferences = useMemo(() => {
    return diffDiscountFactory(
      discountId,
      differences,
      changeData,
      store.getState() as CoreRootState & RootState,
    );
  }, [discountId, differences, changeData]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (textValue) {
      return textValue;
    }

    if (field === 'value') {
      return changeData.type === TDiscountType.PERCENT
        ? `${value}%`
        : formatMoney('', value as number);
    }

    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    if (!change.field) return null;
    return <RenderChange change={change} formatValue={formatValue} />;
  };

  const { masterChanges, locationChanges } = useMemo(
    () => ({
      masterChanges: formattedDifferences?.masterChanges
        ? formattedDifferences?.masterChanges.filter(
            change => change.field !== 'locationIds',
          )
        : [],
      locationChanges: formattedDifferences?.masterChanges
        ? formattedDifferences?.masterChanges.filter(
            change => change.field === 'locationIds',
          )
        : [],
    }),
    [formattedDifferences?.masterChanges],
  );

  return (
    <Box>
      {formattedDifferences && (
        <>
          {masterChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.commonChanges')}
                color="black"
              />
              <Grid columnGap={20} rowGap={10}>
                {masterChanges
                  .filter(change => change.field !== 'locationIds')
                  .map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`master_change_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
              </Grid>
            </>
          )}

          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.moreInfo.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.moreInformation')}
              />
              <Box>
                {formattedDifferences.moreInfo.map(change =>
                  change.value ? (
                    <TextAreaChange
                      key={`change-${change.field}`}
                      change={change}
                    />
                  ) : (
                    <EmptyField
                      key={`change-${change.field}`}
                      label={change.label}
                    />
                  ),
                )}
              </Box>
            </>
          )}
          {formattedDifferences.discountScheme.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.discountScheme')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.discountScheme.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {locationChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.associations')}
              />
              {locationChanges.map(renderChange)}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default DiscountsDiffModal;
