import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import { formatValue } from '@app/helpers/modals/diffModal';
import {
  selectChangeDataDifferences,
  selectChangeDataId,
} from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import { TChange } from '@app/types';
import {
  CoreRootState,
  diffCategoriesFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import EmptyField from '../../common/EmptyField';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import TextAreaChange from '../../common/TextAreaChange';

const CategoriesDiffModal = ({ t }: WithTranslation) => {
  const differences = useSelector(selectChangeDataDifferences);
  const categoryId = useSelector(selectChangeDataId);

  const formattedDifferences = useMemo(() => {
    return diffCategoriesFactory(
      categoryId,
      differences,
      store.getState() as CoreRootState & RootState,
    );
  }, [categoryId, differences]);

  const renderChange = (change: TChange) => {
    if (!change.field) return null;
    return <RenderChange change={change} formatValue={formatValue} />;
  };

  return (
    <Box>
      {formattedDifferences && (
        <>
          {formattedDifferences.masterChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.commonChanges')}
                color="black"
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.masterChanges
                  .filter(change => change.field !== 'locationIds')
                  .map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`master_change_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
              </Grid>
            </>
          )}
          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.moreInfo.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.moreInformation')}
              />
              <Box>
                {formattedDifferences.moreInfo.map(change =>
                  change.value ? (
                    <TextAreaChange
                      key={`change-${change.field}`}
                      change={change}
                    />
                  ) : (
                    <EmptyField
                      key={`change-${change.field}`}
                      label={change.label}
                    />
                  ),
                )}
              </Box>
            </>
          )}
          {(formattedDifferences.associations.items.length > 0 ||
            formattedDifferences.associations.subcategories.length > 0 ||
            formattedDifferences.associations.combos.length > 0) && (
            <HeaderDiffModal
              name={t('app.modals.diffModal.sectionTitles.associations')}
            />
          )}
          {formattedDifferences.associations.subcategories.length > 0 && (
            <>
              {formattedDifferences.associations.subcategories
                .filter(
                  change =>
                    change.field === 'subcategoryItemsIds' ||
                    change.field === 'subcategoryCombosIds',
                )
                .map(renderChange)}
            </>
          )}
          {formattedDifferences.associations.items.length > 0 && (
            <>
              {formattedDifferences.associations.items
                .filter(change => change.field === 'itemIds')
                .map(renderChange)}
            </>
          )}
          {formattedDifferences.associations.combos.length > 0 && (
            <>
              {formattedDifferences.associations.combos
                .filter(change => change.field === 'comboIds')
                .map(renderChange)}
            </>
          )}

          {formattedDifferences.taxes.length > 0 && (
            <>
              <RenderChange
                change={{
                  field: 'taxIds',
                  label: t('menuScreen.categoryDetails.taxesSection.title'),
                  value: formattedDifferences.taxes,
                }}
                formatValue={formatValue}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CategoriesDiffModal;
