import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TCsx } from '@emotion/react';
import { Row, Table } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import Box from '../../Box';
import Typography from '../../Typography';
import {
  tableBodyStyles,
  tableContainerStyles,
  tableStyles,
  tableTitleStyles,
} from '../styles';
import { ITable } from '../Table';
import { IRenderItem } from '../types';
import { sortableRowStyles } from './styles';

interface ISortableTable<T> extends ITable<T> {
  table: Table<T>;
  headerGroups: React.ReactNode;
  renderRow: (item: IRenderItem<T>) => React.ReactNode;
  ItemHeight: number;
  titleContainerCsx?: TCsx;
  titleTextCsx?: TCsx;
}
const SortableTable = <T,>({
  table,
  headerGroups,
  renderRow,
  noDataComponent,
  noDataPlaceholder,
  bodyCsx: bodyProps,
  onSortEnd,
  showShadow = true,
  title,
  isInverted = false,
  noDataMessage,
  titleContainerCsx,
  titleTextCsx,
}: ISortableTable<T>) => {
  const [sortedItems, setSortedItems] = useState<Row<T>[]>(
    table.getRowModel().rows,
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const newOrderRows = [...sortedItems];
      const oldIndex = newOrderRows.findIndex(
        item => item.index + 1 === active.id,
      );
      const newIndex = newOrderRows.findIndex(
        item => item.index + 1 === over?.id,
      );
      newOrderRows.splice(newIndex, 0, newOrderRows.splice(oldIndex, 1)[0]);
      setSortedItems(newOrderRows);
      onSortEnd && onSortEnd(newOrderRows.map(row => row.original));
    }
  }

  const r = table.getRowModel().rows;

  useEffect(() => {
    setSortedItems(r);
  }, [r]);

  return (
    <>
      {title && (
        <Box csx={[tableTitleStyles, titleContainerCsx]}>
          <Typography fontWeight="medium" align="center" csx={titleTextCsx}>
            {title}
          </Typography>
        </Box>
      )}
      <Box csx={tableContainerStyles(showShadow, Boolean(title))}>
        <Box csx={tableStyles(isInverted)}>
          {headerGroups}
          <Box csx={[bodyProps, tableBodyStyles, { overflowX: 'hidden' }]}>
            {sortedItems.length > 0 ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                onDragEnd={handleDragEnd}>
                <SortableContext
                  items={sortedItems.map(item => item.index + 1)}
                  strategy={verticalListSortingStrategy}>
                  {sortedItems.map(row => (
                    <SortableRow
                      key={`sortable-row${row.index}`}
                      row={row}
                      renderItem={renderRow}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            ) : noDataComponent ? (
              noDataComponent
            ) : noDataPlaceholder ? (
              noDataPlaceholder
            ) : (
              <Box
                csx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBlock: '20px',
                }}>
                <Typography align="center">
                  {noDataMessage ?? 'No Data'}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

interface ISortableRow<T> {
  row: Row<T>;
  renderItem: (item: IRenderItem<T>) => React.ReactNode;
}

const SortableRow = <T,>({ row, renderItem }: ISortableRow<T>) => {
  const hasMeta = row.getAllCells()[0].getContext().cell.column.columnDef.meta;

  const currentRow = row.getAllCells()[0].getContext();
  const isLocked = hasMeta ? hasMeta.getCellContext(currentRow).locked : false;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
    transition,
  } = useSortable({ id: row.index + 1 });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return isLocked ? (
    <div css={sortableRowStyles}>
      {renderItem({
        index: row.index,
        row,
        dragListeners: listeners,
        isSortLocked: true,
      })}
    </div>
  ) : (
    <div
      ref={setNodeRef}
      css={sortableRowStyles}
      data-dragging={isDragging ? true : false}
      style={style}
      {...attributes}>
      {renderItem({
        index: row.index,
        row,
        dragListeners: listeners,
        isDragging,
      })}
    </div>
  );
};

export default SortableTable;
