import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import SortButton from '@app/components/common/SortButton';
import Typography from '@app/components/common/Typography';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { SortOptions } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { TSingleSelectDropdown } from '../Dropdown/SingleSelectDropdown/types';

type IFiltersDropdownBase = WithTranslation & {
  onSortChange: (value: SortOptions | null) => void;
  sortValue: SortOptions | null;
  onDone?: () => void;
};

type IFiltersDropDownData = {
  label: TSingleSelectDropdown['label'];
  onChange: (value: number) => void;
  data: TSingleSelectDropdown['data'];
  value: TSingleSelectDropdown['value'];
  defaultValue?: number;
};

type IFiltersDropdownWithData = IFiltersDropdownBase & {
  data: IFiltersDropDownData[];
};

type IFiltersDropdownWithoutData = IFiltersDropdownBase & {
  data?: IFiltersDropDownData[];
};

type IFiltersDropdown = IFiltersDropdownWithData | IFiltersDropdownWithoutData;

function FiltersDropdown(props: IFiltersDropdownWithData): React.ReactElement;
function FiltersDropdown(
  props: IFiltersDropdownWithoutData,
): React.ReactElement;
function FiltersDropdown({
  t,
  data,
  sortValue,
  onSortChange,
  onDone = () => null,
}: IFiltersDropdown) {
  const handleOnClear = () => {
    if (data) {
      data.forEach(
        dropdown =>
          dropdown.onChange && dropdown.onChange(dropdown.defaultValue || 0),
      );
    }
    onSortChange(null);
  };

  return (
    <Box csx={{ width: '240px', padding: '5px' }}>
      <Grid rowGap={15}>
        <Grid.Item>
          <Typography
            variant="subtitle"
            fontWeight="medium"
            csx={{ marginBlock: '5px' }}>
            {t('components.filtersDropdown.title')}
          </Typography>
          <Divider />
        </Grid.Item>
        <Grid.Item>
          <Typography csx={{ marginBottom: '5px' }} color="darkGrey">
            {t('components.filtersDropdown.sortBy')}
          </Typography>
          <Box csx={[displayFlexRowStyles, { width: '100%', gap: '10px' }]}>
            <SortButton
              mode="active-inactive"
              initialSortDirection={SortOptions.asc}
              onSort={newSort => onSortChange(newSort)}
              value={sortValue === SortOptions.asc ? sortValue : null}
            />
            <SortButton
              mode="active-inactive"
              initialSortDirection={SortOptions.desc}
              onSort={newSort => onSortChange(newSort)}
              value={sortValue === SortOptions.desc ? sortValue : null}
            />
          </Box>
        </Grid.Item>
        {data &&
          data.map((dropdown, index) => (
            <Grid.Item key={`dropdown-${index}`}>
              <Typography csx={{ marginBottom: '5px' }} color="darkGrey">
                {dropdown.label}
              </Typography>
              <Dropdown
                value={dropdown.value}
                data={dropdown.data}
                onChange={newDropdownValue =>
                  dropdown.onChange(newDropdownValue)
                }
              />
            </Grid.Item>
          ))}
        <Grid.Item mb={12} csx={[displayFlexRowStyles, { gap: '10px' }]}>
          <Button variant="secondary" onClick={handleOnClear}>
            {t('commonButtons.clearAll')}
          </Button>
          <Button variant="primary" onClick={onDone}>
            {t('commonButtons.done')}
          </Button>
        </Grid.Item>
      </Grid>
    </Box>
  );
}

export default FiltersDropdown;
