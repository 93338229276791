import Checkbox from '@app/components/common/Checkbox';
import Grid from '@app/components/common/Grid';
import {
  actionCreatorsMenu,
  IMenuTypeVersion,
  MassUpdateValues,
  SectionId,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  leftTwoCheckboxesSpacing,
  registerDisplayNavigationCheckbosStyles,
  rightTwoCheckboxesStyles,
} from '../RegisterDisplayNavigationSubsection/styles';

const sectionId = SectionId.REGISTER;

interface IRegisterDisplayDisplayCheckboxesSubsection extends WithTranslation {
  menuTypeVersionId: number;
  displayItemPriceOnRegister: boolean | undefined | MassUpdateValues;
  displayPictureOnCard: boolean | undefined | MassUpdateValues;
  transparentBackground: boolean | undefined | MassUpdateValues;
  hideItemName: boolean | undefined | MassUpdateValues;
  isCombo?: boolean;
}

const RegisterDisplayDisplayCheckboxesSubsection = ({
  t,
  isCombo,
  menuTypeVersionId,
  displayItemPriceOnRegister,
  displayPictureOnCard,
  transparentBackground,
  hideItemName,
}: IRegisterDisplayDisplayCheckboxesSubsection) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  return (
    <Grid
      columns={{
        mb: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 15,
      }}>
      <Grid.Item xl={6} csx={leftTwoCheckboxesSpacing}>
        <Grid rowGap={15} columnGap={15}>
          <Grid.Item md={4} xl={6}>
            <Checkbox
              label={t(
                `menuScreen.${
                  isCombo ? 'comboDetails' : 'itemDetails'
                }.registerDisplay.registerDisplayOptions.displayPictureOnCard`,
              )}
              info={t(
                'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.displayPictureOnCardtoolTip',
              )}
              checked={
                displayPictureOnCard === MassUpdateValues.NO_CHANGES
                  ? false
                  : displayPictureOnCard
              }
              onChange={_displayPictureOnCard =>
                setValue(
                  {
                    displayPictureOnCard: _displayPictureOnCard,

                    ...(!_displayPictureOnCard
                      ? { transparentBackground: false }
                      : {}),
                  },
                  sectionId,
                  menuTypeVersionId,
                )
              }
            />
          </Grid.Item>
          <Grid.Item md={4} xl={6}>
            <Checkbox
              label={t(
                'menuScreen.itemDetails.registerDisplay.transparentBackground',
              )}
              info={t(
                'menuScreen.itemDetails.registerDisplay.transparentBackgroundtoolTip',
              )}
              checked={
                transparentBackground === MassUpdateValues.NO_CHANGES
                  ? false
                  : transparentBackground
              }
              onChange={_transparentBackground =>
                setValue(
                  { transparentBackground: _transparentBackground },
                  sectionId,
                  menuTypeVersionId,
                )
              }
              isDisabled={!displayPictureOnCard}
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>

      <Grid.Item xl={9} csx={rightTwoCheckboxesStyles}>
        <Grid rowGap={15} columnGap={15}>
          <Grid.Item md={4} csx={[registerDisplayNavigationCheckbosStyles]}>
            <Checkbox
              label={t(
                `menuScreen.${
                  isCombo ? 'comboDetails' : 'itemDetails'
                }.registerDisplay.${
                  isCombo
                    ? 'displayComboPriceOnRegister'
                    : 'displayItemPriceOnRegister'
                }` as ParseKeys,
              )}
              info={t('menuScreen.itemDetails.registerDisplay.displayItemPriceOnRegistertoolTip')}
              checked={
                displayItemPriceOnRegister === MassUpdateValues.NO_CHANGES
                  ? false
                  : displayItemPriceOnRegister
              }
              onChange={_displayItemPriceOnRegister =>
                setValue(
                  isCombo
                    ? ({
                        displayComboPriceOnRegister:
                          _displayItemPriceOnRegister,
                      } as unknown as IMenuTypeVersion)
                    : {
                        displayItemPriceOnRegister: _displayItemPriceOnRegister,
                      },
                  sectionId,
                  menuTypeVersionId,
                )
              }
            />
          </Grid.Item>
          <Grid.Item md={4} csx={[registerDisplayNavigationCheckbosStyles]}>
            <Checkbox
              label={t(
                `menuScreen.${
                  isCombo ? 'comboDetails' : 'itemDetails'
                }.registerDisplay.${
                  isCombo ? 'hideComboName' : 'hideItemName'
                }` as ParseKeys,
              )}
              info={t(
                'menuScreen.itemDetails.registerDisplay.hideItemNametoolTip',
              )}
              checked={
                hideItemName === MassUpdateValues.NO_CHANGES
                  ? false
                  : hideItemName
              }
              onChange={_hideItemName =>
                setValue(
                  isCombo
                    ? ({
                        hideComboName: _hideItemName,
                      } as unknown as IMenuTypeVersion)
                    : { hideItemName: _hideItemName },
                  sectionId,
                  menuTypeVersionId,
                )
              }
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>
    </Grid>
  );
};

export default RegisterDisplayDisplayCheckboxesSubsection;
