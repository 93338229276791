import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import {
  actionCreatorsMenu,
  IMenuTypeVersion,
} from '@westondev/tableturn-core';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import { IItemDetailsSection } from '../types';
import CheckboxMassUpdate from '../SwitchMassUpdate/CheckboxMassUpdate';

const GuestCheckDisplaySection = ({
  t,
  menuTypeVersionId,
  itemData,
  sectionId,
  isDisabled,
  ...props
}: IItemDetailsSection) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  return (
    <AccordionSection
      disabledLayer={isDisabled && <NoMenuTypeLayer />}
      title={t('menuScreen.itemDetails.guestCheckDisplay.title')}
      {...props}>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          gap: '50px',
        }}>
        <CheckboxMassUpdate
          label={t('menuScreen.itemDetails.guestCheckDisplay.showModifiers')}
          checked={itemData ? itemData?.showModifiers : false}
          onChange={showModifiers => {
            const values: Partial<IMenuTypeVersion> = { showModifiers };
            if (!showModifiers) {
              values.showModItemPrice = false;
            }
            setValue(values, sectionId, menuTypeVersionId);
          }}
        />
        <CheckboxMassUpdate
          label={t('menuScreen.itemDetails.guestCheckDisplay.showModItemPrice')}
          checked={itemData ? itemData?.showModItemPrice : false}
          isDisabled={!itemData?.showModifiers}
          onChange={showModItemPrice =>
            setValue({ showModItemPrice }, sectionId, menuTypeVersionId)
          }
        />
      </Box>
    </AccordionSection>
  );
};

export default GuestCheckDisplaySection;
