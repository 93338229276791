import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import Modal from '@app/components/common/Modal';
import Spinner from '@app/components/common/Spinner';
import Stepper from '@app/components/common/Stepper';
import { IStep } from '@app/components/common/Stepper/types';
import StepperForm from '@app/components/common/StepperForm';
import Typography from '@app/components/common/Typography';
import { actionCreatorsApp } from '@app/state';
import { displayFlexRowCenterStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RoleButton from './RoleButton';
import {
  LogoContainerStyles,
  TagStyles,
  itemsContainerStyles,
  pullingOrDataStyles,
  successMessageStyles,
} from './styles';
import { ILocationAndRoleSelectorModal, IOrgData } from './types';
import TTLogo from '@app/components/common/TTLogo.tsx';

const LocationAndRoleSelectorModal = ({
  t,
  isActive,
  onModalClose,
  organizationData,
}: ILocationAndRoleSelectorModal) => {
  const { getOrganizationLocationData: getOrganizationData } =
    bindActionCreators(actionCreatorsApp, useDispatch());

  const ORG_STEPS = {
    [0]: {
      name: t('loggedIn.myOrganizations.rolesSelectorModal.selectLocation'),
      active: true,
      completed: false,
    },
    [1]: {
      name: t('loggedIn.myOrganizations.rolesSelectorModal.selectRole'),
      active: false,
      completed: false,
    },
  };

  const navigate = useNavigate();
  const [steps, setSteps] = useState<IStep>(ORG_STEPS);
  const [data, setData] = useState<IOrgData>({
    location: {
      id: null,
      name: '',
    },
    role: {
      id: null,
      name: '',
    },
  });
  const [locationIndex, setLocationIndex] = useState<number | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hasRoleRef = useRef<boolean>(false);

  const activeStep = useMemo(() => {
    return Object.values(steps).findIndex(step => step.active);
  }, [steps]);

  const locations = useMemo(() => {
    return organizationData ? organizationData.locations : [];
  }, [organizationData]);

  const currentLocation = useMemo(() => {
    return locations?.[locationIndex as number];
  }, [locationIndex, locations]);

  const handleGoToRoles = useCallback(
    (_locationIndex: number, locationId: number, name: string) => {
      setData(prev => ({
        ...prev,
        location: {
          id: locationId,
          name,
        },
      }));
      setLocationIndex(_locationIndex);
      setSteps(_steps => ({
        [0]: {
          ..._steps[0],
          active: false,
          completed: true,
        },
        [1]: {
          ..._steps[1],
          active: true,
        },
      }));
    },
    [],
  );

  const handleModalClose = () => {
    onModalClose();
    setSteps(ORG_STEPS);
  };

  const handleNavigateToHome = useCallback(
    (orgId: number, locationId: number) => {
      if (!hasRoleRef.current) return;
      navigate(`/${orgId}/${locationId}/home`, { replace: true });
    },
    [navigate],
  );

  const handleLoadOrgData = useCallback(
    async (roleId: number, locationId: number, roleName: string) => {
      hasRoleRef.current = true;
      setData(prev => ({
        ...prev,
        role: {
          id: roleId,
          name: roleName,
        },
      }));
      const organizationId = organizationData?.id as number;
      await getOrganizationData({
        organizationId,
        locationId,
        roleId,
      });

      handleNavigateToHome(organizationId, locationId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationData?.id],
  );

  const handleChangeStep = (index?: number) => {
    if (index !== undefined) {
      setSteps(prev => ({
        ...prev,
        [index]: { ...prev[index], active: true },
        [activeStep]: { ...prev[activeStep], active: false },
      }));
    }
  };

  const handleCancelDownload = () => {
    hasRoleRef.current = false;
    timeoutRef.current && clearTimeout(timeoutRef.current);
    setData(prev => ({
      ...prev,
      role: {
        id: null,
        name: '',
      },
    }));
  };

  useEffect(() => {
    if (locations?.length !== 1) return;
    const location = locations[0];
    handleGoToRoles(0, location.id, location.name);
  }, [locations, handleGoToRoles]);

  useEffect(() => {
    if (locations?.length !== 1 || currentLocation?.roles.length !== 1) return;

    const role = currentLocation.roles[0];
    const location = locations[0];
    handleLoadOrgData(role.id, location.id, role.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, locations?.length]);

  return (
    <Modal
      size={600}
      isActive={isActive}
      onModalClose={handleModalClose}
      showXCloser={data.role.id !== null ? false : true}
      dismissOnOutsideClick={data.role.id !== null ? false : true}>
      {organizationData && (
        <>
          <Box csx={LogoContainerStyles}>
            {organizationData.digitalLogoUrl ? (
              <img
                src={organizationData.digitalLogoUrl}
                style={{
                  borderRadius: 0,
                  width: '60%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <TTLogo svgProps={{ width: '40%', height: 60 }} />
            )}

            <Typography
              variant="heading"
              fontWeight="bold"
              csx={{ marginTop: '15px' }}>
              {organizationData.name}
            </Typography>
          </Box>
          {data.role.id === null && (
            <>
              <Stepper steps={steps} getStepIndex={handleChangeStep} />
              <Divider
                label={
                  activeStep === 1 && data.location.id !== null
                    ? data.location.name
                    : ''
                }
                csx={{ marginBlock: '10px' }}
              />
              <StepperForm csx={{ marginTop: '15px' }} activeStep={activeStep}>
                <Grid rowGap={10} columnGap={10} csx={itemsContainerStyles}>
                  {locations.length > 0 ? (
                    locations.map((location, index) => (
                      <Grid.Item mb={6} sm={4} key={`location_${location.id}`}>
                        <RoleButton
                          name={location.name}
                          subtitle={location.streetLine1}
                          onClick={() =>
                            handleGoToRoles(index, location.id, location.name)
                          }
                        />
                      </Grid.Item>
                    ))
                  ) : (
                    <Grid.Item mb={12} csx={displayFlexRowCenterStyles}>
                      <Typography variant="body" fontWeight="medium">
                        {t(
                          'loggedIn.myOrganizations.rolesSelectorModal.noLocations',
                        )}
                      </Typography>
                    </Grid.Item>
                  )}
                </Grid>
                <Grid rowGap={10} columnGap={10} csx={itemsContainerStyles}>
                  {currentLocation ? (
                    currentLocation.roles.map(role => (
                      <Grid.Item mb={6} sm={4} key={`role_${role.id}`}>
                        <RoleButton
                          name={role.name}
                          onClick={() =>
                            handleLoadOrgData(
                              role.id,
                              data.location.id || 0,
                              role.name,
                            )
                          }
                        />
                      </Grid.Item>
                    ))
                  ) : (
                    <Grid.Item mb={12} csx={displayFlexRowCenterStyles}>
                      <Typography variant="body" fontWeight="medium">
                        {t(
                          'loggedIn.myOrganizations.rolesSelectorModal.noRoles',
                        )}
                      </Typography>
                    </Grid.Item>
                  )}
                </Grid>
              </StepperForm>
            </>
          )}

          {data.role.id !== null && (
            <Grid rowGap={10} columnGap={10}>
              <Grid.Item mb={12} csx={successMessageStyles}>
                <Tag
                  text={data.location.name}
                  icon={<Icon name="MdLocationPin" />}
                />
                <Tag text={data.role.name} icon={<Icon name="MdPerson" />} />
                <Box csx={pullingOrDataStyles}>
                  <Spinner size="24px" color="darkBlue" />
                  <Typography color="darkGrey" fontWeight="medium">
                    {t(
                      'loggedIn.myOrganizations.rolesSelectorModal.pullingOrganizationData',
                    )}
                    ...
                  </Typography>
                </Box>
                <Box csx={displayFlexRowCenterStyles}>
                  <Button
                    variant="transparent"
                    csx={{ color: 'red', width: '120px', height: '30px' }}
                    onClick={handleCancelDownload}>
                    {t('commonButtons.cancel')}
                  </Button>
                </Box>
              </Grid.Item>
            </Grid>
          )}
        </>
      )}
    </Modal>
  );
};

const Tag = ({ text, icon }: { text: string; icon?: React.ReactNode }) => {
  return (
    <Box csx={TagStyles}>
      {icon && icon}
      <Typography>{text}</Typography>
    </Box>
  );
};

export default LocationAndRoleSelectorModal;
