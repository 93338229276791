import { actionCreatorsMenuWeb } from '@app/state';
import { BreadCrumbAction } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import useRefreshMenuWeb from './useRefreshMenuWeb';
import useNavigateWithOrg from './useNavigateWithOrg';

const useNavigateNewElement = (
  url: string,
  id: number,
  newId: number,
  name: string,
) => {
  const navigate = useNavigateWithOrg();

  const onRefresh = useRefreshMenuWeb();

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  useEffect(() => {
    if (!id && newId && url && name) {
      checkForChangesAndNavigateWeb(
        () =>
          addBreadcrumbLocationWeb({
            action: BreadCrumbAction.REPLACE,
            text: name,
            onPress: () => navigate(url),
            pathURL: url,
          }),
        onRefresh,
      );
    }
  }, [
    newId,
    id,
    url,
    name,
    addBreadcrumbLocationWeb,
    checkForChangesAndNavigateWeb,
    navigate,
    onRefresh,
  ]);

  return null;
};

export default useNavigateNewElement;
