import { scrollBarStyles } from '@app/theme/commonStyles';
import { MQ_MIN_MEDIUM, MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';
import { BUTTON_HEIGHT } from '../Button/styles';

export const tableContainerStyles =
  (showShadow: boolean, hasTitle: boolean) => (theme: Theme) =>
    css(scrollBarStyles(theme), {
      display: 'grid',
      gridTemplateColumns: 'minmax(0, 1fr)',
      width: '100%',
      height: 'auto',
      borderRadius: hasTitle ? '0px 0px 8px 8px' : '8px',
      boxShadow: showShadow ? theme.shadows[1] : 'none',
      border: showShadow ? 'none' : `1px solid ${theme.colors.lighterGrey}`,
      overflowX: 'auto',
      position: 'relative',
      backgroundColor: theme.colors.white,
    });

export const tableContainerCardsStyles =
  (showShadow: boolean, hasTitle: boolean) => (theme: Theme) =>
    css(scrollBarStyles(theme), {
      width: '100%',
      height: 'auto',
      borderRadius: hasTitle ? '0px 0px 8px 8px' : '8px',
      boxShadow: showShadow ? theme.shadows[1] : 'none',
      border: showShadow ? 'none' : `1px solid ${theme.colors.lighterGrey}`,
      overflowX: 'hidden',
      [MQ_MIN_SMALL]: {
        overflowX: 'auto',
      },
      table: {
        width: '100%',
      },
    });

export const tableTitleStyles = (theme: Theme) =>
  css({
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    paddingBlock: '10px',
    backgroundColor: theme.colors.lightestExtraGrey,
    zIndex: 1,
  });

export const tableStyles = (isInverted: boolean) => () =>
  css({
    display: isInverted ? 'flex' : 'grid',
    width: '100%',
    minWidth: 'auto',
    position: 'relative',
    gridAutoFlow: isInverted ? 'column' : 'row',
  });

export const headerStyles =
  (hasTitle: boolean, isInverted: boolean) => (theme: Theme) =>
    css({
      display: 'flex',
      width: isInverted ? 'auto' : '100%',
      height: isInverted ? 'auto' : BUTTON_HEIGHT,
      zIndex: 2,
      backgroundColor: theme.colors.white,
      boxShadow: isInverted
        ? 'none'
        : hasTitle
        ? '0px 3px 5px -3px rgba(3, 8, 25, 0.25)'
        : theme.shadows[1.1],

      border: isInverted ? 'none' : `1px solid ${theme.colors.lighterGrey}`,
      borderRight: isInverted
        ? `1px solid ${theme.colors.lighterGrey}`
        : 'none',
    });

export const tableBodyStyles = (theme: Theme) =>
  css(scrollBarStyles(theme), {
    overflowY: 'auto',
  });

export const headerRowStyles = (
  rowStyle: 'card' | 'scroll',
  isInverted: boolean,
) =>
  css({
    display: rowStyle === 'scroll' ? 'flex' : 'none',
    maxHeight: '100%',
    paddingInline: '25px',
    gap: '10px',
    flexDirection: isInverted ? 'column' : 'row',
    width: isInverted ? '170px' : '100%',
    [MQ_MIN_MEDIUM]: {
      display: 'flex',
    },
  });
