import React, { useEffect, useMemo, useRef } from 'react';
import Box from '../../Box';
import Typography from '../../Typography';
import {
  listItemActiveStyles,
  listItemClickableStyles,
  listItemStyles,
  listSubItemStyles,
} from './styles';

export interface IListItem {
  text: string;
  isActive?: boolean;
  isSubItem?: boolean;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  showLeftIcon?: boolean;
  isLink?: string;
  onClick?: (openInNewTab: boolean) => void;
  onIconRightClick?: () => void;
}

const ListItem = ({
  text,
  isActive = false,
  isSubItem = false,
  icon,
  rightIcon,
  onClick,
  onIconRightClick,
  showLeftIcon = true,
  isLink = '',
}: IListItem) => {
  const listItemRef = useRef<HTMLAnchorElement>(null);
  const mergedStyles = useMemo(() => {
    const isClickable = onClick ? listItemClickableStyles : {};
    const isItemActive = isActive
      ? isSubItem
        ? listSubItemStyles
        : listItemActiveStyles
      : {};
    return [listItemStyles, isClickable, isItemActive];
  }, [onClick, isActive, isSubItem]);

  useEffect(() => {
    if (!isLink) return;

    const closestAnchor = listItemRef?.current;
    const handleLeft = (e: any) => {
      e.preventDefault();

      if (e.button === 0) {
        handleClick(false);
      } else if (e.button === 1) {
        handleClick(true);
      }
      // console.log('leftClick');
    };
    const handleMiddleClickOtherBrowsers = (e: MouseEvent | Event) => {
      e.preventDefault();
      handleClick(true);
      // console.log('auxClick');
    };

    const handleClick = (openInNewTab: boolean) => {
      onClick && onClick(openInNewTab);
    };

    closestAnchor?.addEventListener('click', handleLeft);
    closestAnchor?.addEventListener('auxclick', handleMiddleClickOtherBrowsers);

    return () => {
      closestAnchor?.removeEventListener('click', handleLeft);
      closestAnchor?.removeEventListener(
        'auxclick',
        handleMiddleClickOtherBrowsers,
      );
    };
  }, [isLink, onClick]);

  return (
    <Box
      csx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
      }}>
      <Box
        csx={mergedStyles}
        className={isActive ? (isSubItem ? 'activeSubitem' : 'active') : ''}>
        {isLink ? (
          <a ref={listItemRef} href={isLink} className="details listItemLink">
            {showLeftIcon && <Box className="icon">{icon && icon}</Box>}
            <Typography>{text}</Typography>
          </a>
        ) : (
          <Box className="details" onClick={() => onClick && onClick(false)}>
            {showLeftIcon && <Box className="icon">{icon && icon}</Box>}
            <Typography>{text}</Typography>
          </Box>
        )}
        {rightIcon && (
          <Box className="iconRight" onClick={onIconRightClick}>
            {rightIcon}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ListItem;
