import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Card from '@app/components/common/Card';
import CardsContainer from '@app/components/common/CardsContainer';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import TaxesSelectionModal from '@app/components/common/SelectionModal/TaxesSelectionModal';
import {
  selectCategories,
  selectCurrentMenuTypes,
  selectIsMenuMasterMode,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import { selectShouldRenderLocationDropdown } from '@app/state/selectors/locationsSelectors';
import { currentTaxesSettingsSelector } from '@app/state/selectors/settingsSelectors';
import {
  IMenuTypeVersion,
  ITaxTypeData,
  ITaxesMenuObject,
  TaxType,
  actionCreatorsMenu,
  formatTax,
} from '@westondev/tableturn-core';
import without from 'lodash/without';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssignedElements from '../../../AssignedElements';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import SectionLayer from '../../../common/SectionLayer';
import { IItemDetailsSection } from '../types';
import Box from '@app/components/common/Box';

const TaxesSection = ({
  t,
  menuTypeVersionId,
  itemData,
  sectionId,
  isCombo,
  ...props
}: IItemDetailsSection) => {
  // Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const categories = useSelector(selectCategories);
  const subcategories = useSelector(selectSubcategories);
  const menuTypes = useSelector(selectCurrentMenuTypes);
  const settingsTaxes = useSelector(currentTaxesSettingsSelector);
  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const hasMultipleLocations = useSelector(selectShouldRenderLocationDropdown);

  // Local state
  const [isTaxesModalOpen, setIsTaxesModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const generateDescription = useCallback(
    (taxType: TaxType, typeData: ITaxTypeData | null) => {
      const description = formatTax(taxType, typeData);

      return description === 'N/A'
        ? t('settingsModule.taxesSettings.taxesTable.taxTypes.table')
        : description;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const generateCustomCards = useCallback(
    (taxes: number[]) => {
      const cards = taxes.map(id => ({
        id: id,
        title: settingsTaxes[id].name,
        subTitle: generateDescription(
          settingsTaxes[id].taxType,
          settingsTaxes[id].typeData,
        ),
        isActive: true,
        onRemoveClick: () => {
          const newItemMenuTypeTaxes = without(
            (itemData as IMenuTypeVersion)?.taxIds,
            id,
          );

          setValue(
            {
              taxIds: newItemMenuTypeTaxes,
            },
            sectionId,
            menuTypeVersionId,
          );
        },
        onClick: () => null,
      }));

      return cards;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settingsTaxes, itemData, sectionId, menuTypeVersionId],
  );

  const generateCards = useCallback(
    (taxes: ITaxesMenuObject) => {
      const cards = Object.values(taxes).map(({ id }) => ({
        id: id,
        title: settingsTaxes[id].name,
        isActive: taxes[id].isActive,
        subTitle: generateDescription(
          settingsTaxes[id].taxType,
          settingsTaxes[id].typeData,
        ),
        onRemoveClick: () => null,
        onClick: () => null,
      }));

      return cards;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settingsTaxes],
  );

  const handleOnAssociate = (newButtons: IItemSelection[]) => {
    const newButtonIds = newButtons.map(button => Number(button.id));

    const newTaxes = [
      ...((itemData as IMenuTypeVersion).taxIds || []),
      ...newButtonIds,
    ];

    setValue({ taxIds: newTaxes }, sectionId, menuTypeVersionId);
    setIsTaxesModalOpen(false);
  };

  const menuTypeTaxCards = useMemo(() => {
    const taxes = (itemData as IMenuTypeVersion)?.inheritedTaxes
      ?.menuTypeInheritedTaxes
      ? (itemData as IMenuTypeVersion)?.inheritedTaxes.menuTypeInheritedTaxes
      : {};

    const cards = generateCards(taxes);

    return cards;
  }, [itemData, generateCards]);

  const taxIds = useMemo(() => {
    return (itemData as IMenuTypeVersion)?.taxIds || [];
  }, [itemData]);

  const itemMenuTypeTaxCards = useMemo(() => {
    const cards = generateCustomCards(taxIds);

    return cards;
  }, [generateCustomCards, taxIds]);

  const { itemCategoryIds, itemSubcategoryIds } = useMemo(
    () =>
      Object.values(
        (itemData as IMenuTypeVersion)?.categoriesSubcategories || {},
      ).reduce(
        (acc, { categoryId, subcategoryId }) => {
          const returnAcc = { ...acc };

          if (categoryId && categories?.[categoryId])
            returnAcc.itemCategoryIds.push(categoryId);

          if (subcategoryId && subcategories?.[subcategoryId])
            returnAcc.itemSubcategoryIds.push(subcategoryId);
          return returnAcc;
        },
        { itemCategoryIds: [], itemSubcategoryIds: [] } as {
          itemCategoryIds: number[];
          itemSubcategoryIds: number[];
        },
      ),
    [categories, itemData, subcategories],
  );

  const categoryTaxCardsByCategory = useMemo(
    () =>
      itemCategoryIds.reduce(
        (acc, categoryId) => {
          const categoryTaxes = categories?.[categoryId]?.taxIds ?? [];

          const categoryInheritedTaxes =
            (itemData as IMenuTypeVersion)?.inheritedTaxes ?? {};

          const categoryTaxSubset = categoryTaxes.reduce(
            (currentCategoryTaxSubset, taxId) => {
              if (categoryInheritedTaxes.categoryInheritedTaxes[taxId]) {
                currentCategoryTaxSubset[taxId] =
                  categoryInheritedTaxes.categoryInheritedTaxes[taxId];
              }
              return currentCategoryTaxSubset;
            },
            {} as ITaxesMenuObject,
          );

          const cards = generateCards(categoryTaxSubset);

          acc[categoryId] = cards;

          return acc;
        },
        {} as Record<
          number,
          {
            id: any;
            title: string;
            isActive: boolean;
            subTitle: string;
            onRemoveClick: () => null;
            onClick: () => null;
          }[]
        >,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories, itemCategoryIds, itemData],
  );

  const subcategoryTaxCardsBySubcategory = useMemo(
    () =>
      itemSubcategoryIds.reduce(
        (acc, subcategoryId) => {
          const subcategoryTaxes = subcategories?.[subcategoryId]?.taxIds ?? [];

          const subcategoryInheritedTaxes =
            (itemData as IMenuTypeVersion)?.inheritedTaxes ?? {};

          const subcategoryTaxSubset = subcategoryTaxes.reduce(
            (currentSubcategoryTaxSubset, taxId) => {
              if (subcategoryInheritedTaxes.subcategoryInheritedTaxes[taxId]) {
                currentSubcategoryTaxSubset[taxId] =
                  subcategoryInheritedTaxes.subcategoryInheritedTaxes[taxId];
              }
              return currentSubcategoryTaxSubset;
            },
            {} as ITaxesMenuObject,
          );

          const cards = generateCards(subcategoryTaxSubset);

          acc[subcategoryId] = cards;

          return acc;
        },
        {} as Record<
          number,
          {
            id: any;
            title: string;
            isActive: boolean;
            subTitle: string;
            onRemoveClick: () => null;
            onClick: () => null;
          }[]
        >,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subcategories, itemSubcategoryIds, itemData],
  );

  return (
    <>
      <TaxesSelectionModal
        onAssociate={handleOnAssociate}
        active={isTaxesModalOpen}
        onModalClose={() => setIsTaxesModalOpen(false)}
        taxIds={taxIds}
      />
      <AccordionSection
        title={t('menuScreen.itemDetails.taxesSection.title')}
        disabledLayer={
          isMasterMode && hasMultipleLocations ? (
            <SectionLayer title={t('menuScreen.itemDetails.noLocationMode')} />
          ) : (
            !menuTypeVersionId && <NoMenuTypeLayer />
          )
        }
        {...props}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}>
          {menuTypes[menuTypeVersionId] && (
            <Card.SubCard
              title={t('menuScreen.itemDetails.taxesSection.menuTypeTaxes', {
                menuType: menuTypes[menuTypeVersionId]?.name,
              })}>
              <CardsContainer
                data={menuTypeTaxCards}
                noElementsMessage={t(
                  'menuScreen.itemDetails.taxesSection.noTaxes',
                )}
                renderItem={(card, index) => (
                  <Card.ItemWithSwitch
                    key={`menuTypeTaxCard${index}`}
                    switchValue={
                      (itemData as IMenuTypeVersion).inheritedTaxes
                        .menuTypeInheritedTaxes[card.id].isActive
                    }
                    onSwitchChange={value => {
                      setValue(
                        {
                          inheritedTaxes: {
                            ...(itemData as IMenuTypeVersion).inheritedTaxes,
                            menuTypeInheritedTaxes: {
                              ...(itemData as IMenuTypeVersion).inheritedTaxes
                                .menuTypeInheritedTaxes,

                              [card.id]: {
                                ...(itemData as IMenuTypeVersion).inheritedTaxes
                                  .menuTypeInheritedTaxes[card.id],
                                isActive: value,
                              },
                            },
                          },
                        },
                        sectionId,
                        menuTypeVersionId,
                      );
                    }}
                    {...card}
                  />
                )}
              />
            </Card.SubCard>
          )}
          {itemCategoryIds.map(itemCategoryId => (
            <Card.SubCard
              key={`categoryTaxCardsContainer${itemCategoryId}`}
              title={t('menuScreen.itemDetails.taxesSection.categoryTaxes', {
                category: categories[itemCategoryId]?.name,
              })}>
              <CardsContainer
                data={categoryTaxCardsByCategory[itemCategoryId]}
                noElementsMessage={t(
                  'menuScreen.itemDetails.taxesSection.noTaxes',
                )}
                renderItem={(card, index) => (
                  <Card.ItemWithSwitch
                    switchValue={
                      (itemData as IMenuTypeVersion).inheritedTaxes
                        .categoryInheritedTaxes[card.id].isActive
                    }
                    onSwitchChange={value => {
                      setValue(
                        {
                          inheritedTaxes: {
                            ...(itemData as IMenuTypeVersion).inheritedTaxes,
                            categoryInheritedTaxes: {
                              ...(itemData as IMenuTypeVersion).inheritedTaxes
                                .categoryInheritedTaxes,
                              [card.id]: {
                                ...(itemData as IMenuTypeVersion).inheritedTaxes
                                  .categoryInheritedTaxes[card.id],
                                isActive: value,
                              },
                            },
                          },
                        },
                        sectionId,
                        menuTypeVersionId,
                      );
                    }}
                    key={`categoryTaxCard${index}`}
                    {...card}
                  />
                )}
              />
            </Card.SubCard>
          ))}
          {itemSubcategoryIds.map(itemSubcategoryId => (
            <Card.SubCard
              key={`subcategoryTaxCardsContainer${itemSubcategoryId}`}
              title={t('menuScreen.itemDetails.taxesSection.subcategoryTaxes', {
                subcategory: subcategories[itemSubcategoryId]?.name,
              })}>
              <CardsContainer
                data={subcategoryTaxCardsBySubcategory[itemSubcategoryId]}
                noElementsMessage={t(
                  'menuScreen.itemDetails.taxesSection.noTaxes',
                )}
                renderItem={(card, index) => (
                  <Card.ItemWithSwitch
                    key={`subcategoryTaxC${index}`}
                    switchValue={
                      (itemData as IMenuTypeVersion).inheritedTaxes
                        .subcategoryInheritedTaxes[card.id].isActive
                    }
                    onSwitchChange={value => {
                      setValue(
                        {
                          inheritedTaxes: {
                            ...(itemData as IMenuTypeVersion).inheritedTaxes,
                            subcategoryInheritedTaxes: {
                              ...(itemData as IMenuTypeVersion).inheritedTaxes
                                .subcategoryInheritedTaxes,
                              [card.id]: {
                                ...(itemData as IMenuTypeVersion).inheritedTaxes
                                  .subcategoryInheritedTaxes[card.id],
                                isActive: value,
                              },
                            },
                          },
                        },
                        sectionId,
                        menuTypeVersionId,
                      );
                    }}
                    {...card}
                  />
                )}
              />
            </Card.SubCard>
          ))}
          <AssignedElements
            elements={itemMenuTypeTaxCards}
            subCardProps={{
              title: isCombo
                ? t('menuScreen.comboDetails.taxesSection.comboTaxes')
                : t('menuScreen.itemDetails.taxesSection.itemTaxes'),
              actionOptions: [
                {
                  text: t('components.actionButtons.addExistingTax'),
                  icon: false,
                  handler: () => setIsTaxesModalOpen(true),
                },
              ],
              onEditMode: () => setIsEditMode(!isEditMode),
            }}
            noElementsMessage={t('menuScreen.itemDetails.taxesSection.noTaxes')}
            renderItem={card => (
              <Card.Item
                key={`itemMenuTypeTaxCard${card.id}`}
                showRemoveIcon={isEditMode}
                {...card}
              />
            )}
          />
        </Box>
      </AccordionSection>
    </>
  );
};

export default TaxesSection;
