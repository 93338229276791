import { BREAKPOINTS } from '@app/theme/themes';
import { onValidate, paginateButtons } from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { isNil } from 'lodash';
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import Box from '../Box';
import Button from '../Button';
import Card from '../Card';
import Icon from '../Icon';
import Modal from '../Modal';
import NoElementsFound from '../NoElementsFound';
import SearchInput from '../SearchInput';
import Typography from '../Typography';
import OptionTypeButton from './OptionTypeButton';
import { optionsModalButtonTextStyles, optionsModalStyles } from './styles';
import { IOption, IOptionTypes, TOptionModal } from './types';

const OptionsModal = ({
  t,
  title,
  options,
  currentOptionsSelected,
  specialOptions,
  specialOptionsPosition = 'bottom',
  value,
  onChange,
  placeholder,
  customText,
  dismissOnSelect = true,
  isSearchable = false,
  returnItemOnChange,
  activateItemOnSelect,
  buttonProps: { ...buttonProps } = {},
  isActiveByDefault: defaultActive = false,
  hideTriggerButton = false,
  allowMultipleSelection = false,
  groupOptionsByType,
  selectedOptionsLabel,
  noOptionsSelectedLabel,
  clearOptionsLabel,
  cancelOutsidePressToClose,
  onChangeDone,
  onCancel,
  onOutsidePress,
  validate = onValidate,
  noElementMessage,
  pageSize = 20,
  showGroupsOnSamePage,
  hideFooterButtons = false,
  disableSelectedStyle,
}: TOptionModal & { disableSelectedStyle?: boolean }) => {
  const prevValue = useRef<IOption | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: -Infinity,
  });

  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);
  const [selectedType, setSelectedType] = useState<IOptionTypes | null>(
    groupOptionsByType ? groupOptionsByType[0] : null,
  );
  const [searchBarText, setSearchBarText] = useState<string>('');
  const [filterTitle, setFilterTitle] = useState('');
  const [isMobile, setIsMobile] = useState(true);

  const handleSelectedGroupClick =
    (optionType: IOptionTypes): MouseEventHandler<HTMLDivElement> =>
    e => {
      e.currentTarget.scrollIntoView({
        behavior: 'smooth',
        inline: isMobile ? 'center' : 'nearest',
        block: isMobile ? 'nearest' : 'center',
      });
      setSelectedType(optionType);
    };

  const allOptions = useMemo(() => {
    let _allOptions = [
      ...(specialOptionsPosition === 'top' ? specialOptions || [] : []),
      ...options,
      ...(specialOptionsPosition === 'bottom' ? specialOptions || [] : []),
    ];

    if (groupOptionsByType && groupOptionsByType.length > 0)
      _allOptions = _allOptions.filter(option => {
        const currentType = selectedType
          ? selectedType.type
          : groupOptionsByType[0].type;

        return option.type ? option.type.includes(currentType) : false;
      });

    if (!filterTitle) return _allOptions;

    return _allOptions.filter(item =>
      item.label.toLowerCase().includes(filterTitle.toLowerCase()),
    );
  }, [
    filterTitle,
    options,
    specialOptions,
    specialOptionsPosition,
    selectedType,
    groupOptionsByType,
  ]);

  const selectedOptionsArray = selectedOptions.reduce(
    (acc: number[], option) => [...acc, option.value],
    [],
  );

  const optionPages = useMemo(
    () =>
      paginateButtons(
        allOptions.filter(
          option => !selectedOptionsArray.includes(option.value),
        ),
        pageSize,
      ),
    [allOptions, selectedOptionsArray, pageSize],
  );

  useEffect(() => {
    if (!isNil(value)) {
      const selectedItem = [...options, ...(specialOptions || [])].find(
        item => item.value === value,
      );
      selectedItem
        ? setSelectedOption(selectedItem)
        : setSelectedOption({
            label: '',
            value: -Infinity,
          });
    }
  }, [options, specialOptions, value]);

  useEffect(() => {
    const minInnerWidth = parseFloat(BREAKPOINTS['medium'].min);
    const handleResize = () => setIsMobile(window.innerWidth < minInnerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsModalVisible(defaultActive);
  }, [defaultActive]);

  useEffect(() => {
    if (currentOptionsSelected) setSelectedOptions(currentOptionsSelected);
  }, [currentOptionsSelected]);

  const handleModalOpen = () => {
    validate(() => {
      setIsModalVisible(true);
      prevValue.current = selectedOption;
    });
  };

  const handleModalClose = (reset = false) => {
    setIsModalVisible(false);
    setFilterTitle('');

    if (!currentOptionsSelected) setSelectedOptions([]);
    else {
      setSelectedOptions(currentOptionsSelected);
    }

    if (!reset || !onChange || !prevValue.current) return;

    setSelectedOption(prevValue.current);

    if (
      prevValue.current.value !== -Infinity &&
      prevValue.current.value !== selectedOption.value
    ) {
      if (allowMultipleSelection) return;
      if (returnItemOnChange) {
        onChange(prevValue.current);
      } else {
        onChange(prevValue.current.value);
      }
    }
  };

  const handleSelectOption = (optionItem: IOption) => {
    if (dismissOnSelect && !allowMultipleSelection) handleModalClose();

    isNil(value) && activateItemOnSelect && setSelectedOption(optionItem);

    if (allowMultipleSelection) {
      setSelectedOptions(prev => prev.concat(optionItem));
    }

    if (!onChange) return;

    if (returnItemOnChange) {
      onChange(optionItem);
    } else {
      onChange(optionItem.value);
    }
  };

  const handleCancel = () => {
    onCancel?.();
    handleModalClose();
  };

  const handleDone = () => {
    onChangeDone?.(selectedOptions);
    handleModalClose();
  };

  return (
    <>
      {!hideTriggerButton && (
        <Button
          icon={
            <Icon
              name="MdChevronRight"
              csx={{ rotate: isModalVisible ? '-90deg' : '90deg' }}
            />
          }
          iconPosition="right"
          onClick={() => handleModalOpen()}
          {...buttonProps}>
          <Typography csx={optionsModalButtonTextStyles}>
            {buttonProps.children ||
              (customText
                ? customText
                : selectedOption.label === ''
                ? placeholder
                : selectedOption.label)}
          </Typography>
        </Button>
      )}
      <Modal
        isActive={isModalVisible}
        onModalClose={() => {
          if (!cancelOutsidePressToClose) {
            onOutsidePress && onOutsidePress();
            handleModalClose();
          } else return null;
        }}
        footer={
          !hideFooterButtons ? (
            <>
              <Button
                variant="secondary"
                onClick={handleCancel}
                csx={{ width: '100px' }}>
                {t('commonButtons.cancel')}
              </Button>
              <Button
                variant="primary"
                onClick={handleDone}
                csx={{ width: '100px' }}>
                {t('commonButtons.done')}
              </Button>
            </>
          ) : undefined
        }
        height={
          (groupOptionsByType && groupOptionsByType.length > 0) ||
          allowMultipleSelection
            ? 620
            : 'auto'
        }
        size={
          (groupOptionsByType && groupOptionsByType.length > 0) ||
          allowMultipleSelection
            ? 1000
            : 700
        }
        modalContainerCsx={optionsModalStyles}
        noPadding
        title={title}>
        {groupOptionsByType &&
          groupOptionsByType.length > 0 &&
          !showGroupsOnSamePage && (
            <Box className="groupsContainer">
              <Box className="groups">
                {groupOptionsByType.map(optionType => {
                  return (
                    <OptionTypeButton
                      key={optionType.type}
                      isSelected={selectedType === optionType}
                      label={optionType.label}
                      onClick={() => handleSelectedGroupClick(optionType)}
                    />
                  );
                })}
              </Box>
              <Box className="clearOptionsContainer">
                <Button
                  csx={{ width: '130px' }}
                  key="clear-options"
                  onClick={() => setSelectedOptions([])}>
                  {clearOptionsLabel ??
                    t('app.modals.optionsModal.clearOptions')}
                </Button>
              </Box>
            </Box>
          )}

        <Box className="options">
          {isSearchable && (
            <Box className="searchBar">
              <SearchInput
                onChange={setSearchBarText}
                value={searchBarText}
                debounceTimeout={300}
                onDebounce={setFilterTitle}
              />
            </Box>
          )}

          {showGroupsOnSamePage &&
            groupOptionsByType &&
            groupOptionsByType.length > 0 && (
              <>
                {groupOptionsByType.map(({ type, label: _label }, index) => {
                  const buttons = paginateButtons(
                    options.filter(option =>
                      option.type ? option.type.includes(type) : false,
                    ),
                    pageSize,
                  );
                  return (
                    <Box className="optionsContent" key={index}>
                      <Typography>{_label}</Typography>
                      {buttons.length > 0 ? (
                        buttons.map((page, _index2) =>
                          page.map(option => {
                            return (
                              <Card.Item
                                key={`${option.label}${option.value}${_index2}`}
                                title={option.label}
                                subTitle={option.description}
                                onClick={() => handleSelectOption(option)}
                                csx={{
                                  backgroundColor:
                                    option.backgroundColor || undefined,
                                }}
                              />
                            );
                          }),
                        )
                      ) : (
                        <Box key={index}>
                          <Typography color="semanticGrey">
                            {filterTitle.length > 0
                              ? `No ${_label} found`
                              : noElementMessage ||
                                t('validations.noElements', {
                                  elementName: _label,
                                })}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </>
            )}

          {optionPages.length > 0 && !showGroupsOnSamePage ? (
            <Box className="optionsContent">
              {optionPages.map((page, index) =>
                page.map(option => {
                  return (
                    <Card.Item
                      key={`${option.label}${option.value}${index}`}
                      title={option.label}
                      subTitle={option.description}
                      onClick={() => handleSelectOption(option)}
                      csx={{
                        backgroundColor: option.backgroundColor || undefined,
                      }}
                      showStatus={option.showStatus}
                      isActive={option.isActive}
                      isSelected={
                        option.value === selectedOption.value &&
                        !disableSelectedStyle
                          ? true
                          : undefined
                      }
                    />
                  );
                }),
              )}
            </Box>
          ) : (
            !showGroupsOnSamePage && (
              <Box className="noOptions">
                <Typography color="semanticGrey" fontWeight="medium">
                  {filterTitle.length > 0
                    ? `No ${title} found`
                    : noElementMessage ||
                      t('validations.noElements', {
                        elementName: title,
                      })}
                </Typography>
              </Box>
            )
          )}
        </Box>

        {allowMultipleSelection && (
          <Box className="selectedOptions">
            <Box className="selectedOptionsHeaderContainer">
              <Typography variant="body" fontWeight="medium" align="center">
                {selectedOptionsLabel ??
                  t('app.modals.optionsModal.selectedOptions')}
              </Typography>
            </Box>
            {selectedOptions.length > 0 ? (
              <Box className="selectedOptionsContent">
                {selectedOptions.map(option => {
                  return (
                    <Card.Item
                      key={option.label + option.value}
                      title={option.label}
                      subTitle={option.description}
                      showRemoveIcon
                      showStatus={option.showStatus}
                      isActive={option.isActive}
                      onClick={() =>
                        setSelectedOptions(prev =>
                          prev.filter(
                            sOption => sOption.value !== option.value,
                          ),
                        )
                      }
                      onRemoveClick={() =>
                        setSelectedOptions(prev =>
                          prev.filter(
                            sOption => sOption.value !== option.value,
                          ),
                        )
                      }
                      csx={theme => ({
                        color: option.textColor || theme.colors.black,
                        backgroundColor: option.backgroundColor || undefined,
                      })}
                    />
                  );
                })}
              </Box>
            ) : (
              <Box className="noOptionsSelectedContainer">
                <NoElementsFound
                  vertical
                  showBorder={false}
                  text={t(
                    (noOptionsSelectedLabel as ParseKeys) ??
                      ('app.modals.optionsModal.noOptionsSelected' as ParseKeys),
                  )}
                />
              </Box>
            )}
          </Box>
        )}
      </Modal>
    </>
  );
};

export default OptionsModal;
