import Accordion from '@app/components/common/Accordion';
import Box from '@app/components/common/Box';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsSettingsWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  FormMode,
  actionCreatorsSettingsChangeData,
} from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BasicSettings from './BasicSettings';
import HomeSettings from './HomeSettings';
import ManagementSettings from './ManagementSettings';
import MenuSettings from './MenuSettings';
import RegisterSettings from './RegisterSettings';
import SettingsSection from './SettingsSection';

const RoleDetails = () => {
  // Redux
  const dispatch = useDispatch();
  const loadSettingsBucketChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadSettingsBucketChangeData,
    dispatch,
  );

  const resetSettingsChangeData = bindActionCreators(
    actionCreatorsSettingsChangeData.resetSettingsChangeData,
    dispatch,
  );

  // Local state
  const { id } = useParams();

  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'roles',
  );

  useEffect(() => {
    if (!isSet) {
      loadSettingsBucketChangeData({
        setting: 'roles',
        id: Number(id),
        mode: Number(id) ? FormMode.UPDATE : FormMode.CREATE,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, id]);

  useEffect(() => {
    return () => {
      resetSettingsChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return isSet ? (
    <Box>
      <Accordion>
        <BasicSettings isExpanded={true} />
        <HomeSettings />
        <RegisterSettings />
        <MenuSettings />
        <SettingsSection />
        <ManagementSettings />
      </Accordion>
    </Box>
  ) : null;
};

export default RoleDetails;
