import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { barStyles, statusBlockStyles } from './styles';
import { WithTranslation } from 'react-i18next';
import { TUserDetailsStatus, EUserDetailsStatus } from './types';
import Icon from '@app/components/common/Icon';
import {
  displayFlexEndColumnStyles,
  displayFlexRowStyles,
} from '@app/theme/commonStyles';
import { ParseKeys } from 'i18next';
import { useTheme } from '@emotion/react';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';

interface IStatusBlock extends WithTranslation {
  status: TUserDetailsStatus;
  isUserStatus?: boolean;
  isCreation?: boolean;
  hideResendButton?: boolean;
}
const StatusBlock = ({
  t,
  status,
  isUserStatus,
  hideResendButton,
  isCreation,
}: IStatusBlock) => {
  const theme = useTheme();
  const showResendButton =
    !hideResendButton &&
    !isCreation &&
    status === EUserDetailsStatus.UNVERIFIED;

  const STATUSES_COLORS = {
    [EUserDetailsStatus.NEW]: theme.colors.darkBlue,
    [EUserDetailsStatus.UNVERIFIED]: theme.colors.darkestYellow,
    [EUserDetailsStatus.VERIFIED]: theme.colors.semanticGreen,
  };
  const STATUSES_ICONS = {
    [EUserDetailsStatus.NEW]: (
      <Icon name="MdPeople" color={STATUSES_COLORS[EUserDetailsStatus.NEW]} />
    ),
    [EUserDetailsStatus.UNVERIFIED]: (
      <Icon
        name="FaUserClock"
        color={STATUSES_COLORS[EUserDetailsStatus.UNVERIFIED]}
      />
    ),
    [EUserDetailsStatus.VERIFIED]: (
      <Icon
        name="MdCheckCircle"
        color={STATUSES_COLORS[EUserDetailsStatus.VERIFIED]}
      />
    ),
  };

  return (
    <Grid columnGap={15} rowGap={15} csx={{ width: 'inherit' }}>
      <Grid.Item
        mb={12}
        lg={showResendButton ? 9 : 12}
        xl={showResendButton ? 9 : 12}
        csx={statusBlockStyles}>
        <Box csx={[barStyles, { backgroundColor: STATUSES_COLORS[status] }]} />
        <Box>
          <Box csx={displayFlexRowStyles}>
            {STATUSES_ICONS[status]}
            <Typography
              fontWeight="medium"
              variant="subtitle"
              csx={{ marginLeft: '8px' }}
              color={STATUSES_COLORS[status]}>
              {isUserStatus
                ? t(
                    `settingsModule.userDetailsSettings.basic.status.${status}.name` as ParseKeys,
                  )
                : t(
                    `settingsModule.userDetailsSettings.basic.status.${status}.orgName` as ParseKeys,
                  )}
            </Typography>
          </Box>
          <Typography csx={{ marginTop: '5px' }}>
            {isUserStatus
              ? t(
                  `settingsModule.userDetailsSettings.basic.status.${status}.userStatusDescription` as ParseKeys,
                )
              : t(
                  `settingsModule.userDetailsSettings.basic.status.${status}.orgStatusDescription` as ParseKeys,
                )}
          </Typography>
        </Box>
      </Grid.Item>
      {showResendButton && (
        <Grid.Item mb={12} lg={3} xl={3} csx={displayFlexEndColumnStyles}>
          <Button variant="primary" icon={<Icon name="MdOutlineEmail" />}>
            {t('settingsModule.userDetailsSettings.resendInvite')}
          </Button>
        </Grid.Item>
      )}
    </Grid>
  );
};

export default StatusBlock;
