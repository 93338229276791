import { TUserCreationData } from '@app/components/LoggedIn/Settings/Accounts/Users/UsersList/AddUserModal/types';
import { webErrorCodesDispatcher } from '@app/helpers/apiCall';
import {
  CoreRootState,
  CreateSettingsBucketRequestType,
  FormMode,
  ICustomSettingsSelected,
  ILicenses,
  ISettingsStore,
  ITablets,
  LICENSE_SETTINGS_INITIAL_STATE,
  ROLE_INITIAL_STATE,
  SettingsSectionId,
  SettingsTypes,
  USER_INITIAL_STATE,
  actionCreatorsApp,
  actionCreatorsSelectedModeSettings,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  getCurrentSettingsCore,
  settingsSelectors,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import { NavigateFunction } from 'react-router-dom';
import {
  selectLocationId,
  selectOrganizationId,
} from '../selectors/appSelectors';
import { selectShouldRenderLocationDropdown } from '../selectors/locationsSelectors';
import {
  selectLicenseSpecificPrinterRulesForDetailsScreen,
  selectPrepStationsSettingsForDetailsScreen,
  selectPrinterRulesForDetailsScreen,
} from '../selectors/settingsSelectors';
import { AppThunk, RootState } from '../store';
import { ILoadSettingsBucketChangeData } from './types';

const { setSettingsChangeData, resetSettingsChangeData } =
  actionCreatorsSettingsChangeData;
const {
  deactivateLicense,
  editSettingsElement,
  associateLicenseLocation,
  updateSettingsChangeData,
  deleteSettingsElement: deleteSettingsElementCore,
  printTestKitchenTicket,
  showElementNotFoundInLocationToast,
} = actionCreatorsSettings;
const { setIsLoadingModal, setMessageId } = actionCreatorsApp;

const {
  saveSettingsChangesOnSavePress: saveSettingsChangesOnSavePressCore,
  checkForSettingsChangesAndNavigate: checkForSettingsChangesAndNavigateCore,
  cancelSettingsChangesOnCancelPress: cancelSettingsChangesOnCancelPressCore,
} = actionCreatorsSettings;

const { setSelectedSettingsMode } = actionCreatorsSelectedModeSettings;

export const getSettingsVersionWithStateCore = (state: RootState) => {
  const settingsType = state.settings.selectedMode.settingsType;
  switch (settingsType) {
    case SettingsTypes.MASTER_SETTINGS:
      return state.settings.versions.master;
    case SettingsTypes.LOCATION_SETTINGS:
      return state.settings.versions.location;
    case SettingsTypes.REMOTE_LOCATION_SETTINGS:
      return state.settings.versions.remoteLocation;
  }
};

const SETTINGS_INITIAL_STATES = {
  roles: ROLE_INITIAL_STATE,
  licenseSettings: LICENSE_SETTINGS_INITIAL_STATE,
};

export const loadUserSettingsChangeData =
  (
    { id = 0 }: Omit<ILoadSettingsBucketChangeData, 'setting'>,
    userCreationData?: TUserCreationData,
  ): AppThunk =>
  (dispatch, getState) => {
    const mode = id ? FormMode.UPDATE : FormMode.CREATE;
    const newUserData = {
      ...USER_INITIAL_STATE,
      user: { ...USER_INITIAL_STATE.user, ...(userCreationData?.user || {}) },
    };
    const userData =
      settingsSelectors.selectCurrentSettings(
        getState() as CoreRootState & RootState,
      ).users[Number(id)] || newUserData;

    const data = cloneDeep({
      ...userData,
      locationsWithRoleIds: {},
    });

    if (userCreationData && mode === FormMode.CREATE) {
      data.user.email = userCreationData.user?.email || '';
    }
    dispatch(
      loadSettingsBucketChangeData(
        {
          setting: 'users',
          id,
          mode,
        },
        data,
      ),
    );
  };

export const loadSettingsBucketChangeData =
  (
    { setting, id = 0, mode = FormMode.UPDATE }: ILoadSettingsBucketChangeData,
    data: any = undefined,
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const allowedIdSettings: (keyof ISettingsStore)[] = [
      'roles',
      'users',
      'licenseSettings',
    ];

    const settingId = Number(id);
    let settingsObject;
    if (data) {
      settingsObject = data;
    } else if (allowedIdSettings.includes(setting)) {
      if (settingId) {
        settingsObject = settingsSelectors.selectCurrentSettings(
          state as CoreRootState & RootState,
        )[setting as 'users'][settingId];
      } else {
        settingsObject = SETTINGS_INITIAL_STATES[setting as 'roles'];
      }
    } else {
      settingsObject = settingsSelectors.selectCurrentSettings(
        state as CoreRootState & RootState,
      )[setting];
    }

    const settingsVersion = getSettingsVersionWithStateCore(state) || 0;

    if (!settingsObject && setting === 'licenseSpecificPrinterRules') {
      settingsObject = {};
    }

    dispatch(
      setSettingsChangeData({
        id: settingId,
        settingsSelected: setting,
        data: cloneDeep(settingsObject),
        originalData: cloneDeep(settingsObject),
        hasChanges: false,
        differences: [],
        finalDiffObject: null,
        errors: {},
        sectionId: SettingsSectionId.ALL,
        settingsVersion,
        selectedLocations: [],
        mode,
      }),
    );
  };

export const loadPrepStationsSettingsChangeData =
  (): AppThunk => (dispatch, getState) => {
    const state = getState();

    const prepStations = selectPrepStationsSettingsForDetailsScreen(state);

    const hasMultipleLocations = selectShouldRenderLocationDropdown(
      state as unknown as CoreRootState,
    );

    const settingsVersion =
      (hasMultipleLocations
        ? getSettingsVersionWithStateCore(state)
        : getCurrentSettingsCore(state, SettingsTypes.LOCATION_SETTINGS)
            .version) || 0;

    dispatch(
      setSettingsChangeData({
        id: 0,
        settingsSelected: 'prepStations',
        data: cloneDeep(prepStations),
        originalData: cloneDeep(prepStations),
        hasChanges: false,
        differences: [],
        finalDiffObject: null,
        errors: {},
        sectionId: SettingsSectionId.ALL,
        settingsVersion,
        selectedLocations: [],
        mode: FormMode.UPDATE,
      }),
    );
  };

export const loadPrintingRulesSettingsChangeData =
  (licenseSettingsId: number): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const licenseSpecificPrinterRules =
      selectLicenseSpecificPrinterRulesForDetailsScreen(
        state as unknown as CoreRootState,
      );
    const printerRules = selectPrinterRulesForDetailsScreen(
      state as unknown as CoreRootState,
    );

    const hasMultipleLocations = selectShouldRenderLocationDropdown(
      state as unknown as CoreRootState,
    );

    const setting = licenseSettingsId
      ? 'licenseSpecificPrinterRules'
      : 'printerRules';

    const settingsObject = licenseSettingsId
      ? licenseSpecificPrinterRules[licenseSettingsId] || {}
      : printerRules;

    const settingsVersion =
      (hasMultipleLocations
        ? getSettingsVersionWithStateCore(state)
        : getCurrentSettingsCore(state, SettingsTypes.LOCATION_SETTINGS)
            .version) || 0;

    dispatch(
      setSettingsChangeData({
        id: licenseSettingsId || 0,
        settingsSelected: setting,
        data: cloneDeep(settingsObject),
        originalData: cloneDeep(settingsObject),
        hasChanges: false,
        differences: [],
        finalDiffObject: null,
        errors: {},
        sectionId: SettingsSectionId.ALL,
        settingsVersion,
        selectedLocations: [],
        mode: FormMode.UPDATE,
      }),
    );
  };

export const createPostRequestWeb =
  (navigateCallback: NavigateFunction, isDelete = false) =>
  (
    navigate = true,
    customSettingsSelected?: ICustomSettingsSelected,
    mode?: FormMode,
  ): AppThunk<void> => {
    return dispatch => {
      if (!customSettingsSelected) {
        return;
      }

      if (navigate && (mode === FormMode.CREATE || isDelete)) {
        let clearChangeData = false;
        switch (customSettingsSelected) {
          case 'roles':
            navigateCallback('/settings/accounts/roles', {
              state: { omitBlocker: true },
            });
            clearChangeData = true;
            break;
          case 'users':
            navigateCallback('/settings/accounts/users', {
              state: { omitBlocker: true },
            });
            clearChangeData = true;
            break;
          case 'licenseSettings':
            clearChangeData = true;
            break;
          default:
            break;
        }
        if (clearChangeData) {
          dispatch(resetSettingsChangeData());
        }
      } else if (customSettingsSelected === 'taxes') {
        navigateCallback('/settings/taxes', {
          state: { omitBlocker: true },
        });
      }

      dispatch(updateSettingsChangeData() as unknown as AppThunk<void>);

      dispatch(setIsLoadingModal({ active: false }));
      dispatch(setMessageId(''));
    };
  };

export const saveSettingsChangesOnSaveClickWeb = (
  onRefresh: () => void,
  onContinue: () => void,
  navigateCallback: NavigateFunction,
): AppThunk<void> => {
  return (dispatch, getState) => {
    const state = getState();
    const { settingsSelected } = state.settings.changeData;
    const onIsValid = () => {
      dispatch(
        checkForSettingsChangesAndNavigateWeb(
          () => onContinue && onContinue(),
          onRefresh,
          navigateCallback,
          true,
          true,
          settingsSelected === 'licenseSpecificPrinterRules' ? false : true,
          settingsSelected === 'licenseSettings' ? false : true,
        ),
      );
    };

    return dispatch(
      saveSettingsChangesOnSavePressCore(
        onRefresh,
        createPostRequestWeb(navigateCallback) as any,
        onIsValid,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const deleteSettingsElementWeb =
  (navigateCallback: NavigateFunction): AppThunk<Promise<boolean>> =>
  (dispatch, getState) => {
    const state = getState();

    const currentLocationId = state.app.locationId;
    const organizationId = state.app.organizationId;

    const licenseId = null;
    const currentDeviceLicenseSettingsId = 0;

    return dispatch(
      deleteSettingsElementCore(
        createPostRequestWeb(navigateCallback, true) as any,
        organizationId,
        currentLocationId,
        licenseId,
        currentDeviceLicenseSettingsId,
        webErrorCodesDispatcher,
        undefined,
      ) as unknown as AppThunk<Promise<boolean>>,
    );
  };

export const printTestKitchenTicketWeb =
  (
    printerId: number,
    prepStationVersionId?: number,
  ): AppThunk<Promise<{ success: boolean; id: number }>> =>
  async (dispatch, getState) => {
    const state = getState();

    const currentLocationId = selectLocationId(state);

    const organizationId = selectOrganizationId(state);

    const licenseId = null;

    return dispatch(
      printTestKitchenTicket(
        printerId,
        prepStationVersionId || 0,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        undefined,
      ) as unknown as AppThunk<Promise<{ success: boolean; id: number }>>,
    );
  };

export const checkForSettingsChangesAndNavigateWeb = (
  onContinue: () => void,
  onRefresh: () => void,
  navigateCallback: NavigateFunction,
  onContinueResetChangeData = true,
  isSavePressed = false,
  navigateOnSave = false,
  showDiscardChangesModal = true,
): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();

    const currentLocationId = state.app.locationId;
    const organizationId = state.app.organizationId;
    const currentDeviceLicenseSettingsId = 0;

    return dispatch(
      checkForSettingsChangesAndNavigateCore(
        onContinue,
        onRefresh,
        createPostRequestWeb(navigateCallback) as any,
        organizationId,
        currentLocationId,
        null,
        currentDeviceLicenseSettingsId,
        webErrorCodesDispatcher,
        undefined,
        onContinueResetChangeData,
        isSavePressed,
        navigateOnSave,
        showDiscardChangesModal,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const checkForCurrentUserDetailsLocationAccessAndContinue =
  (
    locationId: number,
    locationName: string,
    onContinue: () => void,
  ): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    const isUserDetailsScreen =
      state.settings.changeData.settingsSelected === 'users';
    if (!isUserDetailsScreen) {
      onContinue();
      return;
    }

    const userId = state.settings.changeData.id;
    const masterUser = state.settings.master.users[userId];
    const locationsAccess = masterUser?.locationIds;

    if (
      locationsAccess?.includes(locationId) ||
      (locationId === 0 && masterUser)
    ) {
      onContinue();
      return;
    }

    dispatch(showElementNotFoundInLocationToast(locationName, 'users'));
  };

export const cancelSettingsChangesOnCancelPressWeb = (
  customOnSuccess?: () => void,
): AppThunk<void> => {
  return dispatch =>
    dispatch(
      cancelSettingsChangesOnCancelPressCore(
        () => null,
        customOnSuccess,
      ) as unknown as AppThunk<void>,
    );
};

export const deactivateLicenseWeb = (
  onRefresh: () => void,
  deviceId: keyof ITablets,
  licenseId: keyof ILicenses,
  nickname: string,
  onDeactivationSuccess?: () => void,
): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();

    const currentLocationId = selectLocationId(state);
    const organizationId = selectOrganizationId(state);
    const currentDeviceLicenseSettingsId = 0;
    return dispatch(
      deactivateLicense(
        onRefresh,
        deviceId,
        licenseId,
        null,
        currentDeviceLicenseSettingsId,
        nickname,
        organizationId,
        currentLocationId,
        webErrorCodesDispatcher,
        undefined,
        onDeactivationSuccess,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const associateLicenseLocationWeb =
  (
    onRefresh: () => void,
    licenseIds: number[],
    locationId: number,
  ): AppThunk<Promise<{ success: boolean; id: number }>> =>
  async (dispatch, getState) => {
    const state = getState();

    const currentLocationId = selectLocationId(state);

    const organizationId = selectOrganizationId(state);

    const licenseId = null;
    const currentDeviceLicenseSettingsId = 0;

    return dispatch(
      associateLicenseLocation(
        onRefresh,
        licenseIds,
        locationId,
        organizationId,
        currentLocationId,
        licenseId,
        currentDeviceLicenseSettingsId,
        webErrorCodesDispatcher,
        undefined,
      ) as unknown as AppThunk<Promise<{ success: boolean; id: number }>>,
    );
  };

export const editSettingsElementWeb =
  (
    onRefresh: () => void,
    navigateCallback: NavigateFunction,
    navigate = true,
    request?: CreateSettingsBucketRequestType,
    mode?: FormMode,
  ): AppThunk<Promise<{ success: boolean; id: number }>> =>
  (dispatch, getState) => {
    const state = getState();

    const currentLocationId = selectLocationId(state);

    const organizationId = selectOrganizationId(state);
    const licenseId = null;
    const currentDeviceLicenseSettingsId = 0;

    return dispatch(
      editSettingsElement(
        onRefresh,
        navigate,
        createPostRequestWeb(navigateCallback) as any,
        organizationId,
        currentLocationId,
        licenseId,
        currentDeviceLicenseSettingsId,
        webErrorCodesDispatcher,
        undefined,
        request,
        mode,
      ) as unknown as AppThunk<Promise<{ success: boolean; id: number }>>,
    );
  };

export const changeSelectedSettingsModeWeb =
  (locationId: number, locationName: string): AppThunk =>
  (dispatch, getState) => {
    const getSettingsType = (id: number) => {
      const currentLocationId = getState().app.locationId;
      switch (id) {
        case 0:
          return SettingsTypes.MASTER_SETTINGS;
        case currentLocationId:
          return SettingsTypes.LOCATION_SETTINGS;
        default:
          return SettingsTypes.REMOTE_LOCATION_SETTINGS;
      }
    };
    const settingsType = getSettingsType(locationId);

    const { location, master, versions } = getState().settings;

    switch (settingsType) {
      case SettingsTypes.MASTER_SETTINGS:
        dispatch(
          setSelectedSettingsMode({
            settingsType: SettingsTypes.MASTER_SETTINGS,
            currentSettings: master,
            settingsVersion: versions.master,
            id: 0,
            name: 'Enterprise',
          }),
        );

        break;
      case SettingsTypes.LOCATION_SETTINGS:
        dispatch(
          setSelectedSettingsMode({
            settingsType: SettingsTypes.LOCATION_SETTINGS,
            currentSettings: location,
            settingsVersion: versions.location,
            id: locationId,
            name: locationName,
          }),
        );
        break;
      case SettingsTypes.REMOTE_LOCATION_SETTINGS: {
        return;
      }
    }
  };
