import BasicSection from '@app/components/LoggedIn/Menu/Discounts/DiscountDetails/BasicSection';
import DiscountSchemeSection from '@app/components/LoggedIn/Menu/Discounts/DiscountDetails/DiscountSchemeSection';
import MoreInformationSection from '@app/components/LoggedIn/Menu/Discounts/DiscountDetails/MoreInformationSection';
import Accordion from '@app/components/common/Accordion';
import useIsSet from '@app/hooks/useIsSet';
import useNavigateNewElement from '@app/hooks/useNavigateNewElement';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsMenu,
  DISCOUNT_INITIAL_STATE,
} from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const BUCKET = 'discounts';

function DiscountDetails() {
  const dispatch = useDispatch();
  const loadChangeData = bindActionCreators(
    actionCreatorsMenuWeb.loadMenuBucketChangeData,
    dispatch,
  );

  const resetChangeData = bindActionCreators(
    actionCreatorsMenu.resetChangeData,
    dispatch,
  );

  const { id } = useParams();

  const newId = useRootSelector(state => state.menu.changeData.data?.id ?? 0);

  const currentDiscount = useRootSelector(
    state =>
      state.menu.selectedMode.currentMenu.discounts[
        Number(id) || Number(newId)
      ] ?? DISCOUNT_INITIAL_STATE,
  );

  useNavigateNewElement(
    `/menu/discounts/${newId}`,
    Number(id),
    Number(newId),
    currentDiscount.name,
  );

  const isSet = useIsSet(BUCKET, id === undefined ? undefined : Number(id));

  useEffect(() => {
    if (!isSet)
      loadChangeData({
        id: Number(id),
        bucket: BUCKET,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, id]);

  useEffect(() => {
    return () => {
      resetChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isSet && (
      <Accordion>
        <BasicSection isExpanded />
        <MoreInformationSection />
        <DiscountSchemeSection />
      </Accordion>
    )
  );
}

export default DiscountDetails;
