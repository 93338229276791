import Card from '@app/components/common/Card';
import MainScreenContainer from '@app/components/common/MainScreenContainer';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { actionCreatorsApp, actionCreatorsMenuWeb } from '@app/state';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import {
  selectBreadCrumbs,
  selectModifierGroups,
} from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  IModifierGroupCard,
  menuModifierGroupsCardFactory,
} from '@westondev/tableturn-core';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const ModifierGroupsList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();
  const setShowToast = bindActionCreators(
    actionCreatorsApp.setShowToast,
    dispatch,
  );
  const modifierGroups = useSelector(selectModifierGroups);
  const breadcrumbs = useSelector(selectBreadCrumbs);

  // Local state
  const navigate = useNavigateWithOrg();

  const modifierGroupsCardList = useMemo(
    () => menuModifierGroupsCardFactory(modifierGroups),
    [modifierGroups],
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const dropdownOptions = useMemo(
    () => [
      [{ label: 'All', value: 0 }],
      [
        { label: t('commonTexts.active'), value: 1 },
        { label: t('commonTexts.inactive'), value: 2 },
      ],
    ],
    [t],
  );

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(5, 51, navigate);
      if (!navMain) return;
      dispatch(initializeBucketListBreadcrumb(navMain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleAddNewModGroup = () => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewModifierGroupTag'),
      onPress: () => navigate('/menu/modifiers/modifier-groups/add'),
      pathURL: '/menu/modifiers/modifier-groups/add',
    });
  };

  const dropdowns = useMemo(() => {
    const _dropdowns = [
      {
        id: 'activeFilter' as keyof IModifierGroupCard,
        label: t('commonTexts.status'),
        data: dropdownOptions,
        defaultValue: 1,
        dataExample: 1,
      },
    ];
    return _dropdowns;
  }, [dropdownOptions, t]);

  return (
    <MainScreenContainer
      dropdowns={dropdowns}
      breakpoint="medium"
      sortAvailable
      data={modifierGroupsCardList}
      optionList={[
        {
          text: t('menuScreen.menuLocation.actionButtons.addNewIngredient'),
          handler: handleAddNewModGroup,
        },
      ]}
      noItemsText={t('commonTexts.noneFound', {
        elementName: t('screenNames.ModifierGroupsScreen'),
      })}
      onOptionClickDisabled={() => {
        setShowToast({
          type: 'info',
          title: t('menuScreen.modifierGroupsMain.disabledActionToast.title'),
          description: t(
            'menuScreen.modifierGroupsMain.disabledActionToast.description',
          ),
        });
      }}
      renderItem={modGroup => {
        const pathURL = `/menu/modifiers/modifier-groups/${modGroup.id}`;
        return (
          <Card.Item
            title={modGroup.title}
            isActive={modGroup.status}
            showStatus
            tag={modGroup.customTag}
            onClick={openInNewTab => {
              if (openInNewTab) return openNewTabWithOrgData(pathURL);

              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text: modGroup.title,
                onPress: () => {
                  navigate(pathURL);
                },
                statusPath: `selectedMode.currentMenu.groups.${modGroup.id}.active`,
                pathURL,
              });
            }}
            count={modGroup.itemCount + modGroup.modifierItemCount}
            isLink={getPathWithOrgData(pathURL)}
          />
        );
      }}
    />
  );
};

export default ModifierGroupsList;
