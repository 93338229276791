import Checkbox from '@app/components/common/Checkbox';
import { ICheckbox } from '@app/components/common/Checkbox/types';
import { MassUpdateValues } from '@westondev/tableturn-core';

const CheckboxMassUpdate = (
  props: Omit<ICheckbox, 'checked'> & { checked: boolean | MassUpdateValues },
) => {
  const getISChecked = () => {
    if (props.checked === MassUpdateValues.NO_CHANGES) {
      return false;
    } else return props.checked;
  };
  return <Checkbox {...props} checked={getISChecked()} />;
};

export default CheckboxMassUpdate;
