import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import Box from '@app/components/common/Box';
import ColorPicker from '@app/components/common/ColorPicker';
import ResetButton from '@app/components/common/ResetButton';
import { WithTranslation } from 'react-i18next';

interface IBgAndTextColorPickers extends WithTranslation {
  backgroundColor: string;
  textColor: string;
  onBackgroundColorChange: (color: string) => void;
  onTextColorChange: (color: string) => void;
  onReset: () => void;
  onResetBackground?: () => void;
  onResetText?: () => void;
}

const BgAndTextColorPickers = ({
  t,
  backgroundColor,
  textColor,
  onBackgroundColorChange,
  onTextColorChange,
  onReset,
  onResetBackground,
  onResetText,
}: IBgAndTextColorPickers) => {
  return (
    <Box>
      <DetailsSubtitle>
        {t(
          'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.colors',
        )}
      </DetailsSubtitle>
      <Box
        csx={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: '20px',
        }}>
        <ColorPicker
          label={t(
            'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.background',
          )}
          color={backgroundColor}
          onChange={onBackgroundColorChange}
          onReset={onResetBackground}
          showHexInput
        />

        <ColorPicker
          label={t(
            'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.text',
          )}
          color={textColor}
          onChange={onTextColorChange}
          onReset={onResetText}
          showHexInput
        />

        <Box
          csx={{
            paddingBottom: '55px',
          }}>
          <ResetButton onClick={() => onReset()} />
        </Box>
      </Box>
    </Box>
  );
};

export default BgAndTextColorPickers;
