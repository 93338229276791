import { TCsx } from '@emotion/react';
import { ReactNode } from 'react';

export interface TBreakpoints {
  mb?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export interface TOffsets {
  mbOffset?: number;
  smOffset?: number;
  mdOffset?: number;
  lgOffset?: number;
  xlOffset?: number;
}

export interface IGrid {
  children: ReactNode;
  simpleMode?: boolean;
  container?: boolean;
  columnGap?: number | TBreakpoints;
  rowGap?: number | TBreakpoints;
  csx?: TCsx;
  columns?: number | TBreakpoints;
}

export const DEFAULT_COLUMN_SIZE = 12;
