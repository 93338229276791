import Accordion from '@app/components/common/Accordion';
import { IAccordionRef } from '@app/components/common/Accordion/types';
import Box from '@app/components/common/Box';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import BasicSection from '@app/components/LoggedIn/Menu/Combos/CombosDetails/BasicSection';
import InventorySection from '@app/components/LoggedIn/Menu/Combos/CombosDetails/InventorySection';
import ItemGroupsSection from '@app/components/LoggedIn/Menu/Combos/CombosDetails/ItemGroupsSection';
import MoreInformationSection from '@app/components/LoggedIn/Menu/Combos/CombosDetails/MoreInformationSection';
import RegisterDisplaySection from '@app/components/LoggedIn/Menu/Combos/CombosDetails/RegisterDisplaySection';
import TaxesSection from '@app/components/LoggedIn/Menu/Combos/CombosDetails/TaxesSection';
import HiddenSection from '@app/components/LoggedIn/Menu/common/HiddenSection';
import MenuTypesSection from '@app/components/LoggedIn/Menu/common/MenuTypesSection';
import { getIsMenuTypeItemHidden } from '@app/helpers/factories/menu/cardFactories/items/menuItemCardFactory';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useIsSet from '@app/hooks/useIsSet';
import useNavigateNewElement from '@app/hooks/useNavigateNewElement';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  selectBreadCrumbs,
  selectCategoriesSubcategories,
  selectCurrentItem,
  selectMenuTypes,
  selectMenuTypeVersionsIds,
} from '@app/state/menu/menuSelectors';
import {
  actionCreatorsMenu,
  COMBO_INITIAL_STATE,
  FormMode,
  HiddenReason,
  ICombo,
  IMassUpdateSelectedData,
  MenuTypesSectionRef,
  SectionId,
} from '@westondev/tableturn-core';
import { t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const BUCKET = 'combos';

const CombosDetails = () => {
  const { id: comboId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const loadMenuBucketChangeData = bindActionCreators(
    actionCreatorsMenuWeb.loadMenuBucketChangeData,
    dispatch,
  );

  const {
    resetChangeData,
    addNewMenuTypesToItem,
    setBreadCrumbStatusPath,
    updateItem: setValue,
  } = bindActionCreators(actionCreatorsMenu, dispatch);

  const menuTypeVersions = useSelector(selectMenuTypeVersionsIds, shallowEqual);
  const breadCrumbs = useSelector(selectBreadCrumbs);

  const currentName = useRootSelector(
    state => (selectCurrentItem(state) as ICombo)?.name,
  );

  const {
    cloneId,
    menuTypeId,
    assignedMenuTypeIds,
    massUpdateData,
    categoryId,
    subcategoryId,
  } = useMemo(() => {
    if (location.state) {
      return {
        cloneId: location.state.cloneId as number,
        menuTypeId: location.state.menuTypeId as number,
        assignedMenuTypeIds: location.state.assignedMenuTypeIds as number[],
        massUpdateData: location.state
          .massUpdateData as IMassUpdateSelectedData,
        categoryId: location.state.categoryId
          ? Number(location.state.categoryId)
          : null,
        subcategoryId: location.state.subcategoryId
          ? Number(location.state.subcategoryId)
          : null,
      };
    }
    return {
      cloneId: undefined,
      menuTypeId: 0,
      assignedMenuTypeIds: undefined,
      massUpdateData: undefined,
      categoryId: null,
      subcategoryId: null,
    };
  }, [location]);

  // Local state
  const [selectedMenuTypeVersionId, setSelectedMenuTypeVersionId] =
    useState<number>(menuTypeId || -1);
  const associationSectionRef = useRef<IAccordionRef>(null);
  const menuTypesRef = useRef<MenuTypesSectionRef>(null);
  const prevMenuTypesRef = useRef(false);
  const prevSelectedMenuType = useRef(0);

  const newId = useRootSelector(state => state.menu.changeData.data?.id ?? 0);

  const currentCombo = useRootSelector(
    state =>
      state.menu.selectedMode.currentMenu.combos[
        Number(comboId) || cloneId || Number(newId)
      ] ?? COMBO_INITIAL_STATE,
  );

  useNavigateNewElement(
    `/menu/combos/combos/${newId}`,
    Number(comboId),
    Number(newId),
    currentCombo.name,
  );

  useEffect(() => {
    if (selectedMenuTypeVersionId >= 0 && categoryId) {
      setValue(
        {
          categoriesSubcategories: {
            0: { categoryId: categoryId, subcategoryId: subcategoryId },
          },
        },
        SectionId.BASIC,
        selectedMenuTypeVersionId,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, selectedMenuTypeVersionId]);

  const isSet = useIsSet(
    BUCKET,
    comboId === undefined ? undefined : Number(comboId),
  );

  const mode = useMemo(() => {
    if (massUpdateData) return FormMode.MASS_UPDATE;
    return !comboId ? FormMode.CREATE : FormMode.UPDATE;
  }, [comboId, massUpdateData]);

  const isFocused =
    location.pathname === getPathWithOrgData(`/menu/combos/combos/${comboId}`);

  const categoriesSubcategories = useRootSelector(state =>
    selectCategoriesSubcategories(state, selectedMenuTypeVersionId),
  );

  const menuTypeName = useRootSelector(state =>
    selectedMenuTypeVersionId > 0
      ? selectMenuTypes(state)[selectedMenuTypeVersionId]?.name || ''
      : '',
  );

  const { hiddenBucket, isHidden, hiddenReason } = useMemo(() => {
    const _isHidden = getIsMenuTypeItemHidden(
      categoriesSubcategories,
      'combos',
      Number(newId),
      selectedMenuTypeVersionId,
    );
    return {
      isHidden: _isHidden.isHidden,
      hiddenBucket: _isHidden.bucket,
      hiddenReason: _isHidden.reason,
    };
  }, [categoriesSubcategories, newId, selectedMenuTypeVersionId]);

  const currentBreadcrumbMenuTypeId = useMemo(() => {
    let _currentBreadcrumbMenuTypeId = -1;

    if (!isFocused) return _currentBreadcrumbMenuTypeId;

    if (breadCrumbs.length > 0) {
      const statusPath = breadCrumbs[breadCrumbs.length - 1].statusPath;

      if (statusPath && statusPath.split('.').length >= 5) {
        const splittedPath = statusPath.split('.');
        if (!splittedPath.includes('menuTypeVersions'))
          return _currentBreadcrumbMenuTypeId;

        _currentBreadcrumbMenuTypeId = splittedPath.includes('changeData')
          ? Number(splittedPath[3])
          : splittedPath.includes('selectedMode') && splittedPath[5]
          ? Number(splittedPath[5])
          : _currentBreadcrumbMenuTypeId;
      }
    }

    return _currentBreadcrumbMenuTypeId;
  }, [breadCrumbs, isFocused]);

  const areSectionsDisabled =
    mode === FormMode.CREATE && selectedMenuTypeVersionId === 0;

  useEffect(() => {
    let menuTypeVersionId =
      menuTypeId || Object.values(currentCombo.menuTypeVersions)[0].menuTypeId;

    menuTypeVersionId =
      menuTypeVersions.length > 0 &&
      !menuTypeVersions.includes(menuTypeVersionId)
        ? menuTypeVersions[0]
        : menuTypeVersionId;

    if (
      selectedMenuTypeVersionId <= 0 ||
      currentBreadcrumbMenuTypeId > 0 ||
      (!currentCombo.menuTypeVersions[selectedMenuTypeVersionId] && !isSet) ||
      !menuTypeVersions.includes(selectedMenuTypeVersionId)
    ) {
      if (currentBreadcrumbMenuTypeId > 0) {
        if (currentBreadcrumbMenuTypeId !== prevSelectedMenuType.current) {
          setSelectedMenuTypeVersionId(currentBreadcrumbMenuTypeId);
        }
      } else {
        if (!menuTypeVersionId && menuTypeId && menuTypeVersions.length === 0) {
          setSelectedMenuTypeVersionId(menuTypeId);
        } else {
          setSelectedMenuTypeVersionId(menuTypeVersionId);
        }
      }
    }

    if (
      menuTypesRef.current &&
      !prevMenuTypesRef.current &&
      !menuTypeVersions.includes(0) &&
      currentBreadcrumbMenuTypeId < 0
    ) {
      !menuTypeId && menuTypesRef.current?.setFirstActiveMenuType();
      prevMenuTypesRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    menuTypeVersions,
    menuTypesRef.current,
    currentBreadcrumbMenuTypeId,
    selectedMenuTypeVersionId,
  ]);

  useEffect(() => {
    if (!isSet) {
      loadMenuBucketChangeData({
        bucket: BUCKET,
        id: Number(comboId),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, comboId, currentCombo]);

  useEffect(() => {
    return () => {
      resetChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!prevMenuTypesRef.current) {
      mode === FormMode.CREATE &&
        !assignedMenuTypeIds &&
        menuTypesRef.current?.openExistingTypes();
      prevMenuTypesRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuTypesRef.current]);

  useEffect(() => {
    // Check for the selectedMenuTypeVersionId change and update the list of sections
    if (selectedMenuTypeVersionId >= 0) {
      setBreadCrumbStatusPath(
        breadCrumbs.length - 1,
        `changeData.data.menuTypeVersions.${selectedMenuTypeVersionId}.active`,
        true,
      );
      prevSelectedMenuType.current = selectedMenuTypeVersionId;
    }
    if (selectedMenuTypeVersionId > 0) {
      prevSelectedMenuType.current = selectedMenuTypeVersionId;
    }
    return () => {
      setBreadCrumbStatusPath(
        breadCrumbs.length - 1,
        `selectedMode.currentMenu.combos.${comboId}.menuTypeVersions.${
          selectedMenuTypeVersionId || prevSelectedMenuType.current
        }.active`,
        true,
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumbs.length, selectedMenuTypeVersionId, comboId]);

  useEffect(() => {
    if (isSet && mode === FormMode.CREATE) {
      menuTypesRef.current?.openExistingTypes();
      menuTypesRef.current?.setFirstActiveMenuType();
    }
    prevMenuTypesRef.current = false;
  }, [mode, isSet]);

  const handleNewMenuType = (
    menuTypeIds: IItemSelection[],
    firstActiveMenuType?: IItemSelection,
  ) => {
    addNewMenuTypesToItem(
      menuTypeIds,
      selectedMenuTypeVersionId,
      () => {
        menuTypeIds[0] &&
          setSelectedMenuTypeVersionId(
            Number(
              firstActiveMenuType ? firstActiveMenuType.id : menuTypeIds[0].id,
            ),
          );
      },
      true,
    );
  };

  const isHiddenDueSimpleMode = hiddenReason === HiddenReason.simpleMode;

  const showAssociateButton = !isHiddenDueSimpleMode;

  return (
    isSet && (
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}>
        <>
          {isHidden && (
            <HiddenSection
              name={currentName}
              // There does not exist a category-subcategory couple where both are active
              bucketOrigin="combo"
              bucket={isHiddenDueSimpleMode ? 'menuType' : hiddenBucket}
              description={hiddenReason}
              menuTypeName={menuTypeName}
              navigationInfo={{
                id:
                  (isHiddenDueSimpleMode ? selectedMenuTypeVersionId : 0) || 0,
                text: isHiddenDueSimpleMode ? menuTypeName : '0',
              }}
              scrollText={t(
                'menuScreen.itemDetails.hiddenItemSection.button.review',
              )}
              onScrollClick={
                showAssociateButton
                  ? () => {
                      const section = document.getElementById(SectionId.BASIC);

                      const associationSectionIndex = 0;

                      associationSectionRef.current?.expandSection(
                        associationSectionIndex,
                      );

                      setTimeout(() => {
                        section?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'end',
                        });
                      }, 0);
                    }
                  : undefined
              }
            />
          )}
          <MenuTypesSection
            selectedMenuTypeVersionId={selectedMenuTypeVersionId}
            setSelectedMenuTypeVersionId={setSelectedMenuTypeVersionId}
            ref={menuTypesRef}
            title={t('menuScreen.comboDetails.menuTypes.title')}
            sectionDescription={t(
              'menuScreen.comboDetails.menuTypes.sectionDescription',
            )}
            description={t('menuScreen.comboDetails.menuTypes.description')}
            onNewMenuType={handleNewMenuType}
            emptyMenuTypesPath="comboDetails"
          />
          <Accordion ref={associationSectionRef}>
            <BasicSection
              sectionId={SectionId.BASIC}
              isExpanded
              isCombo
              menuTypeVersionId={selectedMenuTypeVersionId}
              isDisabled={areSectionsDisabled}
            />
            <RegisterDisplaySection
              sectionId={SectionId.REGISTER}
              menuTypeVersionId={selectedMenuTypeVersionId}
              isCombo
              isDisabled={areSectionsDisabled}
            />
            {mode !== FormMode.MASS_UPDATE && (
              <TaxesSection
                sectionId={SectionId.TAXES}
                menuTypeVersionId={selectedMenuTypeVersionId}
                isDisabled={areSectionsDisabled}
                isCombo
              />
            )}
            <InventorySection
              sectionId={SectionId.INVENTORY}
              menuTypeVersionId={selectedMenuTypeVersionId}
              isDisabled={areSectionsDisabled}
              isCombo
            />
            <MoreInformationSection
              sectionId={SectionId.MORE_INFO}
              menuTypeVersionId={selectedMenuTypeVersionId}
              isDisabled={areSectionsDisabled}
              isCombo
            />
            <ItemGroupsSection
              sectionId={SectionId.ITEM_GROUPS}
              menuTypeVersionId={selectedMenuTypeVersionId}
              isDisabled={areSectionsDisabled}
              isCombo
            />
          </Accordion>
        </>
      </Box>
    )
  );
};

export default CombosDetails;
