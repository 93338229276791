import Box from '@app/components/common/Box';
import BreadCrumb from '@app/components/common/BreadCrumb/BreadCrumb';
import Input from '@app/components/common/Input';
import Typography from '@app/components/common/Typography';
import { MENU_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  selectBreadCrumbs,
  selectIsMenuMasterMode,
  selectItemCustomId,
} from '@app/state/menu/menuSelectors';
import { IBreadCrumbWeb } from '@app/types';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsMenu,
  BreadCrumbAction,
  IMenuStore,
  SectionId,
  useFormError,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useMatch, useParams } from 'react-router-dom';
import CategoriesList from '../../Categories/CategoriesList';
import CombosList from '../../Combos/CombosList';
import DiscountsList from '../../Discounts/DiscountsList';
import IngredientsList from '../../Ingredients/IngredientsList';
import ItemGroupsList from '../../ItemGroups/ItemGroupsList';
import ItemsList from '../../Items/ItemsList';
import MenuTypesList from '../../MenuTypes/MenuTypesList';
import ModifierGroupsList from '../../ModifierGroups/ModifierGroupsList';
import ModifierItemsList from '../../ModifierItems/ModifierItemsList';
import SpecialsList from '../../Specials/SpecialsList';
import SubcategoriesList from '../../Subcategories/SubcategoriesList';
import StickyContainer from './StickyContainer';

interface IMenuBucketNavSwitch {
  bucket: keyof IMenuStore;
}

const MenuBucketNavSwitch = ({
  bucket,
  t,
}: IMenuBucketNavSwitch & WithTranslation) => {
  const onRefresh = useRefreshMenuWeb();
  const dispatch = useDispatch();
  const { checkBreadCrumbLocationWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    dispatch,
  );
  const { updateItem } = bindActionCreators(actionCreatorsMenu, useDispatch());

  const breadCrumbs = useSelector(selectBreadCrumbs) as IBreadCrumbWeb[];
  const customId = useSelector(selectItemCustomId);
  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const isItem = bucket === 'items';

  const customIdError = useFormError('customId');

  const route = MENU_SCREEN_BY_BUCKET_NAME[bucket];
  const match = useMatch(getPathWithOrgData(route));
  const id = useParams().id;

  const handleClick = (locationIndex: number) => {
    if (locationIndex + 1 !== breadCrumbs.length) {
      checkBreadCrumbLocationWeb(locationIndex, onRefresh);
    }
  };

  return !match ? (
    <>
      <StickyContainer>
        {breadCrumbs
          .filter(item => item.action !== BreadCrumbAction.NAV_OTHER)
          .map((breadCrumb, index) => {
            const isLast = index === breadCrumbs.length - 1;

            return (
              <BreadCrumb
                key={index}
                isLast={isLast}
                breadCrumb={breadCrumb}
                onClick={() => handleClick(index)}
                showActiveStyle={isLast}
                isSettings={false}
              />
            );
          })}

        {id && (
          <Box
            csx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
            }}>
            <Typography color="semanticBlue" fontWeight="bold">
              TableTurn ID:
            </Typography>
            <Typography>{id}</Typography>
            {isItem && (isMasterMode || !!customId) && (
              <>
                <Box
                  csx={{
                    height: 40,
                    width: 1,
                    backgroundColor: 'lightGrey',
                  }}
                />
                <Typography
                  color={customIdError?.error ? 'semanticRed' : 'semanticBlue'}
                  fontWeight="bold">
                  {`${t('menuScreen.itemDetails.customId')}: `}
                </Typography>
                {!isMasterMode && <Typography>{customId}</Typography>}
                {isMasterMode && (
                  <Box>
                    <Input
                      containerCsx={{ width: 150 }}
                      placeholder={t('menuScreen.itemDetails.customId')}
                      value={customId}
                      onChange={newCustomId =>
                        updateItem(
                          { customId: newCustomId.target.value },
                          SectionId.CUSTOM_ID,
                        )
                      }
                      error={Boolean(customIdError?.error)}
                      onFocus={() => customIdError && customIdError.clear()}
                      trimOnBlur
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </StickyContainer>
      <Box csx={{ padding: '4px 15px 15px 15px' }}>
        <Outlet />
      </Box>
    </>
  ) : (
    <Box csx={{ padding: '15px', height: '100%' }}>
      <ListComponent bucket={bucket} />
    </Box>
  );
};

const ListComponent = ({ bucket }: IMenuBucketNavSwitch) => {
  switch (bucket) {
    case 'menuTypes':
      return <MenuTypesList />;

    case 'items':
      return <ItemsList />;

    case 'categories':
      return <CategoriesList />;

    case 'subcategories':
      return <SubcategoriesList />;

    case 'modifierGroups':
      return <ModifierGroupsList />;

    case 'modifierItems':
      return <ModifierItemsList />;

    case 'ingredients':
      return <IngredientsList />;

    case 'specials':
      return <SpecialsList />;

    case 'discounts':
      return <DiscountsList />;

    case 'combos':
      return <CombosList />;

    case 'itemGroups':
      return <ItemGroupsList />;
  }

  return null;
};

export default MenuBucketNavSwitch;
