import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { stepsContainerStyles } from './styles';
import Icon from '@app/components/common/Icon';
import { useMemo } from 'react';
import { IStep } from './types';

interface IStepper {
  steps: IStep;
  getStepIndex?: (index?: number) => void;
  clickable?: boolean;
}
const Stepper = ({ steps, getStepIndex, clickable = true }: IStepper) => {
  const stepsArray = useMemo(() => Object.values(steps), [steps]);

  const handleCheckStepCompleted = (index: number) => {
    if (getStepIndex) {
      const isNextStepCompleted = stepsArray[index].completed;
      const arePreviousToNextStepCompleted =
        index !== 0
          ? !stepsArray.slice(0, index).some(step => !step.completed)
          : true;

      const currentStepIndex = stepsArray.findIndex(
        step => step.active === true,
      );
      const validToChangeStep = currentStepIndex !== index;

      if (
        (validToChangeStep &&
          isNextStepCompleted &&
          arePreviousToNextStepCompleted) ||
        (validToChangeStep && arePreviousToNextStepCompleted)
      )
        getStepIndex(index);
      else getStepIndex();
    }
  };
  return (
    <Box csx={stepsContainerStyles(clickable)}>
      {stepsArray.map((step, index) => (
        <Box
          key={index}
          className={`stepContainer ${
            step.active ? 'active' : step.completed ? 'completed' : ''
          }`}
          onClick={
            clickable ? () => handleCheckStepCompleted(index) : undefined
          }>
          <Box className="step">
            <Typography>{index + 1}</Typography>
          </Box>
          <Typography className="stepName">{step.name}</Typography>
          {stepsArray.length !== index + 1 && (
            <Icon name="MdChevronRight" size="30px" />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Stepper;
