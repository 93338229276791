import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import useItemChangeColor from '@app/hooks/useItemChangeColor';
import useRootSelector from '@app/hooks/useRootSelector';
import { selectChangeDataId } from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import {
  CoreRootState,
  diffModifierGroupsFactory,
  IModifierGroupPricingVersion,
  IModifierGroupPricingVersionFront,
  IModifierGroups,
  TChangeModifierGroups,
  TChangeModifierItems,
  TModifierGroupsDiff,
  TPricingVersionDiff,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import PricingVersionsButtons from '../../common/PricingVersionsButtons';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';

const ModifierGroupsDiffModal = ({ t }: WithTranslation) => {
  //Redux state
  const { differences, data } = useRootSelector(state => state.menu.changeData);
  const modifierGroupId = useSelector(selectChangeDataId);

  const {
    itemChangeColor,
    modifierItemBoxBorderColor,
    modifierItemBoxColor,
    boxTextAndBorderColor,
    boxColor,
    boxRemovedColor,
    boxRemovedTextColor,
    selectedColor,
  } = useItemChangeColor();

  const currentMenu = useSelector(
    (state: RootState) => state.menu.selectedMode.currentMenu,
  );

  const modifierGroupData = data as IModifierGroups;

  const [selectedPricingVersion, setSelectedPricingVersion] = useState(0);

  const formattedDifferences = useMemo(() => {
    return diffModifierGroupsFactory(
      modifierGroupId,
      differences,
      modifierGroupData,
      store.getState() as CoreRootState,
    );
  }, [modifierGroupId, differences, modifierGroupData]);

  const generatePricingVersionsList = (
    modifierItems: {
      label: string;
      id: number;
      changes: TChangeModifierGroups[];
    }[],
    menuModifierGroupsPricingVersion: {
      [key: number]: IModifierGroupPricingVersion;
    },
    changedPricingVersions: TPricingVersionDiff[],
  ) => {
    modifierItems = modifierItems.filter(modifierItem => {
      const changesNoPricingVersion = modifierItem.changes.filter(
        change => !change.pricingVersionId,
      );

      return changesNoPricingVersion.length > 0
        ? true
        : !modifierItem.changes.find(
            change => change.field === 'modItemPricingVersions',
          );
    });
    let pricingVersions =
      modifierItems.length === 0
        ? Object.values(menuModifierGroupsPricingVersion).filter(
            pricingVersion =>
              changedPricingVersions.find(
                change =>
                  change.id === pricingVersion?.modifierGroupPricingVersionId ||
                  0,
              ),
          )
        : Object.values(menuModifierGroupsPricingVersion);

    pricingVersions = [
      ...pricingVersions,
      ...changedPricingVersions
        .filter(changedPricingVersion => changedPricingVersion.action === 'add')
        .map(changedPricingVersion => {
          return {
            id: changedPricingVersion.id,
            action: changedPricingVersion.action,
            name: changedPricingVersion.label,
            totalOfChanges: changedPricingVersion.totalOfChanges,
            organizationId: 0,
            locationId: 0,
            createdByLocationId: 0,
            modifierGroupId: 0,
            modItemPricingVersions: {},
            modifierGroupPricingVersionId: changedPricingVersion.id,
            locationModifierGroupPricingVersionVersionId: 0,
            locationModifierGroupPricingVersionId: 0,
          };
        }),
    ];

    pricingVersions = pricingVersions.map(pricingVersion => {
      const changedPricingVersion = changedPricingVersions.find(
        change =>
          change.id === pricingVersion?.modifierGroupPricingVersionId || 0,
      );
      let action = 'update';
      let totalOfChanges = 0;
      let name = pricingVersion.name;
      if (changedPricingVersion) {
        action = changedPricingVersion.action;
        totalOfChanges = changedPricingVersion.totalOfChanges;
        name = changedPricingVersion.label;
      }
      return {
        ...pricingVersion,
        action,
        totalOfChanges,
        name,
      };
    });

    return pricingVersions as IModifierGroupPricingVersionFront[];
  };

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (textValue) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const pricingVersions = generatePricingVersionsList(
    formattedDifferences?.modifierItems || [],
    currentMenu.modifierGroups[modifierGroupId]?.modifierGroupPricingVersions ||
      {},
    formattedDifferences?.pricingVersions || [],
  );

  const renderModifierItems = (
    currentFormattedDifferences: TModifierGroupsDiff,
  ) => {
    if (
      pricingVersions[selectedPricingVersion] &&
      pricingVersions[selectedPricingVersion].action === 'remove'
    ) {
      return (
        <Typography align="center">
          {t('app.modals.modifierGroupsDiffModal.pricingVersionRemoved')}
        </Typography>
      );
    } else {
      // Ignore changes depending on the selected pricing version
      const modifierItems = currentFormattedDifferences.modifierItems
        .map(modifierItem => {
          const modifierItemChanges = modifierItem.changes
            .map(change => {
              // Ignore the change that has a pricingVersionId different of the selectedPricingVersion
              if (
                (change.pricingVersionId &&
                  change.pricingVersionId !==
                    pricingVersions[selectedPricingVersion]
                      ?.modifierGroupPricingVersionId) ||
                0
              )
                return null;
              return change;
            })
            .filter(change => change !== null);

          // Return the filtered changed
          return {
            ...modifierItem,
            changes: [...(modifierItemChanges as TChangeModifierItems[])],
          };
        })
        .filter(
          modifierItem =>
            modifierItem.changes.length > 0 ||
            (modifierItem.changes.length === 0 &&
              modifierItem.action !== 'update'),
        );

      return (
        <>
          {modifierItems.length > 0 && (
            <HeaderDiffModal
              name={t(
                'app.modals.modifierGroupsDiffModal.sectionTitles.modifierItems',
              )}
              color="black"
            />
          )}

          <Grid
            columnGap={10}
            rowGap={10}
            csx={{
              width: '100%',
              height: '100%',
            }}>
            {modifierItems.map(item => (
              <Grid.Item
                mb={12}
                sm={6}
                md={4}
                key={`item_${item.label}`}
                csx={{
                  backgroundColor: modifierItemBoxColor,
                  border: `1px solid ${modifierItemBoxBorderColor}`,
                  padding: 8,
                  borderRadius: 10,
                }}>
                <Box
                  csx={{
                    width: '100%',
                    borderRadius: 10,
                    backgroundColor: 'white',
                    height: 66,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px solid ${boxTextAndBorderColor}`,
                  }}>
                  <Typography fontWeight="medium" color="black">
                    {item.label}
                  </Typography>
                </Box>
                {item.changes.length > 0 && (
                  <Box
                    csx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      gap: '20px',
                      padding: '10px 20px',
                    }}>
                    {item.changes.map((change, index) => (
                      <Box
                        key={`itemChange_${item.label}_${index}`}
                        csx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBlock: 10,
                        }}>
                        <Box csx={{ width: 70 }}>
                          <Typography
                            variant="caption2"
                            color={itemChangeColor}>
                            {change.label}:
                          </Typography>
                        </Box>
                        <Box
                          csx={{
                            width: 70,
                            height: 35,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor:
                              change.value === false &&
                              change.field !== 'isSelected'
                                ? boxRemovedColor
                                : boxColor,
                            borderRadius: 10,
                            border: `1px solid ${
                              change.value === false &&
                              change.field !== 'isSelected'
                                ? boxRemovedTextColor
                                : boxTextAndBorderColor
                            }`,
                          }}>
                          <Typography
                            color={
                              change.value === false &&
                              change.field !== 'isSelected'
                                ? boxRemovedTextColor
                                : boxTextAndBorderColor
                            }
                            variant="caption2"
                            align="center">
                            {formatValue(
                              change.value as string,
                              change.field,
                              change.textValue,
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </Grid.Item>
            ))}
          </Grid>
        </>
      );
    }
  };

  return (
    <Box>
      {formattedDifferences && (
        <>
          {formattedDifferences.masterChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.commonChanges')}
                color="black"
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.masterChanges.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`master_change_${change.field}`}>
                    <RenderChange change={change} formatValue={formatValue} />
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => {
                  return (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`basic_change_${change.field}`}>
                      <RenderChange change={change} formatValue={formatValue} />
                    </Grid.Item>
                  );
                })}
              </Grid>
            </>
          )}

          {formattedDifferences.register.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.settings.register')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.register.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_change_${change.field}`}>
                    <RenderChange change={change} formatValue={formatValue} />
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {(formattedDifferences.modifierItems.length > 0 ||
            formattedDifferences.pricingVersions.length > 0) && (
            <>
              <HeaderDiffModal
                name={t(
                  'app.modals.modifierGroupsDiffModal.sectionTitles.associations',
                )}
              />

              {formattedDifferences.selectedModifierItems > 0 && (
                <Box
                  csx={theme => ({
                    backgroundColor: theme.colors.lightestBlue,
                    width: '100%',
                    height: '66px',
                    padding: '10px',
                    borderRadius: '8px',
                    marginBottom: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  })}>
                  <Typography>
                    {t('app.modals.modifierGroupsDiffModal.selectedDefault')}:
                  </Typography>
                  <Box
                    csx={{
                      borderRadius: '8px',
                      backgroundColor: selectedColor,
                      display: 'flex',
                      padding: '5px 40px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: `1px solid ${boxTextAndBorderColor}`,
                      marginLeft: '10px',
                    }}>
                    <Typography color={boxTextAndBorderColor} align="center">
                      {formattedDifferences.selectedModifierItems}
                    </Typography>
                  </Box>
                </Box>
              )}

              <Box>
                <Typography>
                  {t(
                    'app.modals.modifierGroupsDiffModal.associations.description',
                  )}
                </Typography>
              </Box>

              <PricingVersionsButtons
                pricingVersions={pricingVersions}
                onPress={newPricingVersionIndex => {
                  setSelectedPricingVersion(newPricingVersionIndex);
                }}
                selectedPricingVersion={selectedPricingVersion}
                currentFormattedDifferences={formattedDifferences}
              />

              {formattedDifferences.modifierItems.length > 0 && (
                <Box csx={{ paddingTop: '20px' }}>
                  {renderModifierItems(formattedDifferences)}
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
export default ModifierGroupsDiffModal;
