import { CombinedState, combineReducers } from '@reduxjs/toolkit';
import {
  ISettingsReducerStore,
  RegisterReducers,
  locationsReducer,
  loggedInUserReducer,
  menuReducer,
  settingsReducer,
  tableLayoutReducer,
} from '@westondev/tableturn-core';
import { Reducer } from 'redux';
import appReducer from './app/reducers';
import { RESET_GLOBAL_STATE } from './app/types';

const combinedReducer = combineReducers({
  app: appReducer,
  settings: settingsReducer as Reducer<CombinedState<ISettingsReducerStore>>,
  locations: locationsReducer,
  menu: menuReducer,
  loggedInUser: loggedInUserReducer,
  tableLayout: tableLayoutReducer, //FIXME: when animations are ready for the table layout screen add types
  register: RegisterReducers,
});

export const rootReducer: typeof combinedReducer = (state, action) => {
  switch (action.type) {
    case RESET_GLOBAL_STATE: {
      return combinedReducer(undefined, action);
    }
    default:
      return combinedReducer(state, action);
  }
};
