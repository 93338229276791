import AssociationsSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/AssociationsSection';
import BasicSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/BasicSection';
import GuestCheckDisplaySection from '@app/components/LoggedIn/Menu/Items/ItemDetails/GuestCheckDisplaySection';
import IngredientsSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/IngredientsSection';
import InventorySection from '@app/components/LoggedIn/Menu/Items/ItemDetails/InventorySection';
import ModifierGroupsSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/ModifierGroupsSection';
import MoreInformationSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/MoreInformationSection';
import PrepStationsSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/PrepStationsSection';
import RegisterDisplaySection from '@app/components/LoggedIn/Menu/Items/ItemDetails/RegisterDisplaySection';
import SpecialsSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/SpecialsSection';
import SuggestiveOrderingSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/SuggestiveOrderingSection';
import TaxesSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/TaxesSection';
import HiddenSection from '@app/components/LoggedIn/Menu/common/HiddenSection';
import MenuTypesSection from '@app/components/LoggedIn/Menu/common/MenuTypesSection';
import Accordion from '@app/components/common/Accordion';
import { IAccordionRef } from '@app/components/common/Accordion/types';
import Box from '@app/components/common/Box';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import { getIsMenuTypeItemHidden } from '@app/helpers/factories/menu/cardFactories/items/menuItemCardFactory';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useIsSet from '@app/hooks/useIsSet';
import useNavigateNewElement from '@app/hooks/useNavigateNewElement';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsApp, actionCreatorsMenuWeb } from '@app/state';
import {
  selectBucketSelected,
  selectCategoriesSubcategories,
  selectMenuTypes,
  selectMenuTypeVersionsIds,
  selectSelectedModeId,
} from '@app/state/menu/menuSelectors';
import {
  actionCreatorsMenu,
  FormMode,
  HiddenReason,
  IMassUpdateSelectedData,
  ITEM_INITIAL_STATE,
  MenuTypesSectionRef,
  SectionId,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const BUCKET = 'items';

const ItemDetails = ({ t }: WithTranslation) => {
  const { id: itemId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const loadItemsMenuChangeData = bindActionCreators(
    actionCreatorsMenuWeb.loadItemsMenuChangeData,
    dispatch,
  );

  const {
    resetChangeData,
    addNewMenuTypesToItem,
    setBreadCrumbStatusPath,
    updateItem: setValue,
  } = bindActionCreators(actionCreatorsMenu, dispatch);

  const { setShowToast } = bindActionCreators(actionCreatorsApp, dispatch);
  const menuTypeVersions = useSelector(selectMenuTypeVersionsIds, shallowEqual);
  const breadCrumbs = useRootSelector(state => state.menu.breadCrumb);
  const selectedModeId = useSelector(selectSelectedModeId);
  const bucketSelected = useSelector(selectBucketSelected);

  const {
    cloneId,
    menuTypeId,
    assignedMenuTypeIds,
    massUpdateData,
    categoryId,
    subcategoryId,
  } = useMemo(() => {
    if (location.state) {
      return {
        cloneId: location.state.cloneId as number,
        menuTypeId: location.state.menuTypeId as number,
        assignedMenuTypeIds: location.state.assignedMenuTypeIds as number[],
        massUpdateData: location.state
          .massUpdateData as IMassUpdateSelectedData,
        categoryId: location.state.categoryId
          ? Number(location.state.categoryId)
          : null,
        subcategoryId: location.state.subcategoryId
          ? Number(location.state.subcategoryId)
          : null,
      };
    }
    return {
      cloneId: undefined,
      menuTypeId: undefined,
      assignedMenuTypeIds: undefined,
      massUpdateData: undefined,
      categoryId: null,
      subcategoryId: null,
    };
  }, [location]);

  const [selectedMenuTypeVersionId, setSelectedMenuTypeVersionId] =
    useState<number>(menuTypeId || -1);

  const menuTypeName = useRootSelector(state =>
    selectedMenuTypeVersionId > 0
      ? selectMenuTypes(state)[selectedMenuTypeVersionId]?.name || ''
      : '',
  );

  const categoriesSubcategories = useRootSelector(state =>
    selectCategoriesSubcategories(state, selectedMenuTypeVersionId),
  );

  useEffect(() => {
    if (!location?.state?.menuTypeId) return;

    const { menuTypeId: _menuTypeId } = location.state as {
      menuTypeId: number;
    };
    setSelectedMenuTypeVersionId(_menuTypeId);
  }, [location]);

  const newId = useRootSelector(state => state.menu.changeData.data?.id ?? 0);

  const currentItem = useRootSelector(
    state =>
      state.menu.selectedMode.currentMenu.items[
        Number(itemId) || cloneId || Number(newId)
      ] ?? ITEM_INITIAL_STATE,
  );

  useNavigateNewElement(
    `/menu/items/${newId}`,
    Number(itemId),
    Number(newId),
    currentItem.name,
  );

  const currentName = currentItem.name;

  // Local state
  const navigate = useNavigateWithOrg();

  const menuTypesRef = useRef<MenuTypesSectionRef>(null);
  const prevMenuTypesRef = useRef(false);
  const prevSelectedMenuType = useRef(0);
  const associationSectionRef = useRef<IAccordionRef>(null);

  const isSet = useIsSet(
    BUCKET,
    itemId === undefined ? undefined : Number(itemId),
  );

  const isFocused =
    location.pathname === getPathWithOrgData(`/menu/items/${itemId}`);

  const mode = useMemo(() => {
    if (massUpdateData) return FormMode.MASS_UPDATE;
    return !itemId ? FormMode.CREATE : FormMode.UPDATE;
  }, [itemId, massUpdateData]);

  const areSectionsDisabled =
    mode === FormMode.CREATE && selectedMenuTypeVersionId === 0;

  const { hiddenBucket, isHidden, hiddenReason } = useMemo(() => {
    const _isHidden = getIsMenuTypeItemHidden(
      categoriesSubcategories,
      'items',
      Number(newId),
      selectedMenuTypeVersionId,
    );
    return {
      isHidden: _isHidden.isHidden,
      hiddenBucket: _isHidden.bucket,
      hiddenReason: _isHidden.reason,
    };
  }, [categoriesSubcategories, newId, selectedMenuTypeVersionId]);

  const [shouldUpdate, setShouldUpdate] = useState(false);

  const currentBreadcrumbMenuTypeId = useMemo(() => {
    let _currentBreadcrumbMenuTypeId = -1;

    if (!isFocused) return _currentBreadcrumbMenuTypeId;

    if (breadCrumbs.length > 0) {
      const statusPath = breadCrumbs[breadCrumbs.length - 1].statusPath;

      if (statusPath) {
        const splittedPath = statusPath.split('.');

        if (!splittedPath.includes('menuTypeVersions'))
          return _currentBreadcrumbMenuTypeId;

        _currentBreadcrumbMenuTypeId = splittedPath.includes('changeData')
          ? Number(splittedPath[3])
          : splittedPath.includes('selectedMode') && splittedPath[5]
          ? Number(splittedPath[5])
          : _currentBreadcrumbMenuTypeId;
      }
    }

    return _currentBreadcrumbMenuTypeId;
  }, [breadCrumbs, isFocused]);

  useEffect(() => {
    if (!isFocused) return;
    if (selectedMenuTypeVersionId >= 0 && categoryId) {
      try {
        setValue(
          {
            categoriesSubcategories: {
              0: { categoryId: categoryId, subcategoryId: subcategoryId },
            },
          },
          SectionId.BASIC,
          selectedMenuTypeVersionId,
        );
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, categoryId, selectedMenuTypeVersionId]);

  useEffect(() => {
    if (mode === FormMode.MASS_UPDATE && massUpdateData) {
      // FIXME: Mass update change data
    } else if (
      Object.values(currentItem.menuTypeVersions).length > 0 &&
      (!isSet || (isSet && shouldUpdate))
    ) {
      loadItemsMenuChangeData({
        itemId: itemId ? Number(itemId) : 0,
        cloneId,
      });
      prevMenuTypesRef.current = false;
    } else if (Object.values(currentItem.menuTypeVersions).length === 0) {
      setShowToast({
        title: 'No Menus',
        description: 'This item has no Menu versions assigned.',
        type: 'error',
      });
      navigate(-1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, cloneId, itemId, selectedModeId, shouldUpdate]);

  useEffect(() => {
    return () => {
      resetChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get the menuTypeVersionId and select the firs menu Type by default
    let menuTypeVersionId =
      menuTypeId || Object.values(currentItem.menuTypeVersions)[0].menuTypeId;

    menuTypeVersionId =
      menuTypeVersions.length > 0 &&
      !menuTypeVersions.includes(menuTypeVersionId)
        ? menuTypeVersions[0]
        : menuTypeVersionId;

    if (
      selectedMenuTypeVersionId <= 0 ||
      currentBreadcrumbMenuTypeId > 0 ||
      (!currentItem.menuTypeVersions[selectedMenuTypeVersionId] && !isSet) ||
      !menuTypeVersions.includes(selectedMenuTypeVersionId)
    ) {
      if (currentBreadcrumbMenuTypeId > 0) {
        if (currentBreadcrumbMenuTypeId !== prevSelectedMenuType.current) {
          setSelectedMenuTypeVersionId(currentBreadcrumbMenuTypeId);
        }
      } else {
        if (!menuTypeVersionId && menuTypeId && menuTypeVersions.length === 0) {
          setSelectedMenuTypeVersionId(menuTypeId);
        } else {
          setSelectedMenuTypeVersionId(menuTypeVersionId);
        }
      }
    }

    assignedMenuTypeIds &&
      menuTypeVersions.includes(0) &&
      addNewMenuTypesToItem(
        assignedMenuTypeIds.map(_id => ({ id: _id, title: '' })),
        0,
        () => {
          assignedMenuTypeIds.length > 0 &&
            setSelectedMenuTypeVersionId(assignedMenuTypeIds[0]);
        },
      );
    if (
      menuTypesRef.current &&
      !prevMenuTypesRef.current &&
      !menuTypeVersions.includes(0) &&
      currentBreadcrumbMenuTypeId < 0
    ) {
      !menuTypeId && menuTypesRef.current?.setFirstActiveMenuType();
      prevMenuTypesRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    menuTypeVersions,
    menuTypesRef.current,
    currentBreadcrumbMenuTypeId,
    selectedMenuTypeVersionId,
  ]);

  useEffect(() => {
    if (!prevMenuTypesRef.current) {
      mode === FormMode.CREATE &&
        !assignedMenuTypeIds &&
        !cloneId &&
        menuTypesRef.current?.openExistingTypes();
      prevMenuTypesRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuTypesRef.current]);

  useEffect(() => {
    // Check for the selectedMenuTypeVersionId change and update the list of sections
    if (selectedMenuTypeVersionId >= 0) {
      setBreadCrumbStatusPath(
        breadCrumbs.length - 1,
        `changeData.data.menuTypeVersions.${selectedMenuTypeVersionId}.active`,
        true,
      );
    }
    if (selectedMenuTypeVersionId > 0) {
      prevSelectedMenuType.current = selectedMenuTypeVersionId;
    }

    return () => {
      setBreadCrumbStatusPath(
        breadCrumbs.length - 1,
        `selectedMode.currentMenu.items.${itemId}.menuTypeVersions.${
          selectedMenuTypeVersionId || prevSelectedMenuType.current
        }.active`,
        true,
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuTypeVersionId]);

  useEffect(() => {
    if (!breadCrumbs.length) return;

    const statusPath = breadCrumbs[breadCrumbs.length - 1].statusPath;
    if (statusPath && !shouldUpdate) {
      const splittedPath = statusPath.split('.');
      if (
        !isSet &&
        menuTypeVersions.length === 0 &&
        splittedPath.includes('menuTypeVersions') &&
        (bucketSelected === 'items' || (bucketSelected === null && isFocused))
      ) {
        setTimeout(() => {
          // Wait for data to load to decide if we should rerender the screen
          if (prevSelectedMenuType.current > 0) {
            setShouldUpdate(true);
          }
        }, 0);
      }
    }
  }, [
    breadCrumbs,
    selectedMenuTypeVersionId,
    menuTypeVersions.length,
    isSet,
    bucketSelected,
    shouldUpdate,
    isFocused,
  ]);

  useEffect(() => {
    if (isSet && mode === FormMode.CREATE) {
      menuTypesRef.current?.openExistingTypes();
      menuTypesRef.current?.setFirstActiveMenuType();
    }
    prevMenuTypesRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModeId, isSet]);

  const handleNewMenuType = (
    menuTypeIds: IItemSelection[],
    firstActiveMenuType?: IItemSelection,
  ) => {
    addNewMenuTypesToItem(
      menuTypeIds,
      selectedMenuTypeVersionId,
      () => {
        menuTypeIds[0] &&
          setSelectedMenuTypeVersionId(
            Number(
              firstActiveMenuType ? firstActiveMenuType.id : menuTypeIds[0].id,
            ),
          );
      },
      false,
    );
  };

  const isHiddenDueSimpleMode = hiddenReason === HiddenReason.simpleMode;

  const showAssociateButton = !isHiddenDueSimpleMode;

  return (
    (menuTypeVersions.includes(selectedMenuTypeVersionId) ||
      mode === FormMode.MASS_UPDATE) &&
    isSet && (
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}>
        {isHidden && (
          <HiddenSection
            scrollText={t(
              'menuScreen.itemDetails.hiddenItemSection.button.review',
            )}
            name={currentName}
            bucketOrigin="item"
            bucket={isHiddenDueSimpleMode ? 'menuType' : hiddenBucket}
            description={hiddenReason}
            menuTypeName={menuTypeName}
            navigationInfo={{
              id: (isHiddenDueSimpleMode ? selectedMenuTypeVersionId : 0) || 0,
              text: isHiddenDueSimpleMode ? menuTypeName : '0',
            }}
            onScrollClick={
              showAssociateButton
                ? () => {
                    const section = document.getElementById(SectionId.BASIC);

                    associationSectionRef.current?.expandSection(0);

                    setTimeout(() => {
                      section?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                      });
                    }, 0);
                  }
                : undefined
            }
          />
        )}
        <MenuTypesSection
          selectedMenuTypeVersionId={selectedMenuTypeVersionId}
          setSelectedMenuTypeVersionId={setSelectedMenuTypeVersionId}
          ref={menuTypesRef}
          title={t('menuScreen.itemDetails.menuTypes.title')}
          sectionDescription={t('menuScreen.itemDetails.menuTypes.description')}
          onNewMenuType={handleNewMenuType}
          emptyMenuTypesPath="itemDetails"
        />
        <Accordion ref={associationSectionRef}>
          <BasicSection
            sectionId={SectionId.BASIC}
            isExpanded
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          <RegisterDisplaySection
            sectionId={SectionId.REGISTER}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          {mode !== FormMode.MASS_UPDATE && (
            <TaxesSection
              sectionId={SectionId.TAXES}
              menuTypeVersionId={selectedMenuTypeVersionId}
              isDisabled={areSectionsDisabled}
            />
          )}
          <GuestCheckDisplaySection
            sectionId={SectionId.GUEST_CHECK_DISPLAY}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          <InventorySection
            sectionId={SectionId.INVENTORY}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          <MoreInformationSection
            sectionId={SectionId.MORE_INFO}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          <IngredientsSection
            sectionId={SectionId.INGREDIENTS}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          <ModifierGroupsSection
            sectionId={SectionId.MODIFIER_GROUPS}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          <AssociationsSection
            sectionId={SectionId.ASSOCIATION}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          <PrepStationsSection
            sectionId={SectionId.PREP_STATIONS}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          <SuggestiveOrderingSection
            sectionId={SectionId.SUGGESTIVE_ORDERING}
            itemId={Number(itemId)}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
          <SpecialsSection
            sectionId={SectionId.SPECIAL_SCHEME}
            itemId={Number(itemId)}
            menuTypeVersionId={selectedMenuTypeVersionId}
            isDisabled={areSectionsDisabled}
          />
        </Accordion>
      </Box>
    )
  );
};

export default ItemDetails;
