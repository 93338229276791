import Switch from '@app/components/common/Switch';
import { ISwitch } from '@app/components/common/Switch/types';
import { MassUpdateValues } from '@westondev/tableturn-core';

const SwitchMassUpdate = (
  props: Omit<ISwitch, 'checked'> & { checked: boolean | MassUpdateValues },
) => {
  const getISChecked = () => {
    if (props.checked === MassUpdateValues.NO_CHANGES) {
      return false;
    } else return props.checked;
  };
  return <Switch {...props} checked={getISChecked()} />;
};

export default SwitchMassUpdate;
