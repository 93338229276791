import BreadCrumb from '@app/components/common/BreadCrumb/BreadCrumb';
import { WithTranslation } from 'react-i18next';
import StickyContainer from '../../Menu/common/MenuBucketNavSwitch/StickyContainer';
import startCase from 'lodash/startCase';

interface IBreadCrumbSettings extends WithTranslation {
  title: string;
  currentPage?: string;
  onClick?: () => void;
  isSticky?: boolean;
}

const BreadCrumbSettings = ({
  title,
  currentPage,
  onClick = () => null,
  isSticky = true,
}: IBreadCrumbSettings) => {
  return (
    <StickyContainer isSticky={isSticky}>
      <BreadCrumb
        isLast={false}
        breadCrumb={{
          action: 'nav-main',
          text: title,
          onPress: onClick,
          pathURL: document.location.pathname,
        }}
        onClick={onClick}
        showActiveStyle={false}
        isSettings
      />
      <BreadCrumb
        isLast={true}
        breadCrumb={{
          action: 'edit',
          text: startCase(currentPage ?? ''),
          onPress: () => null,
          pathURL: document.location.pathname,
        }}
        onClick={() => null}
        showActiveStyle={true}
        isSettings
      />
    </StickyContainer>
  );
};
export default BreadCrumbSettings;
