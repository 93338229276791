import { ThemeModes } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const CHECKBOX_SIZE = 28;

export const checkBoxStyles = (theme: Theme) =>
  css({
    display: 'flex',
    cursor: 'pointer',
    width: 'fit-content',
    alignItems: 'center',
    '.checkboxLabel': {
      userSelect: 'none',
      marginLeft: '10px',
    },
    '.checkboxContainer': {
      position: 'relative',
      userSelect: 'none',
      minHeight: `${CHECKBOX_SIZE}px`,
      minWidth: `${CHECKBOX_SIZE}px`,
      input: {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
        height: 0,
        width: 0,
      },
      '.checkMark': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: `${CHECKBOX_SIZE}px`,
        minWidth: `${CHECKBOX_SIZE}px`,
        backgroundColor:
          theme.mode === ThemeModes.DARK ? theme.colors.lightestGrey : 'white',
        borderRadius: '5px',
        border: `2px solid ${theme.colors.lightGrey}`,
        svg: {
          fill: theme.colors.textWhite,
        },
        transition: 'all 0.1s ease-in-out',
      },
      'input:checked ~ .checkMark': {
        backgroundColor: theme.colors.semanticGreen,
        border: `2px solid ${theme.colors.semanticGreen}`,
        svg: {
          fill: theme.colors.textWhite,
          marginTop: '3px',
        },
      },
      'input:disabled ~ .checkMark': {
        backgroundColor: theme.colors.lightestGrey,
        border: `2px solid ${theme.colors.lightGrey}`,
        svg: {
          fill: theme.colors.lightGrey,
          marginTop: '4px',
        },
      },
    },
    '&.disabled': {
      '.checkboxLabel': {
        userSelect: 'none',
        color: theme.colors.lightGrey,
        fontWeight: theme.fontWeights.medium,
      },
    },
  });
