import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import OptionsModal from '@app/components/common/OptionsModal';
import FilterGenericSelectionModal from '@app/components/common/SelectionModal/FilterGenericSelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import { subcategoriesDropdownOptionsFactory } from '@app/helpers/factories/menu/subcategoriesFactory';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  selectChangeDataId,
  selectCombos,
  selectItems,
  selectMenu,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import {
  actionCreatorsMenu,
  BreadCrumbAction,
  ChildrenType,
  FormMode,
  generateTabletGeneratedId,
  ICategoryCard as ICategoryCardCore,
  IMenuTypeVersion,
  menuCategoryCardFactory,
  SectionId,
} from '@westondev/tableturn-core';
import { useMemo, useRef, useState } from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { bindActionCreators } from 'redux';

interface ICategoriesSubsection extends WithTranslation {
  categoriesSubcategories: IMenuTypeVersion['categoriesSubcategories'];
  menuTypeVersionId: number;
  mode: FormMode | undefined;
  isCombo?: boolean;
}

interface ICategoryCard
  extends Omit<IItemCard, 'title' | 'id'>,
    Omit<ICategoryCardCore, 'subcategoryId'> {
  isStatusActive: boolean;
  categoriesSubcategoriesKey: number | string;
  subcategoryId: number | null;
}

const sectionId = SectionId.BASIC;

const CategoriesSubsection = (props: ICategoriesSubsection) => {
  const { t, categoriesSubcategories, menuTypeVersionId, mode, isCombo } =
    props ?? {};
  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();

  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const currentMenu = useSelector(selectMenu, shallowEqual);
  const itemId = useSelector(selectChangeDataId, shallowEqual);

  const categoriesSubcategoriesRef = useRef(categoriesSubcategories);
  categoriesSubcategoriesRef.current = categoriesSubcategories;

  const selectedCategories = useMemo((): ICategoryCard[] => {
    const categoryIdsSelected: number[] = [];
    const nullCategoryIdsSelected: (number | null)[] = [];
    const categoryAndSubcategoryKeys: (string | number)[] = [];
    const nullCategoryAndSubcategoryKeys: (string | number)[] = [];
    Object.entries(categoriesSubcategories ?? {}).forEach(
      ([key, { categoryId }]) => {
        if (categoryId) {
          categoryIdsSelected.push(categoryId);
          categoryAndSubcategoryKeys.push(key);
        } else {
          nullCategoryIdsSelected.push(categoryId);
          nullCategoryAndSubcategoryKeys.push(key);
        }
      },
    );

    const categoriesSelected = categoryIdsSelected
      .map((categoryId, index): ICategoryCard | null => {
        const category = menuCategoryCardFactory({
          [categoryId]: currentMenu.categories[categoryId],
        })?.[0];

        if (!category) return null;

        return {
          ...category,

          categoriesSubcategoriesKey: categoryAndSubcategoryKeys[index],
          subcategoryId:
            categoriesSubcategories?.[categoryAndSubcategoryKeys[index]]
              ?.subcategoryId || null,

          isStatusActive: category.status,
          isActive: category.status,
          onRemoveClick: () => {
            const catSubcatKey = categoryAndSubcategoryKeys[index];
            const nextCategoriesAndSubcategories = {
              ...categoriesSubcategoriesRef.current,
              [catSubcatKey]: undefined,
            };

            delete nextCategoriesAndSubcategories[catSubcatKey];

            setValue(
              {
                categoriesSubcategories:
                  nextCategoriesAndSubcategories as typeof categoriesSubcategories,
              },
              sectionId,
              menuTypeVersionId,
            );
          },
        };
      })
      .filter(Boolean) as ICategoryCard[];

    const nullCategoriesSelected = nullCategoryIdsSelected.map(
      (categoryId, index): ICategoryCard => ({
        id: categoryId ?? 0,
        categoriesSubcategoriesKey: nullCategoryAndSubcategoryKeys[index],
        subcategoryId:
          categoriesSubcategories?.[nullCategoryAndSubcategoryKeys[index]]
            ?.subcategoryId || null,
        title: t(
          'menuScreen.categoryDetails.associations.subcategories.noCategory',
        ),
        isStatusActive: true,
        customTag: '',
        activeFilter: 2,
        itemCount: 0,
        menuTypeIds: [],
        status: false,
        onRemoveClick: () => {
          const catSubcatKey = nullCategoryAndSubcategoryKeys[index];
          const nextCategoriesAndSubcategories = {
            ...categoriesSubcategoriesRef.current,
            [catSubcatKey]: undefined,
          };

          delete nextCategoriesAndSubcategories[catSubcatKey];

          setValue(
            {
              categoriesSubcategories:
                nextCategoriesAndSubcategories as typeof categoriesSubcategories,
            },
            sectionId,
            menuTypeVersionId,
          );
        },
      }),
    );

    return [
      ...nullCategoriesSelected,
      ...categoriesSelected,
    ] as unknown as ICategoryCard[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesSubcategories, currentMenu.categories]);

  const [isEditMode, setEditMode] = useState(false);
  const [openAddExistingItem, setOpenAddExistingItem] = useState(false);

  const [filter, setFilter] = useState(0); // 0: All, 1: Active, 2: Inactive

  const closeModal = () => setOpenAddExistingItem(false);

  const handleOnAssociate = (categoriesToAssociate: IItemSelection[]) => {
    const originalCategoriesSubcategories =
      (isCombo
        ? selectCombos(store.getState())
        : selectItems(store.getState()))[itemId]?.menuTypeVersions?.[
        menuTypeVersionId
      ]?.categoriesSubcategories || {};

    let nextCategoriesAndSubcategories = {
      ...categoriesSubcategoriesRef.current,
    };

    const existingCategoriesCount = Object.keys(nextCategoriesAndSubcategories)
      .map(Number)
      .filter(Boolean).length;

    categoriesToAssociate.forEach((categorySelection, idx) => {
      let id: number | string = generateTabletGeneratedId();

      if (mode === FormMode.CREATE) {
        id = idx + 1 + existingCategoriesCount;
      }

      const targetCategorySubcategory = Object.entries(
        originalCategoriesSubcategories,
      ).find(
        categorySubcategory =>
          categorySubcategory[1].categoryId === Number(categorySelection.id),
      );

      if (targetCategorySubcategory) {
        nextCategoriesAndSubcategories = {
          ...nextCategoriesAndSubcategories,
          [Number(targetCategorySubcategory[0])]: {
            ...targetCategorySubcategory[1],
            subcategoryId: null,
          },
        };
        return;
      }

      nextCategoriesAndSubcategories[id as number] = {
        categoryId: categorySelection.id ? Number(categorySelection.id) : null,
        subcategoryId: null,
      };
    });

    setValue(
      {
        categoriesSubcategories: nextCategoriesAndSubcategories,
      },
      sectionId,
      menuTypeVersionId,
    );

    closeModal();
  };

  const filteredSelectedCategories = useMemo(() => {
    return selectedCategories.filter(category => {
      if (filter === 0) return true;
      return category.isStatusActive === (filter === 1);
    });
  }, [selectedCategories, filter]);

  const menuTypeFilterNode = useMemo(
    () => (
      <FilterGenericSelectionModal<number>
        options={[
          {
            id: 1,
            label: t('menuScreen.common.bucket.menuTypes.plural'),
            options: [
              { label: t('commonTexts.all'), value: 0 },
              { label: t('commonTexts.active'), value: 1 },
              { label: t('commonTexts.inactive'), value: 2 },
            ],
          },
        ]}
        currentValue={[{ id: 1, values: [filter] }]}
        onFilter={options => setFilter(options as number)}
        onClear={() => {
          setFilter(0);
        }}
        triggerButtonProps={{
          csx: { minWidth: '50px', width: '50px' },
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  );

  const onSubcategoryChangeFactory =
    (categorySubcategoryKey: string) => (subcategoryId: number | null) => {
      const catSubcats = categoriesSubcategoriesRef.current ?? {};

      const nextCategoriesAndSubcategories = {
        ...catSubcats,
        [categorySubcategoryKey]: {
          ...catSubcats[categorySubcategoryKey],
          subcategoryId: subcategoryId || null,
        },
      };

      setValue(
        {
          categoriesSubcategories: nextCategoriesAndSubcategories,
        },
        sectionId,
        menuTypeVersionId,
      );
    };

  const selectedCategoryIds = useMemo(() => {
    return selectedCategories.map(category => `${category.id}`);
  }, [selectedCategories]);

  return (
    <>
      <AssignedElements
        elements={filteredSelectedCategories}
        type="categories"
        noElementsMessage={t(
          'menuScreen.menuTypeDetails.itemAssociations.emptyCardsMessage',
        )}
        detailsScreenProps={{
          currentRelationsIds: selectedCategoryIds,
          wantedEntity: 'categories',
        }}
        onModalClose={closeModal}
        onAssociate={handleOnAssociate}
        active={openAddExistingItem}
        subCardProps={{
          title: t('menuScreen.itemDetails.categoriesSubcategories.title'),
          onEditMode: setEditMode,
          actionOptions: [
            {
              text: t('menuScreen.menuLocation.actionButtons.addNewCategory'),
              icon: true,
              handler: () =>
                checkForChangesAndNavigateWeb(
                  () =>
                    addBreadcrumbLocationWeb({
                      action: BreadCrumbAction.ADD,
                      text: t(
                        'menuScreen.menuLocation.actionButtons.addNewCategoryTag',
                      ),
                      onPress: () => {
                        navigate('/menu/categories/add');
                      },
                      pathURL: '/menu/categories/add',
                    }),
                  onRefresh,
                  true,
                  false,
                  'categories',
                ),
            },
            {
              text: t(
                'menuScreen.menuLocation.actionButtons.addExistingCategory',
              ),
              icon: false,
              handler: () => setOpenAddExistingItem(true),
            },
          ],
          extraOptions: menuTypeFilterNode,
        }}
        renderItem={
          ((categoryCard: ICategoryCard) => {
            const pathURL = `/menu/categories/${categoryCard.id}`;
            return (
              <Box csx={{ display: 'flex', flexDirection: 'column' }}>
                <Card.Item
                  csx={theme => ({
                    borderColor: categoryCard.isActive
                      ? `${theme.colors.lightGrey} !important`
                      : `${theme.colors.semanticRed} !important`,
                  })}
                  {...categoryCard}
                  isActive={categoryCard.isActive}
                  isDisabled={!categoryCard.id}
                  isStatusActive={categoryCard.isStatusActive}
                  showStatus
                  showRemoveIcon={isEditMode}
                  tag={categoryCard.customTag}
                  onClick={openInNewTab => {
                    if (openInNewTab) return openNewTabWithOrgData(pathURL);
                    if (!categoryCard.id) return;
                    checkForChangesAndNavigateWeb(
                      () =>
                        addBreadcrumbLocationWeb({
                          action: BreadCrumbAction.NAV,
                          text: categoryCard.title,
                          onPress: () => {
                            navigate(pathURL, {
                              state: isCombo ? { comboId: itemId } : { itemId },
                            });
                          },
                          pathURL,
                        }),
                      onRefresh,
                    );
                  }}
                  isLink={categoryCard.id ? getPathWithOrgData(pathURL) : ''}
                />

                <SubcategoryDropdownSection
                  subcategoryId={categoryCard.subcategoryId ?? 0}
                  categoryId={categoryCard.id}
                  onChange={onSubcategoryChangeFactory(
                    categoryCard.categoriesSubcategoriesKey as string,
                  )}
                />
              </Box>
            );
          }) as unknown as (item: IItemCard, index: number) => JSX.Element
        }
      />
    </>
  );
};

interface ISubcategoryDropdownSection {
  categoryId: number;
  subcategoryId: number;
  onChange: (newSubcategoryId: number | null) => void;
}

const SubcategoryDropdownSection = ({
  categoryId,
  subcategoryId,
  onChange,
}: ISubcategoryDropdownSection) => {
  const { t } = useTranslation();

  const currentMenu = useSelector(selectMenu, shallowEqual);
  const subcategories = useSelector(selectSubcategories, shallowEqual);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const subcategoriesOptions = useMemo(() => {
    return subcategoriesDropdownOptionsFactory(
      Object.keys(currentMenu.subcategories ?? {}).map(Number),
      true,
    )
      .filter(
        subcategory =>
          subcategory.categoryId === categoryId || subcategory.value === 0,
      )
      .map(subcat =>
        subcat.value
          ? {
              ...subcat,
              showStatus: true,
              isActive: currentMenu.subcategories[subcat.value]?.active,
            }
          : subcat,
      );
  }, [categoryId, currentMenu.subcategories]);

  const childrenType = categoryId
    ? currentMenu.categories[categoryId]?.subcategoryIds?.length > 0
      ? ChildrenType.SUBCATEGORY
      : ChildrenType.ITEM
    : '';

  const isCurrentSubcategoryInactive =
    currentMenu.subcategories[subcategoryId]?.active === false;

  const isDisabled =
    subcategoriesOptions.length === 0 || childrenType === 'item' || !categoryId;

  const subcategoryName = subcategories[subcategoryId]?.name || '';

  return (
    <>
      <Box
        csx={theme => ({
          width: 0,
          height: 0,
          borderLeft: '8px solid transparent',
          borderRight: '8px solid transparent',
          borderBottom: `10px solid ${
            isCurrentSubcategoryInactive
              ? theme.colors.semanticRed
              : theme.colors.lighterGrey
          }`,
          margin: '0 auto',
        })}
      />

      <Box>
        <OptionsModal
          isActiveByDefault={isModalOpen}
          value={subcategoryId}
          onCancel={() => setIsModalOpen(false)}
          onChange={onChange}
          title={t(
            'menuScreen.categoryDetails.associations.subcategories.title',
          )}
          options={subcategoriesOptions}
          placeholder={subcategoryName}
          buttonProps={{
            disabled: isDisabled,
            csx: theme => ({
              width: '100%',
              justifyContent: 'space-between',
              paddingInline: '15px',
              borderColor: isCurrentSubcategoryInactive
                ? theme.colors.semanticRed
                : theme.colors.lightGrey,
            }),
          }}
        />
      </Box>
    </>
  );
};

export default CategoriesSubsection;
