import AssociatedModItems from '@app/components/LoggedIn/Menu/ModifierGroups/ModifierGroupsDetails/AssociatedModItems';
import AssociationsSection from '@app/components/LoggedIn/Menu/ModifierGroups/ModifierGroupsDetails/AssociationsSection/';
import BasicSection from '@app/components/LoggedIn/Menu/ModifierGroups/ModifierGroupsDetails/BasicSection';
import RegisterDisplaySection from '@app/components/LoggedIn/Menu/ModifierGroups/ModifierGroupsDetails/RegisterDisplaySection';
import Accordion from '@app/components/common/Accordion';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import useIsSet from '@app/hooks/useIsSet';
import useNavigateNewElement from '@app/hooks/useNavigateNewElement';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  actionCreatorsMenu,
  MODIFIER_GROUPS_INITIAL_STATE,
  SectionId,
} from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const BUCKET = 'modifierGroups';

const ModifierGroupsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const modifierItemId = Number(useLocation().state?.modifierItemId);

  const { loadMenuBucketChangeData } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  const { resetChangeData, associateModifierItemToModifierGroup } =
    bindActionCreators(actionCreatorsMenu, dispatch);

  const newId = useRootSelector(
    state => state.menu.changeData.data?.id ?? 0,
  );

  const currentModGroup = useRootSelector(
    state =>
      state.menu.selectedMode.currentMenu.modifierGroups[
        Number(id) || Number(newId)
      ] ?? MODIFIER_GROUPS_INITIAL_STATE,
  );

  useNavigateNewElement(
    `/menu/modifiers/modifier-groups/${newId}`,
    Number(id),
    Number(newId),
    currentModGroup.name,
  );

  const isSet = useIsSet(BUCKET, id === undefined ? undefined : Number(id));

  useEffect(() => {
    if (!isSet) {
      loadMenuBucketChangeData({
        bucket: BUCKET,
        id: Number(id),
      });

      if (modifierItemId)
        associateModifierItemToModifierGroup(
          [{ id: modifierItemId } as IItemSelection],
          {},
          SectionId.ASSOCIATION,
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, id, currentModGroup]);

  useEffect(() => {
    return () => {
      resetChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isSet && (
      <Accordion>
        <BasicSection isExpanded />
        <RegisterDisplaySection />
        <AssociatedModItems />
        <AssociationsSection />
      </Accordion>
    )
  );
};

export default ModifierGroupsDetails;
