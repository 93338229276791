import Card from '../../Card';
import Icon from '../../Icon';
import Box from '../../Box';
import { optionTypeButtonStyles } from './styles';

interface IOptionTypeButton {
  label: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: (openInNewTab: boolean) => void;
}

const OptionTypeButton = ({
  label,
  isSelected = false,
  onClick,
  isDisabled = false,
}: IOptionTypeButton) => {
  return (
    <Box csx={optionTypeButtonStyles}>
      {isSelected && (
        <Box className="triangleIcon">
          <Icon name="IoTriangleSharp" color="persistentSemanticBlue" />
        </Box>
      )}
      <Card.Item
        title={label}
        onClick={() => {
          isDisabled ? undefined : onClick && onClick(false);
        }}
        isSelected={isSelected}
        csx={{ width: '130px !important', zIndex: 1 }}
      />
    </Box>
  );
};

export default OptionTypeButton;
