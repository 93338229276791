import { actionCreatorsApp } from '@app/state';
import { CONFIRMATION_MODAL_INITIAL_STATE } from '@app/state/app/reducers';
import { IConfirmationModal } from '@app/state/app/types';
import {
  selectBreadCrumbs,
  selectBucketSelected,
  selectChangeDataDifferences,
} from '@app/state/menu/menuSelectors';
import { RootState } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { CoreRootState } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../Icon';
import Modal from '../../Modal';
import Typography from '../../Typography';
import CategoriesDiffModal from './CategoriesDiffModal';
import CombosDiffModal from './CombosDiffModal/CombosDiffModal';
import DiscountsDiffModal from './DiscountsDiffModal';
import IngredientsDiffModal from './IngredientsDiffModal';
import ItemsDiffModal from './ItemsDiffModal';
import MenuTypesDiffModal from './MenuTypesDiffModal';
import ModifierGroupsDiffModal from './ModifierGroupsDiffModal';
import ModifierItemsDiffModal from './ModifierItemsDiffModal';
import SpecialsDiffModal from './SpecialsDiffModal';
import SubcategoriesDiffModal from './SubcategoriesDiffModal';
import ItemGroupsDiffModal from './ItemGroupsDiffModal';

interface IDifferencesModal extends IConfirmationModal, WithTranslation {}

const MenuDiffConfirmationModal = ({
  title,
  active,
  description,
  onCancel,
  onSuccess,
  btnCancelText,
  btnSuccessText,
  runCancelOnClickOutside,
  t,
  hideCancel,
  hideSuccess,
}: IDifferencesModal) => {
  // Redux
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const differences = useSelector(selectChangeDataDifferences);

  const bucketSelected = useSelector(selectBucketSelected);

  const breadCrumbItem = useSelector(
    (state: RootState) =>
      selectBreadCrumbs(state as CoreRootState & RootState)[
        state.menu.breadCrumb.length - 1
      ],
  );

  const handleOnModalClose = () => {
    if (runCancelOnClickOutside && onCancel) {
      onCancel();
    }
    setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
    setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnModalSuccess = () => {
    if (onSuccess) onSuccess();
  };

  const theme = useTheme();
  const boxTextColor = theme.colors.darkBlue;
  const boxColor = theme.colors.lightestExtraGrey;

  return (
    <Modal
      isActive={active}
      size={1225}
      onModalClose={handleOnModalClose}
      title={
        <Box>
          <Typography
            variant="subtitle"
            fontWeight="medium"
            csx={{ marginBottom: '2px' }}>
            {title}
          </Typography>
          <Typography color={boxTextColor}>{breadCrumbItem.text}</Typography>
        </Box>
      }
      footer={
        <>
          {btnCancelText && (
            <Button variant="secondary" onClick={handleOnModalClose}>
              {t('commonButtons.cancel')}
            </Button>
          )}
          {!hideCancel && (
            <Button variant="secondary" onClick={handleOnCancel}>
              {btnCancelText ? btnCancelText : t('commonButtons.cancel')}
            </Button>
          )}
          {!hideSuccess && (
            <Button
              variant="primary"
              icon={<Icon name="MdArrowForward" />}
              iconPosition="right"
              onClick={handleOnModalSuccess}>
              {btnSuccessText ? btnSuccessText : t('commonButtons.continue')}
            </Button>
          )}
        </>
      }>
      <Box
        csx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: boxColor,
          borderWidth: 1,
          borderColor: 'lightGrey',
          borderRadius: '8px',
          paddingInline: '10px',
          paddingBlock: '10px',
        }}>
        <Box csx={{ marginRight: '12px' }}>
          <Icon name="MdWarning" color="darkYellow" />
        </Box>
        <Typography>{description}</Typography>
      </Box>
      {bucketSelected === 'menuTypes' ? (
        <MenuTypesDiffModal />
      ) : bucketSelected === 'categories' ? (
        <CategoriesDiffModal />
      ) : bucketSelected === 'subcategories' ? (
        <SubcategoriesDiffModal />
      ) : bucketSelected === 'ingredients' ? (
        <IngredientsDiffModal />
      ) : bucketSelected === 'discounts' ? (
        <DiscountsDiffModal />
      ) : bucketSelected === 'specials' ? (
        <SpecialsDiffModal />
      ) : bucketSelected === 'modifierGroups' ? (
        <ModifierGroupsDiffModal />
      ) : bucketSelected === 'modifierItems' ? (
        <ModifierItemsDiffModal />
      ) : bucketSelected === 'items' ? (
        <ItemsDiffModal />
      ) : bucketSelected === 'combos' ? (
        <CombosDiffModal />
      ) : bucketSelected === 'itemGroups' ? (
        <ItemGroupsDiffModal />
      ) : (
        <Typography>{JSON.stringify(differences, null, 3)}</Typography>
      )}
    </Modal>
  );
};

export default MenuDiffConfirmationModal;
