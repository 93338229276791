import Card from '@app/components/common/Card';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import {
  actionCreatorsMenu,
  MassUpdateValues,
  SectionId,
  TCustomizerShape,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAllCoursesDropdownArray } from '../../selectors';
import { useMemo } from 'react';
import Checkbox from '@app/components/common/Checkbox';
import {
  generalSettingsDropdownSubsectionStyles,
  dropdownContainerStyles,
  generalSettingsCheckboxesSubsectionStyles,
  generalSettingsCheckboxesStyles,
} from './styles';

const sectionId = SectionId.REGISTER;

interface IRegisterDisplayGeneralSubsection extends WithTranslation {
  menuTypeVersionId: number;
  courseId?: number | undefined | null;
  allowSharing: boolean | undefined | -1;
  activeOnline: boolean | undefined | MassUpdateValues;
  delivery: boolean | undefined | MassUpdateValues;
  customizer?: boolean | undefined | MassUpdateValues;
  isCombo?: boolean;
}
const RegisterDisplayGeneralSubsection = ({
  t,
  menuTypeVersionId,
  courseId,
  allowSharing,
  activeOnline,
  delivery,
  customizer,
  isCombo,
}: IRegisterDisplayGeneralSubsection) => {
  // Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const coursesDropdownArray = useSelector(selectAllCoursesDropdownArray);

  const coursesDropdownOptions = useMemo(
    () =>
      [
        ...coursesDropdownArray,
        [
          {
            label: t('settingsModule.coursing.noCourse'),
            value: 0,
          },
        ],
      ] as typeof coursesDropdownArray,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coursesDropdownArray],
  );

  const sharingDropdownOptions = useMemo(
    () => [
      [
        { label: t('commonTexts.yes'), value: 1 },
        { label: t('commonTexts.no'), value: 0 },
      ],
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Card.SubCard
      title={t(
        'menuScreen.commonSections.registerDisplay.registerDisplayOptions.generalSettings',
      )}>
      <Grid columns={15}>
        {/* General Settings Dropdowns */}
        <Grid.Item mb={15} xl={6} csx={generalSettingsDropdownSubsectionStyles}>
          <Grid rowGap={15} columnGap={15}>
            {!isCombo && (
              <Grid.Item sm={6} csx={dropdownContainerStyles}>
                <Dropdown
                  data={coursesDropdownOptions}
                  onChange={_courseId => {
                    setValue(
                      { courseId: _courseId || null },
                      sectionId,
                      menuTypeVersionId,
                    );
                  }}
                  value={Number(courseId ?? 0)}
                  label={t('menuScreen.itemDetails.basic.coursingLabel')}
                  info={t('menuScreen.itemDetails.basic.coursingInfo')}
                />
              </Grid.Item>
            )}
            <Grid.Item sm={6} csx={dropdownContainerStyles}>
              <Dropdown
                label={
                  isCombo
                    ? t('menuScreen.comboDetails.basic.sharingLabel')
                    : t('menuScreen.itemDetails.basic.sharingLabel')
                }
                info={t('menuScreen.itemDetails.basic.sharingInfo')}
                data={sharingDropdownOptions}
                onChange={_allowSharing =>
                  setValue(
                    {
                      allowSharing:
                        _allowSharing === -1 ? -1 : Boolean(_allowSharing),
                    },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
                value={Number(allowSharing ?? 0)}
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>

        {/* General Checkboxes Subsection */}
        <Grid.Item
          mb={15}
          xl={9}
          csx={generalSettingsCheckboxesSubsectionStyles}>
          <Grid rowGap={15} columnGap={15}>
            <Grid.Item md={4} csx={generalSettingsCheckboxesStyles}>
              <Checkbox
                info={t(
                  isCombo
                    ? 'menuScreen.comboDetails.basic.showComboOnline'
                    : 'menuScreen.itemDetails.basic.showItemOnlineInfo',
                )}
                label={t(
                  isCombo
                    ? 'menuScreen.comboDetails.basic.showComboOnline'
                    : 'menuScreen.itemDetails.basic.showItemOnline',
                )}
                checked={Boolean(activeOnline) ?? false}
                onChange={_activeOnline =>
                  setValue(
                    { activeOnline: _activeOnline },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
              />
            </Grid.Item>
            <Grid.Item md={4} csx={generalSettingsCheckboxesStyles}>
              <Checkbox
                info={t('menuScreen.itemDetails.basic.deliveryInfo')}
                label={t('menuScreen.itemDetails.basic.deliveryLabel')}
                checked={Boolean(delivery) ?? false}
                onChange={_delivery =>
                  setValue(
                    { delivery: _delivery },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
              />
            </Grid.Item>
            {!isCombo && (
              <Grid.Item md={4} csx={generalSettingsCheckboxesStyles}>
                <Checkbox
                  label={t(
                    'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.customizerDisplay',
                  )}
                  info={t(
                    'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.customizerDisplaytoolTip',
                  )}
                  checked={
                    customizer === MassUpdateValues.NO_CHANGES
                      ? false
                      : Boolean(customizer)
                  }
                  onChange={_customizer =>
                    setValue(
                      {
                        customizer: _customizer,
                        customizerShape: _customizer
                          ? TCustomizerShape.SQUARE
                          : '',
                      },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                />
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default RegisterDisplayGeneralSubsection;
