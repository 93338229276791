import { MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const PRINTER_JOBS_WIDTH = 1000;

export const printerCenterStyles = (hasPrinter: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    '.goBackButton': {
      span: { display: 'none' },
    },
    [MQ_MIN_SMALL]: {
      width: hasPrinter ? `${PRINTER_JOBS_WIDTH}px` : '400px',
      '.goBackButton': {
        span: { display: 'block' },
      },
    },
  });
