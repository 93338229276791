import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import useRootSelector from '@app/hooks/useRootSelector';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsMenu,
  FormMode,
  MassUpdateValues,
  SectionId,
  TCustomizerShape,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { leftTwoCheckboxesSpacing } from '../RegisterDisplayNavigationSubsection/styles';

const sectionId = SectionId.REGISTER;

interface IRegisterDisplayGeneralSubsection extends WithTranslation {
  menuTypeVersionId: number;
  mode: FormMode | undefined;
  isCombo: boolean | undefined;
  specialTagId: number | null;
  customizer?: boolean | MassUpdateValues | undefined;
  customizerShape?: TCustomizerShape | '';
}

const RegisterDisplayDisplayDropdownsSubsection = ({
  t,
  menuTypeVersionId,
  mode,
  isCombo,
  specialTagId,
  customizer,
  customizerShape,
}: IRegisterDisplayGeneralSubsection) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );
  const specialTags = useRootSelector(
    state => state.settings.selectedMode.currentSettings.register.specialTags,
  );

  const specialTagsOptions = useMemo(
    () =>
      Object.keys(specialTags).map(_specialTagId => ({
        value: Number(_specialTagId),
        textProps: {
          color: !specialTags[Number(_specialTagId)].active
            ? 'persistentSemanticRed'
            : undefined,
        },
        label:
          specialTags[Number(_specialTagId)].name +
          (!specialTags[Number(_specialTagId)].active
            ? ` (${t('commonTexts.inactive')})`
            : ''),
      })),
    [specialTags, t],
  );

  return (
    <Grid
      columns={{
        mb: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 15,
      }}>
      <Grid.Item xl={6} csx={leftTwoCheckboxesSpacing}>
        <Grid rowGap={15} columnGap={15}>
          <Grid.Item md={4} xl={6}>
            <Dropdown
              label={t(
                'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.specialTag.label',
              )}
              placeholder={t(
                'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.specialTag.label',
              )}
              info={t(
                'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.specialTag.toolTip',
              )}
              data={[
                ...(mode === FormMode.MASS_UPDATE
                  ? [[{ label: t('commonTexts.noChanges'), value: -1 }]]
                  : []),
                [
                  {
                    label: t(
                      'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.specialTag.emptyOption',
                    ),
                    value: 0,
                  },
                  ...specialTagsOptions,
                ],
              ]}
              value={specialTagId || 0}
              onChange={_specialTagId =>
                setValue(
                  {
                    specialTagId: _specialTagId === 0 ? null : _specialTagId,
                  },
                  sectionId,
                  menuTypeVersionId,
                )
              }
            />
          </Grid.Item>
          {!isCombo && (
            <Grid.Item md={4} xl={6}>
              <Dropdown
                isDisabled={!(customizer ?? true)}
                label={t(
                  'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.customizerShape.label',
                )}
                placeholder={t(
                  'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.customizerShape.placeholder',
                )}
                info={t(
                  'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.customizerShape.toolTip',
                )}
                data={[
                  [
                    {
                      label: 'Square',
                      value: 1,
                    },
                    {
                      label: 'Round',
                      value: 0,
                    },
                  ],
                ]}
                onChange={courseId => {
                  setValue(
                    {
                      customizerShape:
                        courseId === 1
                          ? TCustomizerShape.SQUARE
                          : TCustomizerShape.ROUND,
                    },
                    sectionId,
                    menuTypeVersionId,
                  );
                }}
                value={customizerShape === TCustomizerShape.SQUARE ? 1 : 0}
              />
            </Grid.Item>
          )}
        </Grid>
      </Grid.Item>
    </Grid>
  );
};

export default RegisterDisplayDisplayDropdownsSubsection;
